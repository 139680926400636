<template>
  <b-container>
    <b-alert v-model="modalEsperar" show variant="primary" style="margin-top: 100px">
      <div style="text-align: center">
        <!--        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history"
                     viewBox="0 0 16 16">
                  <path
                      d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                  <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                  <path
                      d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                </svg>-->
        <h5>Favor de esperar un momento, estamos cargando las ponencias...</h5>
      </div>
    </b-alert>
    <b-row v-if="activo==true">
      <b-col cols="12">
        <b-img
            center
            rounded
            src="https://storage.googleapis.com/asiste/ponencias-recreoCDMX/ReCreoBanner.png"
            height="auto"
            width="660"
            fluid
        >
        </b-img>
      </b-col>
    </b-row>
    <!-- modal abrir encuesta esta aplica por ponencia-->
    <b-modal v-model="modalAbrirEncuesta" persistent size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">

              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <h5 style="color:#f8105e">{{ tituloencuesta }}</h5>
                </div>
                <p style="text-align: justify">
                  {{ introEncuesta }}
                </p>
              </div>

              <b-form>
                <div v-for="input in dataEncuestas" :key="input.id">
                  <div class="mx-3 mt-3 p-2 criterio">
                    <b-jumbotron :lead=input.pregunta></b-jumbotron>
                    <div id="reactivoRespuestaAbierta" v-if="input.respuesta_abierta===0">
                      <b-form-radio-group>
                        <b-row>
                          <b-col sm="12" v-for="descripcion in input.respuestas" :key="descripcion.id">
                            <b-card style="border:1px #dedede solid;  border-radius: 15px;">
                              <b-card-text>
                                <b-form-radio
                                    :id="'respuesta-' + descripcion.id"
                                    :value="descripcion.id"
                                    @change="saveResponsesMultiple(input.sesion_id,input.id, descripcion.id)"
                                >{{ descripcion.contenido }}
                                </b-form-radio>
                              </b-card-text>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-form-radio-group>
                    </div>
                    <b-form-textarea
                        :id="'abierta-' + input.id"
                        class="my-2"
                        v-model="respuestaAbierta[input.id]"
                        type="text"
                        rows="2"
                        max-rows="3"
                        placeholder="Ingresa la opción de respuesta"
                        @change="saveResponsesAbierta(input.sesion_id,input.id,$event)"
                        v-if="input.respuesta_abierta===1"
                    >
                    </b-form-textarea>
                  </div>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right ml-2"
              :disabled="btnCancelar"
              @click="finalizarEncuesta()">

            Finalizar encuesta
          </b-button>

          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              :disabled="btnCancelar"
              @click="modalAbrirEncuesta=false">
            Cancelar
          </b-button>

        </div>

      </template>
    </b-modal>
    <!-- FIN abrir encuesta --->


    <!--    <div class="fixed-top">
          <b-img
              center
              rounded
              src="/img/banner-asiste-recreo-puebla.png"
              height="auto"
              width="600"
              fluid
          >
          </b-img>
        </div>-->

    <!-- banner-->
    <!--    <div>
          <b-card

              img-src="/img/banner-asiste-recreo-puebla.png"
              img-alt="Recreo Puebla"
              text-variant="gray"
              title="Image Overlay"
              sub-title="Subtitle"
          >
            <b-card-text>
              Some quick example text to build on the card and make up the bulk of the card's content.
            </b-card-text>
          </b-card>
        </div>-->
    <!--    <b-row v-if="activo==true" style="background-color: #dadada">
          <b-col cols="12">

          </b-col>
        </b-row>-->

    <!--respaldo de encuesta global--->
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card-group deck>
          <b-card style="height: 15rem; margin-top: 10px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">Evaluación Evento de Desarrollo
                Profesional ReCreo con Nivel A</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5"> nivelA</h6>
              Gracias por asistir a nuestro evento de Desarrollo Profesional ReCreo con Nivel A y Google for Education.
              Estuvimos encantados de tenerte con nosotros y agradecemos tus comentarios.
              Completa esta breve evaluación para informarnos qué tanto satisfizo ReCreo tus necesidades y qué te
              gustaría ver la próxima vez.
            </b-card-text>
            <template #footer>
              <b-row>
                <!--                      <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[34].nivel_ponencia }}</b-col>
                                      <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[34].nombre_salon }}</b></b-col>
                                      <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[34].seccion }}</b-col>-->
                <b-col cols="12" md="8" lg="12">
                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(96)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>

      </b-col>
    </b-row>
    <!--respaldo de encuesta--->


    <b-row v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <h3 style="color: #f8105e">Día 1 - 26 de mayo, 2023</h3>
          </div>
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">7:30 - 8:15am Registro y desayuno</h4>
            <h4 style="color: #ffffff">8:15 - 9:15am Ponencial Magistral</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col cols="12">
        <b-img
            center
            rounded
            src="https://storage.googleapis.com/asiste/ponencias-recreoCDMX/ponencia-magistral.png"
            height="auto"
            width="600"
            fluid
        >
        </b-img>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">9:30am - 11:00am Sesión 1- 90 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index<2">

          <b-card-group deck>

            <b-card style="height: 25rem">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>

                    <div v-if="bloque == 1">
                      <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                                :disabled="disabledBtnInscribirse"
                                @click="Inscribirse(ponencia.id)"
                                v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes && ponencia.inscripcionactiva===1">
                        Inscribirse
                      </b-button>
                    </div>

                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning"
                             v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">
                      Cupo lleno
                    </b-badge>

                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="(ponencia.inscrito===1  && ponencia.encuesta_activa===1)
                                || (ponencia.inscrito===1 && ponencia.ponenciaimpartida===1)">
                      Encuesta
                    </b-button>

                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=2 && index<=3">
          <b-card-group deck>
            <b-card style="height: 25rem;margin-top: 20px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <div v-if="bloque == 1">
                      <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                                :disabled="disabledBtnInscribirse"
                                @click="Inscribirse(ponencia.id)"
                                v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes && ponencia.inscripcionactiva===1">
                        Inscribirse
                      </b-button>
                    </div>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning"
                             v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">
                      Cupo lleno
                    </b-badge>

                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="(ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1)
                                || (ponencia.inscrito===1 && ponencia.ponenciaimpartida===1)">
                      Encuesta
                    </b-button>

                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=4 && index<=4">
          <b-card-group deck>
            <b-card style="height: 25rem;margin-top: 20px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <div v-if="bloque == 1">
                      <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                                :disabled="disabledBtnInscribirse"
                                @click="Inscribirse(ponencia.id)"
                                v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes && ponencia.inscripcionactiva===1">
                        Inscribirse
                      </b-button>
                    </div>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning"
                             v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">
                      Cupo lleno
                    </b-badge>

                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="(ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1)
                                || (ponencia.inscrito===1 && ponencia.ponenciaimpartida===1)">
                      Encuesta
                    </b-button>

                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">11:05 - 12:30pm Sesión 2 (90 minutos)</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[5].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[5].nombre_presentador
                }}</h6>
              {{ ponencias[5].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[5].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[5].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[5].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 2">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[5].id)"
                              v-if="ponencias[5].inscrito===0 && ponencias[5].total_inscritos<ponencias[5].maximo_permitido_asistentes && ponencias[5].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[5].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[5].inscrito===0 && ponencias[5].total_inscritos>=ponencias[5].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[5].id)"
                            v-if="(ponencias[5].inscrito===1 && ponencias[5].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[6].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[6].nombre_presentador
                }}</h6>
              {{ ponencias[6].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[6].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[6].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[6].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 2">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[6].id)"
                              v-if="ponencias[6].inscrito===0 && ponencias[6].total_inscritos<ponencias[6].maximo_permitido_asistentes && ponencias[6].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[6].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[6].inscrito===0 && ponencias[6].total_inscritos>=ponencias[6].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[6].id)"
                            v-if="(ponencias[6].inscrito===1 && ponencias[6].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[7].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[7].nombre_presentador
                }}</h6>
              {{ ponencias[7].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[7].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[7].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[7].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 2">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[7].id)"
                              v-if="ponencias[7].inscrito===0 && ponencias[7].total_inscritos<ponencias[7].maximo_permitido_asistentes && ponencias[7].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[7].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[7].inscrito===0 && ponencias[7].total_inscritos>=ponencias[7].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[7].id)"
                            v-if=" (ponencias[7].inscrito===1 && ponencias[7].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[8].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[8].nombre_presentador
                }}</h6>
              {{ ponencias[8].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[8].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[8].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[8].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 2">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[8].id)"
                              v-if="ponencias[8].inscrito===0 && ponencias[8].total_inscritos<ponencias[8].maximo_permitido_asistentes && ponencias[8].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[8].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[8].inscrito===0 && ponencias[8].total_inscritos>=ponencias[8].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[8].id)"
                            v-if="(ponencias[8].inscrito===1 && ponencias[8].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[9].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[9].nombre_presentador
                }}</h6>
              {{ ponencias[9].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[9].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[9].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[9].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 2">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[9].id)"
                              v-if="ponencias[9].inscrito===0 && ponencias[9].total_inscritos<ponencias[9].maximo_permitido_asistentes && ponencias[9].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[9].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[9].inscrito===0 && ponencias[9].total_inscritos>=ponencias[9].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[9].id)"
                            v-if="(ponencias[9].inscrito===1 && ponencias[9].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>

        </b-card-group>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">12:30 - 1:30pm Comida</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">1:30pm - 3:00pm Sesión 3 (90 minutos)</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[10].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[10].nombre_presentador
                }}</h6>
              {{ ponencias[10].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[10].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[10].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[10].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 3">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[10].id)"
                              v-if="ponencias[10].inscrito===0 && ponencias[10].total_inscritos<ponencias[10].maximo_permitido_asistentes && ponencias[10].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[10].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[10].inscrito===0 && ponencias[10].total_inscritos>=ponencias[10].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[10].id)"
                            v-if="(ponencias[10].inscrito===1 && ponencias[10].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[11].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[11].nombre_presentador
                }}</h6>
              {{ ponencias[11].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[11].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[11].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[11].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 3">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[11].id)"
                              v-if="ponencias[11].inscrito===0 && ponencias[11].total_inscritos<ponencias[11].maximo_permitido_asistentes && ponencias[11].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[11].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[11].inscrito===0 && ponencias[11].total_inscritos>=ponencias[11].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[11].id)"
                            v-if="(ponencias[11].inscrito===1  && ponencias[11].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[12].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[12].nombre_presentador
                }}</h6>
              {{ ponencias[12].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[12].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[12].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[12].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 3">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[12].id)"
                              v-if="ponencias[12].inscrito===0 && ponencias[12].total_inscritos<ponencias[12].maximo_permitido_asistentes && ponencias[12].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[12].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[12].inscrito===0 && ponencias[12].total_inscritos>=ponencias[12].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[12].id)"
                            v-if="(ponencias[12].inscrito===1 && ponencias[12].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[13].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[13].nombre_presentador
                }}</h6>
              {{ ponencias[13].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[13].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[13].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[13].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 3">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[13].id)"
                              v-if="ponencias[13].inscrito===0 && ponencias[13].total_inscritos<ponencias[13].maximo_permitido_asistentes && ponencias[13].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[13].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[13].inscrito===0 && ponencias[13].total_inscritos>=ponencias[13].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[13].id)"
                            v-if="(ponencias[13].inscrito===1  && ponencias[13].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[14].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[14].nombre_presentador
                }}</h6>
              {{ ponencias[14].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[14].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[14].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[14].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 3">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[14].id)"
                              v-if="ponencias[14].inscrito===0 && ponencias[14].total_inscritos<ponencias[14].maximo_permitido_asistentes && ponencias[14].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[14].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[14].inscrito===0 && ponencias[14].total_inscritos>=ponencias[14].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[14].id)"
                            v-if="(ponencias[14].inscrito===1 && ponencias[14].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>

        </b-card-group>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">3:05pm - 4:05pm Sesión 4 (90 minutos)</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[15].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[15].nombre_presentador
                }}</h6>
              {{ ponencias[15].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[15].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[15].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[15].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 4">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[15].id)"
                              v-if="ponencias[15].inscrito===0 && ponencias[15].total_inscritos<ponencias[15].maximo_permitido_asistentes && ponencias[15].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[15].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[15].inscrito===0 && ponencias[15].total_inscritos>=ponencias[15].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[15].id)"
                            v-if="(ponencias[15].inscrito===1  && ponencias[15].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[16].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[16].nombre_presentador
                }}</h6>
              {{ ponencias[16].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[16].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[16].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[16].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 4">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[16].id)"
                              v-if="ponencias[16].inscrito===0 && ponencias[16].total_inscritos<ponencias[16].maximo_permitido_asistentes && ponencias[16].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[16].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[16].inscrito===0 && ponencias[16].total_inscritos>=ponencias[16].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[16].id)"
                            v-if="(ponencias[16].inscrito===1 && ponencias[16].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[17].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[17].nombre_presentador
                }}</h6>
              {{ ponencias[17].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[17].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[17].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[17].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 4">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[17].id)"
                              v-if="ponencias[17].inscrito===0 && ponencias[17].total_inscritos<ponencias[17].maximo_permitido_asistentes && ponencias[17].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[17].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[17].inscrito===0 && ponencias[17].total_inscritos>=ponencias[17].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[17].id)"
                            v-if="(ponencias[17].inscrito===1  && ponencias[17].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[18].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[18].nombre_presentador
                }}</h6>
              {{ ponencias[18].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[18].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[18].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[18].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 4">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[18].id)"
                              v-if="ponencias[18].inscrito===0 && ponencias[18].total_inscritos<ponencias[18].maximo_permitido_asistentes && ponencias[18].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[18].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[18].inscrito===0 && ponencias[18].total_inscritos>=ponencias[18].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[18].id)"
                            v-if=" (ponencias[18].inscrito===1 && ponencias[18].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[19].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[19].nombre_presentador
                }}</h6>
              {{ ponencias[19].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[19].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[19].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[19].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 4">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[19].id)"
                              v-if="ponencias[19].inscrito===0 && ponencias[19].total_inscritos<ponencias[19].maximo_permitido_asistentes && ponencias[19].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[19].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[19].inscrito===0 && ponencias[19].total_inscritos>=ponencias[19].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[19].id)"
                            v-if="(ponencias[19].inscrito===1 && ponencias[19].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>

        </b-card-group>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">4:15pm - 4:40pm Presentación Ganadores de Rifa (Gimnasio)</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true && ponencias[34].encuesta_activa===1 && ponencias[34].ponenciaimpartida===1">
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <div id="encuestaGlobal">
          <b-card-group deck>
            <b-card style="height: 25rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{
                    ponencias[34].titulo_ponencia
                  }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                    ponencias[34].nombre_presentador
                  }}</h6>
                {{ ponencias[34].descripcion_ponencia }}
                <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                          @click="abrirEncuesta(ponencias[34].id)">
                  Encuesta
                </b-button>
              </b-card-text>

            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <h3 style="color: #f8105e">Día 2 - 27 de mayo, 2023</h3>
          </div>
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">8:00 - 8:30am Desayuno</h4>
            <h4 style="color: #ffffff">8:30 - 9:30am GParty!! Ana Roa y Patty Zamora (Gimnasio)</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">9:45am - 11:15am Sesión 5 (90 minutos)</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <!--        <b-card-group deck>
                  <b-card style="height: 25rem;margin-top: 20px">
                    <b-card-text class="text-justify">
                      <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[20].titulo_ponencia }}</h4>
                      <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                          ponencias[20].nombre_presentador
                        }}</h6>
                      {{ ponencias[20].descripcion_ponencia }}
                    </b-card-text>
                    <template #footer>
                      <b-row>
                        <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[20].nivel_ponencia }}</b-col>
                        <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[20].nombre_salon }}</b></b-col>
                        <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[20].seccion }}</b-col>
                        <b-col cols="12" md="8" lg="12">
                          <br>
                          <div v-if="bloque == 5">
                            <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                                      :disabled="disabledBtnInscribirse"
                                      @click="Inscribirse(ponencias[20].id)"
                                      v-if="ponencias[20].inscrito===0 && ponencias[20].total_inscritos<ponencias[20].maximo_permitido_asistentes && ponencias[20].inscripcionactiva===1">
                              Inscribirse
                            </b-button>
                          </div>

                          <b-badge variant="success" v-if="ponencias[20].inscrito===1">Inscrito</b-badge>
                          <b-badge variant="warning"
                                   v-if="ponencias[20].inscrito===0 && ponencias[20].total_inscritos>=ponencias[20].maximo_permitido_asistentes">
                            Cupo lleno
                          </b-badge>

                          <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                                    @click="abrirEncuesta(ponencias[20].id)"
                                    v-if="(ponencias[20].inscrito===1 && ponencias[20].encuesta>=1 && ponencias[20].encuesta_activa===1)
                                    || (ponencias[20].inscrito===1 && ponencias[20].encuesta>=1 && ponencias[20].respuestas===0 && ponencias[20].ponenciaimpartida===1)">
                            Encuesta
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-card>
                </b-card-group>-->
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[21].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[21].nombre_presentador
                }}</h6>
              {{ ponencias[21].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[21].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[21].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[21].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 5">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[21].id)"
                              v-if="ponencias[21].inscrito===0 && ponencias[21].total_inscritos<ponencias[21].maximo_permitido_asistentes && ponencias[21].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[21].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[21].inscrito===0 && ponencias[21].total_inscritos>=ponencias[21].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[21].id)"
                            v-if="(ponencias[21].inscrito===1 && ponencias[21].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[22].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[22].nombre_presentador
                }}</h6>
              {{ ponencias[22].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[22].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[22].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[22].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 5">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[22].id)"
                              v-if="ponencias[22].inscrito===0 && ponencias[22].total_inscritos<ponencias[22].maximo_permitido_asistentes && ponencias[22].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[22].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[22].inscrito===0 && ponencias[22].total_inscritos>=ponencias[22].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[22].id)"
                            v-if="(ponencias[22].inscrito===1 && ponencias[22].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[23].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[23].nombre_presentador
                }}</h6>
              {{ ponencias[23].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[23].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[23].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[23].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 5">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[23].id)"
                              v-if="ponencias[23].inscrito===0 && ponencias[23].total_inscritos<ponencias[23].maximo_permitido_asistentes && ponencias[23].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[23].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[23].inscrito===0 && ponencias[23].total_inscritos>=ponencias[23].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[23].id)"
                            v-if="(ponencias[23].inscrito===1  && ponencias[23].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[24].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[24].nombre_presentador
                }}</h6>
              {{ ponencias[24].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[24].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[24].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[24].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 5">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[24].id)"
                              v-if="ponencias[24].inscrito===0 && ponencias[24].total_inscritos<ponencias[24].maximo_permitido_asistentes && ponencias[24].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[24].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[24].inscrito===0 && ponencias[24].total_inscritos>=ponencias[24].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[24].id)"
                            v-if="(ponencias[24].inscrito===1 && ponencias[24].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <!--
        <b-row v-if="activo==true">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">

          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <b-card-group>

            </b-card-group>
          </b-col>
        </b-row>-->

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">11:20am - 12:20am Sesión 6 (90 minutos)</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <!--        <b-card-group deck>
                  <b-card style="height: 25rem;margin-top: 20px">
                    <b-card-text class="text-justify">
                      <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[25].titulo_ponencia }}</h4>
                      <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                          ponencias[25].nombre_presentador
                        }}</h6>
                      {{ ponencias[25].descripcion_ponencia }}
                    </b-card-text>
                    <template #footer>
                      <b-row>
                        <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[25].nivel_ponencia }}</b-col>
                        <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[25].nombre_salon }}</b></b-col>
                        <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[25].seccion }}</b-col>
                        <b-col cols="12" md="8" lg="12">
                          <br>
                          <div v-if="bloque == 6">
                            <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                                      :disabled="disabledBtnInscribirse"
                                      @click="Inscribirse(ponencias[25].id)"
                                      v-if="ponencias[25].inscrito===0 && ponencias[25].total_inscritos<ponencias[25].maximo_permitido_asistentes && ponencias[25].inscripcionactiva===1">
                              Inscribirse
                            </b-button>
                          </div>

                          <b-badge variant="success" v-if="ponencias[25].inscrito===1">Inscrito</b-badge>
                          <b-badge variant="warning"
                                   v-if="ponencias[25].inscrito===0 && ponencias[25].total_inscritos>=ponencias[25].maximo_permitido_asistentes">
                            Cupo lleno
                          </b-badge>

                          <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                                    @click="abrirEncuesta(ponencias[25].id)"
                                    v-if="(ponencias[25].inscrito===1 && ponencias[25].encuesta>=1 && ponencias[25].encuesta_activa===1)
                                    || (ponencias[25].inscrito===1 && ponencias[25].encuesta>=1 && ponencias[25].respuestas===0 && ponencias[25].ponenciaimpartida===1)">
                            Encuesta
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-card>
                </b-card-group>-->
        <b-card-group>
          <b-card style="height: 30rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[26].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[26].nombre_presentador
                }}</h6>
              {{ ponencias[26].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[26].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[26].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[26].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 6">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[26].id)"
                              v-if="ponencias[26].inscrito===0 && ponencias[26].total_inscritos<ponencias[26].maximo_permitido_asistentes && ponencias[26].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[26].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[26].inscrito===0 && ponencias[26].total_inscritos>=ponencias[26].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[26].id)"
                            v-if="(ponencias[26].inscrito===1 && ponencias[26].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 30rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[27].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[27].nombre_presentador
                }}</h6>
              {{ ponencias[27].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[27].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[27].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[27].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 6">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[27].id)"
                              v-if="ponencias[27].inscrito===0 && ponencias[27].total_inscritos<ponencias[27].maximo_permitido_asistentes && ponencias[27].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[27].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[27].inscrito===0 && ponencias[27].total_inscritos>=ponencias[27].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[27].id)"
                            v-if=" (ponencias[27].inscrito===1 && ponencias[27].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[28].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[28].nombre_presentador
                }}</h6>
              {{ ponencias[28].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[28].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[28].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[28].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 6">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[28].id)"
                              v-if="ponencias[28].inscrito===0 && ponencias[28].total_inscritos<ponencias[28].maximo_permitido_asistentes && ponencias[28].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[28].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[28].inscrito===0 && ponencias[28].total_inscritos>=ponencias[28].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[28].id)"
                            v-if="(ponencias[28].inscrito===1 && ponencias[28].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[29].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[29].nombre_presentador
                }}</h6>
              {{ ponencias[29].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[29].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[29].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[29].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 6">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[29].id)"
                              v-if="ponencias[29].inscrito===0 && ponencias[29].total_inscritos<ponencias[29].maximo_permitido_asistentes && ponencias[29].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[29].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[29].inscrito===0 && ponencias[29].total_inscritos>=ponencias[29].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[29].id)"
                            v-if="(ponencias[29].inscrito===1  && ponencias[29].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>


    <!--    <b-row v-if="activo==true">
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">

          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6" xl="6">
            <b-card-group>

            </b-card-group>
          </b-col>
        </b-row>-->


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">12:30 - 1:15pm Comida</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">1:20pm - 2:20pm Sesión 7 (60 minutos)</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[30].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[30].nombre_presentador
                }}</h6>
              {{ ponencias[30].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[30].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[30].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[30].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 7">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[30].id)"
                              v-if="ponencias[30].inscrito===0 && ponencias[30].total_inscritos<ponencias[30].maximo_permitido_asistentes && ponencias[30].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[30].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[30].inscrito===0 && ponencias[30].total_inscritos>=ponencias[30].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[30].id)"
                            v-if="(ponencias[30].inscrito===1 && ponencias[30].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[31].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[31].nombre_presentador
                }}</h6>
              {{ ponencias[31].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[31].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[31].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[31].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 7">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[31].id)"
                              v-if="ponencias[31].inscrito===0 && ponencias[31].total_inscritos<ponencias[31].maximo_permitido_asistentes && ponencias[31].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[31].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[31].inscrito===0 && ponencias[31].total_inscritos>=ponencias[31].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[31].id)"
                            v-if="(ponencias[31].inscrito===1 && ponencias[31].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group deck>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[32].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[32].nombre_presentador
                }}</h6>
              {{ ponencias[32].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[32].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[32].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[32].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 7">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[32].id)"
                              v-if="ponencias[32].inscrito===0 && ponencias[32].total_inscritos<ponencias[32].maximo_permitido_asistentes && ponencias[32].inscripcionactiva===1">
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[32].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[32].inscrito===0 && ponencias[32].total_inscritos>=ponencias[32].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[32].id)"
                            v-if="(ponencias[32].inscrito===1 && ponencias[32].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col xs="6" sm="6" md="6" lg="6" xl="6">
        <b-card-group>
          <b-card style="height: 25rem;margin-top: 20px">
            <b-card-text class="text-justify">
              <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencias[33].titulo_ponencia }}</h4>
              <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{
                  ponencias[33].nombre_presentador
                }}</h6>
              {{ ponencias[33].descripcion_ponencia }}
            </b-card-text>
            <template #footer>
              <b-row>
                <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencias[33].nivel_ponencia }}</b-col>
                <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencias[33].nombre_salon }}</b></b-col>
                <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencias[33].seccion }}</b-col>
                <b-col cols="12" md="8" lg="12">
                  <br>
                  <div v-if="bloque == 7">
                    <b-button :pressed="false" variant="primary" size="sm" title="Inscribirse"
                              :disabled="disabledBtnInscribirse"
                              @click="Inscribirse(ponencias[33].id)"
                              v-if="ponencias[33].inscrito===0 &&
                                    ponencias[33].total_inscritos<ponencias[33].maximo_permitido_asistentes
                                    && ponencias[33].inscripcionactiva===1"
                    >
                      Inscribirse
                    </b-button>
                  </div>

                  <b-badge variant="success" v-if="ponencias[33].inscrito===1">Inscrito</b-badge>
                  <b-badge variant="warning"
                           v-if="ponencias[33].inscrito===0 && ponencias[33].total_inscritos>=ponencias[33].maximo_permitido_asistentes">
                    Cupo lleno
                  </b-badge>

                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                            @click="abrirEncuesta(ponencias[33].id)"
                            v-if="(ponencias[33].inscrito===1 && ponencias[33].ponenciaimpartida===1)">
                    Encuesta
                  </b-button>

                  <!--                  <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                                              @click="abrirEncuesta(ponencias[33].id)"
                                              v-if="(ponencias[33].inscrito===1  && ponencias[33].encuesta_activa===1)
                                              || (ponencias[33].inscrito===1 && ponencias[33].respuestas===0 && ponencias[33].ponenciaimpartida===1)">
                                      Encuesta
                                    </b-button>-->
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row class="mb-5" style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">2:30pm - 3:15pm Cierre y Rifa - Patty Zamora (Gimnasio)</h4>
          </div>
        </div>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
import PagoService from "@/services/PagoService";
import moment from "moment"
import 'moment/locale/es';
import EncuestaService from "@/services/EncuestaService";

export default {
  mixins: [toast],
  data() {
    return {
      bloque: null,
      disabledBtnInscribirse: false,
      activo: false,
      modalEditarSesion: false,
      modalAbrirEncuesta: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,
      encuesta: {
        ponencia_id: null
      },
      sesion: {
        id: 0
        , titulo_ponencia: null
        , evento_id: 0
        , nombre_salon: null
        , horario_inicio_ponencia: null
        , horario_fin_ponencia: null
        , numero_ponencia: null
        , duracion_minutos_ponencia: null
        , nombre_presentador: null
        , nivel_ponencia: null
        , descripcion_ponencia: null
        , estatusActivo: null
        , maximo_permitido_asistentes: null
        , seccion: null
        , dia_ponencia: null
        , inscrito: null
        , total_inscritos: null
      },
      dia_ponencia: [
        {value: 1, text: '1'},
        {value: 2, text: '2'}
      ],

      modalAgregarInvitado: false,
      modalVerComprobante: true,
      modalEsperar: true,
      previewObservacionesValidacion: '',
      modalValidarIngreso: false,
      textoSnackbar: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        {label: 'Día', key: 'dia_ponencia', align: 'left'}
        , {label: 'No. Ponencia', key: 'numero_ponencia', align: 'left'}
        , {label: 'Titulo ponencia', key: 'titulo_ponencia', align: 'left'}
        , {label: 'Presentador', key: 'nombre_presentador', align: 'left'}
        , {label: 'Salon', key: 'nombre_salon', align: 'left'}
        , {label: 'Horario', key: 'horario', align: 'left'}
        , {label: 'Acciones', key: 'acciones', align: 'left'}

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter3: null,
      filterOn3: [],
      resultSet3: []

      , infoPago: {
        id: 0,
        url_comprobante_pago: null,
        comprobanteEstatusAprobado: 0,
        observacionesValidacion: null,
        cliente: {
          name: null,
          escuela_procedencia: null,
          email: null
        }
      }
      , dataEncuestas: []
      , introEncuesta: null
      , tituloencuesta: null
      , respuestaAbierta: []
      , ponencias: []
      , btnCancelar: false
    }
  },
  methods: {
    finalizarEncuesta() {
      this.modalAbrirEncuesta = false


      this.getPonenciasAll();
    },
    async saveResponsesMultiple(sesion_id, encuesta_id, respuesta_id) {
      let formData = new FormData();
      formData.append("sesion_id", sesion_id);  //idponencia
      formData.append("encuesta_id", encuesta_id);  ///id pregunta
      formData.append("respuesta_id", respuesta_id);  //id respuesta

      await EncuestaService.saveResponses(formData);
    },
    async saveResponsesAbierta(sesion_id, encuesta_id, event) {
      let formData = new FormData();
      let contenido = event;
      formData.append("sesion_id", sesion_id);
      formData.append("encuesta_id", encuesta_id);
      formData.append("respuestaAbierta", contenido);
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
      await EncuestaService.saveResponsesAbierta(formData);
    },
    async abrirEncuesta(itemSelected) {
      /*      console.log("idPonencia------------>")
            console.log(itemSelected)*/
      this.dataEncuestas = []
      this.introEncuesta = null
      this.modalAbrirEncuesta = true
      this.encuesta.ponencia_id = itemSelected
      this.btnCancelar = true
      this.tituloencuesta = "Favor de esperar un momento, preparando Encuesta..."
      const response = await EncuestaService.getEncuestasRespuestas(itemSelected);
      /*      console.log("preguntas encuesta------>>>>>>>>>")
            console.log(response)*/
      this.tituloencuesta = null
      this.dataEncuestas = response.data.encuestas
      this.introEncuesta = response.data.intro
      this.tituloencuesta = response.data.titulo
      this.btnCancelar = false
      /*
            console.log("titulo encuesta")
            console.log(this.tituloencuesta)
      */
      //console.log("encuestas")
      //console.log(this.dataEncuestas)
    },
    async Inscribirse(idPonencia) {
      this.disabledBtnInscribirse = true
      let formData = new FormData();
      formData.append("idPonencia", idPonencia);
      this.wait()
      const response = await EventoService.inscribirsePonencia(formData);
      this.disabledBtnInscribirse = false
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        await this.getPonenciasAll()
      }
      this.toast('b-toaster-bottom-center', true, message, color);

    },
    openModalUpdateSesion(sessionSelected) {
      this.modalEsperar = true
      this.resetFormSesion()
      this.sesion.id = sessionSelected.id
      this.sesion.titulo_ponencia = sessionSelected.titulo_ponencia
      this.sesion.nombre_salon = sessionSelected.nombre_salon
      this.sesion.horario_inicio_ponencia = sessionSelected.horario_inicio_ponencia
      this.sesion.horario_fin_ponencia = sessionSelected.horario_fin_ponencia
      this.sesion.numero_ponencia = sessionSelected.numero_ponencia
      this.sesion.duracion_minutos_ponencia = sessionSelected.duracion_minutos_ponencia
      this.sesion.nombre_presentador = sessionSelected.nombre_presentador
      this.sesion.nivel_ponencia = sessionSelected.nivel_ponencia
      this.sesion.descripcion_ponencia = sessionSelected.descripcion_ponencia
      this.sesion.maximo_permitido_asistentes = sessionSelected.maximo_permitido_asistentes
      this.sesion.seccion = sessionSelected.seccion
      this.sesion.dia_ponencia = sessionSelected.dia_ponencia
      this.modalEditarSesion = true
      this.modalEsperar = false

    },
    resetFormSesion() {
      this.sesion.id = 0
      this.sesion.titulo_ponencia = null
      this.sesion.evento_id = 0
      this.sesion.nombre_salon = null
      this.sesion.horario_inicio_ponencia = null
      this.sesion.horario_fin_ponencia = null
      this.sesion.numero_ponencia = null
      this.sesion.duracion_minutos_ponencia = null
      this.sesion.nombre_presentador = null
      this.sesion.nivel_ponencia = null
      this.sesion.descripcion_ponencia = null
      this.sesion.estatusActivo = null
      this.sesion.maximo_permitido_asistentes = null
      this.sesion.seccion = null
      this.sesion.dia_ponencia = null
      this.nombre_sesion_actual = null
      this.sesion_id_actual = null
      this.sesion.inscrito = null
      this.sesion.total_inscritos = null
    },
    openModalDeleteSesion(sesion) {
      this.nombre_sesion_actual = sesion.titulo_ponencia
      this.sesion_id_actual = sesion.id
      this.modalDeleteSesion = true
    },
    async updatePonencia() {
      if (this.sesion.titulo_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_presentador == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
        return false;
      } else if (this.sesion.dia_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.descripcion_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_salon == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
        return false;
      } else if (this.sesion.maximo_permitido_asistentes == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
        return false;
      } else if (this.sesion.horario_inicio_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.horario_fin_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.numero_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el número de ponencia!', 'danger');
        return false;
      } else if (this.sesion.duracion_minutos_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la duración en minutos de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nivel_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.seccion == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
        return false;
      }

      let formData = new FormData();
      formData.append("id", this.sesion.id);
      formData.append("titulo_ponencia", this.sesion.titulo_ponencia);
      formData.append("nombre_salon", this.sesion.nombre_salon);
      formData.append("horario_inicio_ponencia", this.sesion.horario_inicio_ponencia);
      formData.append("horario_fin_ponencia", this.sesion.horario_fin_ponencia);
      formData.append("numero_ponencia", this.sesion.numero_ponencia);
      formData.append("duracion_minutos_ponencia", this.sesion.duracion_minutos_ponencia);
      formData.append("nombre_presentador", this.sesion.nombre_presentador);
      formData.append("nivel_ponencia", this.sesion.nivel_ponencia);
      formData.append("descripcion_ponencia", this.sesion.descripcion_ponencia);
      formData.append("maximo_permitido_asistentes", this.sesion.maximo_permitido_asistentes);
      formData.append("seccion", this.sesion.seccion);
      formData.append("dia_ponencia", this.sesion.dia_ponencia);
      this.modalEsperar = true
      this.modalEditarSesion = false
      const response = await EventoService.updatePonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        await this.getPonenciasAll();
        this.toast('b-toaster-bottom-center', true, message, color);
      } else {
        this.toast('b-toaster-bottom-center', true, message, color);
      }
      this.resetFormSesion()
    },
    async guardarPonencia() {
      if (this.sesion.titulo_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_presentador == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
        return false;
      } else if (this.sesion.dia_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.descripcion_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_salon == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
        return false;
      } else if (this.sesion.maximo_permitido_asistentes == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
        return false;
      } else if (this.sesion.horario_inicio_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.horario_fin_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.numero_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el número de ponencia!', 'danger');
        return false;
      } else if (this.sesion.duracion_minutos_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la duración en minutos de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nivel_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.seccion == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
        return false;
      }

      let formData = new FormData();
      formData.append("titulo_ponencia", this.sesion.titulo_ponencia);
      formData.append("nombre_presentador", this.sesion.nombre_presentador);
      formData.append("descripcion_ponencia", this.sesion.descripcion_ponencia);
      formData.append("nombre_salon", this.sesion.nombre_salon);
      formData.append("maximo_permitido_asistentes", this.sesion.maximo_permitido_asistentes);
      formData.append("horario_inicio_ponencia", this.sesion.horario_inicio_ponencia);
      formData.append("horario_fin_ponencia", this.sesion.horario_fin_ponencia);
      formData.append("numero_ponencia", this.sesion.numero_ponencia);
      formData.append("duracion_minutos_ponencia", this.sesion.duracion_minutos_ponencia);
      formData.append("dia_ponencia", this.sesion.dia_ponencia);
      formData.append("nivel_ponencia", this.sesion.nivel_ponencia);
      formData.append("seccion", this.sesion.seccion);
      this.wait()
      this.modalAgregarInvitado = false
      const response = await EventoService.agregarPonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalAgregarInvitado = false
        await this.getPonenciasAll();
        this.toast('b-toaster-bottom-center', true, message, color);
      } else {
        //this.modalAgregarInvitado=false
        this.toast('b-toaster-bottom-center', true, message, color);
      }
      this.resetFormSesion()
    },
    async guardarValidacion() {
      let formData = new FormData();
      formData.append("id", this.infoPago.id);
      //console.log("id user", this.infoPago.id)
      this.modalValidarIngreso = false
      this.modalEsperar = true
      const response = await PagoService.guardarRegistroDeAsistencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        this.getPonenciasAll()

      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    hidemodalValidarPago() {
      this.modalValidarIngreso = false
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getPonenciasAll() {
      this.resultSet = []
      this.modalEsperar = true
      this.activo = false
      // const response = await EventoService.getAllPonencias();
      const response = await EventoService.getPonenciasByUserInscrito();
      this.modalEsperar = false
      this.activo = true
      this.resultSet = response.data.ponencias;
      this.ponencias = response.data.ponencias
      console.log("ponencias")
      console.log(response)
      this.totalRows = response.data.cantidad;
      this.bloque = response.data.bloque
    },
    showModalValidarPago(item) {
      this.modalValidarIngreso = true
      this.infoPago = item
    },
    hideModalCrearEvento() {
      this.modalCrearEvento = false
    },
    async deleteSesion() {
      let formData = new FormData();
      formData.append("id", this.sesion_id_actual);
      this.modalDeleteSesion = false
      this.modalEsperar = true
      const response = await EventoService.deleteSesion(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        this.resetFormSesion()
        this.getPonenciasAll();
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    async guardarEvento() {
      if (this.nombre_evento === '') {
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger');
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      this.wait()
      this.modalCrearEvento = false
      const response = await EventoService.saveEvento(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.getEventos();
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    showModalCrearEvento() {
      this.modalCrearEvento = true
    },
    async getData() {
      this.resultSet = [];
      const url = `/api/all-events`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.resultSet = (data == 1) ? resultSet : [];
      this.totalRows = this.resultSet.length;
      this.textoSnackbar = message;
      this.showAlert();
    },//getData
    async getMyTickets() {
      this.resultSet2 = [];
      const url = `/api/all-myTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.resultSet2 = (data == 1) ? resultSet : [];
      this.totalRows2 = this.resultSet2.length;
      this.textoSnackbar = message;
      this.showAlert();
    },//getMyTickets
    async getMyClosedTickets() {
      this.resultSet3 = [];
      const url = `/api/all-myClosedTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.textoSnackbar = message;
      this.resultSet3 = (data == 1) ? resultSet : [];
      this.totalRows3 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    this.getPonenciasAll();
  },
  computed: {}
}
</script>
<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}
</style>