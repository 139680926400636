/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-alert v-model="modalEsperar" show variant="primary" style="margin-top: 100px" >
      <div style="text-align: center">
        <!--        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history"
                     viewBox="0 0 16 16">
                  <path
                      d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                  <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                  <path
                      d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                </svg>-->
        <h5>Favor de esperar un momento, procesando información...</h5>
      </div>
    </b-alert>

    <!-- modal abrir encuesta -->
    <b-modal v-model="modalAbrirEncuesta" persistent size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">

              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <h5 style="color:#f8105e">{{tituloencuesta}}</h5>
                </div>

                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                >
                  <p style="text-align: justify">
                    {{introEncuesta}}
                  </p>
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>

                <div v-for="input in dataEncuestas" :key="input.id">
                  <div class="mx-3 mt-3 p-2 criterio" >
                    <b-jumbotron :lead=input.pregunta></b-jumbotron>
                    <b-form-radio-group >
                      <b-row>

                        <b-col sm="12"  v-for="descripcion in input.respuestas" :key="descripcion.id">
                          <b-card style="border:1px #dedede solid;  border-radius: 15px;" >
                            <b-card-text>
                              <b-form-radio
                                  :id="'respuesta-' + descripcion.id"
                                  :value="descripcion.id"
                                  @change="saveResponsesMultiple(input.sesion_id,input.id, descripcion.id,'multiple')"
                              >{{ descripcion.contenido }}
                              </b-form-radio>
                            </b-card-text>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-form-radio-group>
                    <b-form-textarea
                        :id="'abierta-' + input.id"
                        class="my-2"
                        v-model="respuestaAbierta[input.id]"
                        type="text"
                        rows="2"
                        max-rows="3"
                        placeholder="Ingresa la opción de respuesta"
                        @focusout="saveResponsesAbierta(input.sesion_id,input.id, 'abierta',respuestaAbierta[input.id])"
                        v-if="input.respuesta_abierta==1"
                    >
                    </b-form-textarea>
                  </div>
                </div>
                <!--                <b-row  style="display: flex; justify-content: space-around; align-items:center;" class="my-3 mx-3">

                                  <b-button @click="modalAbrirEncuesta=false"
                                            class="btn-studioAPP"
                                            variant="outline-warning">Cancelar
                                  </b-button>
                                  <b-button @click="modalAbrirEncuesta=false"
                                            class="btn-studioAPP"
                                            variant="outline-warning"> Finalizar Encuesta
                                  </b-button>
                                </b-row>-->
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right ml-2"
              @click="modalAbrirEncuesta=false">
            Finalizar encuesta
          </b-button>

          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalAbrirEncuesta=false">
            Cancelar
          </b-button>

        </div>

      </template>
    </b-modal>
    <!-- FIN abrir encuesta --->


    <!--    <div class="fixed-top">
          <b-img
              center
              rounded
              src="/img/banner-asiste-recreo-puebla.png"
              height="auto"
              width="600"
              fluid
          >
          </b-img>
        </div>-->

    <!-- banner-->
    <!--    <div>
          <b-card

              img-src="/img/banner-asiste-recreo-puebla.png"
              img-alt="Recreo Puebla"
              text-variant="gray"
              title="Image Overlay"
              sub-title="Subtitle"
          >
            <b-card-text>
              Some quick example text to build on the card and make up the bulk of the card's content.
            </b-card-text>
          </b-card>
        </div>-->
    <!--    <b-row v-if="activo==true" style="background-color: #dadada">
          <b-col cols="12">

          </b-col>
        </b-row>-->

    <b-row v-if="activo==true" >
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <h3 style="color: #f8105e">Día 1 - 24 de febrero, 2023</h3>
          </div>
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">7:30 - 8:30am Registro y desayuno</h4>
            <h4 style="color: #ffffff">8:15 - 9:15am Ponencial Magistral</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col cols="12">
        <b-img
            center
            rounded
            src="https://storage.googleapis.com/asiste/ponencias-recreoQueretaro/1.png"
            height="auto"
            width="660"
            fluid
        >
        </b-img>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">9:30am - 11:00am Sesión 1- 90 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index<2">
          <b-card-group deck>
            <!--          <b-card :img-src="ponencia.imagen" img-alt="Image" style="height: 39rem">-->
            <b-card style="height: 25rem">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>

                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>

                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=2 && index<4">
          <b-card-group deck>
            <b-card style="height: 25rem;margin-top: 20px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>

                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>

                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">11:00am - 12:30pm Sesión 2- 90 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=4 && index<=5">
          <b-card-group deck>
            <b-card style="height: 25rem;margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=6 && index<=7">
          <b-card-group deck>
            <b-card style="height: 25rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">12:30pm - 1:30pm Comida</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">1:30pm - 3:00pm Sesión 3- 90 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=8 && index<=9">
          <b-card-group deck>
            <b-card style="height: 32rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=10 && index<=11">
          <b-card-group deck>
            <b-card style="height: 25rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">3:10pm - 4:10pm Sesión 4- 60 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=12 && index<=13">
          <b-card-group deck>
            <b-card style="height: 22rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=14 && index<=15">
          <b-card-group deck>
            <b-card style="height: 25rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">4:15pm - 4:40pm Presentaciones Ganadores de Rifa</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <h3 style="color: #f8105e">Día 2 - 25 de febrero, 2023</h3>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">8:00am - 8:30am Desayuno</h4>
          </div>
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">8:30am - 9:30am  G Party! con  Ana Roa y Patty Zamora</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">9:45am - 11:15am Sesión 5- 90 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=16 && index<=17">
          <b-card-group deck>
            <b-card style="height: 25rem;margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=18 && index<=19">
          <b-card-group deck>
            <b-card style="height: 30rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">11:20am - 12:20pm Sesión 6- 60 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=20 && index<=21">
          <b-card-group deck>
            <b-card style="height: 25rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=22 && index<=23">
          <b-card-group deck>
            <b-card style="height: 25rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>


    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">12:30pm - 1:15pm Comida</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #0a00b5">
            <h4 style="color: #ffffff">1:20pm - 2:20pm Sesión 7- 60 minutos</h4>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=24 && index<=25">
          <b-card-group deck>
            <b-card style="height:30rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="activo==true">
      <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in resultSet" :key="index">
        <div v-if="index>=26 && index<=27">
          <b-card-group deck>
            <b-card style="height: 30rem; margin-top: 10px">
              <b-card-text class="text-justify">
                <h4 style="text-align: center; margin-bottom: 10px;color:#f8105e">{{ ponencia.titulo_ponencia }}</h4>
                <h6 style="text-align: center; margin-bottom: 10px;color:#0a00b5">{{ ponencia.nombre_presentador }}</h6>
                {{ ponencia.descripcion_ponencia }}
              </b-card-text>
              <template #footer>
                <b-row>
                  <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                  <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                  <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                  <b-col cols="12" md="8" lg="12">
                    <br>
                    <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                              @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                      Inscribirse
                    </b-button>
                    <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                    <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                    <b-button :pressed="false" style="margin-left: 5px" variant="info" size="sm" title="Encuesta"
                              @click="abrirEncuesta(ponencia.id)"
                              v-if="ponencia.inscrito===1 && ponencia.encuesta>=1 && ponencia.encuesta_activa===1">
                      Encuesta
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-col>
    </b-row>

    <b-row style="margin-top:30px" v-if="activo==true">
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: #f8105e">
            <h4 style="color: #ffffff">2:30pm - 3:15pm Cierre y Rifa con Patty Zamora</h4>
          </div>
        </div>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
import PagoService from "@/services/PagoService";
import moment from "moment"
import 'moment/locale/es';
import EncuestaService from "@/services/EncuestaService";

export default {
  mixins: [toast],
  data() {
    return {
      uuid:(this.$route.params.idSesion) ? (this.$route.params.idSesion) : 0,
      activo:false,
      modalEditarSesion: false,
      modalAbrirEncuesta: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,
      encuesta:{
        ponencia_id:null
      },
      sesion: {
        id: 0
        , titulo_ponencia: null
        , evento_id: 0
        , nombre_salon: null
        , horario_inicio_ponencia: null
        , horario_fin_ponencia: null
        , numero_ponencia: null
        , duracion_minutos_ponencia: null
        , nombre_presentador: null
        , nivel_ponencia: null
        , descripcion_ponencia: null
        , estatusActivo: null
        , maximo_permitido_asistentes: null
        , seccion: null
        , dia_ponencia: null
        ,inscrito:null
        ,total_inscritos:null
      },
      dia_ponencia: [
        {value: 1, text: '1'},
        {value: 2, text: '2'}
      ],

      modalAgregarInvitado: false,
      modalVerComprobante: true,
      modalEsperar: true,
      previewObservacionesValidacion: '',
      modalValidarIngreso: false,
      textoSnackbar: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        {label: 'Día', key: 'dia_ponencia', align: 'left'}
        , {label: 'No. Ponencia', key: 'numero_ponencia', align: 'left'}
        , {label: 'Titulo ponencia', key: 'titulo_ponencia', align: 'left'}
        , {label: 'Presentador', key: 'nombre_presentador', align: 'left'}
        , {label: 'Salon', key: 'nombre_salon', align: 'left'}
        , {label: 'Horario', key: 'horario', align: 'left'}
        , {label: 'Acciones', key: 'acciones', align: 'left'}

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter3: null,
      filterOn3: [],
      resultSet3: []

      , infoPago: {
        id: 0,
        url_comprobante_pago: null,
        comprobanteEstatusAprobado: 0,
        observacionesValidacion: null,
        cliente: {
          name: null,
          escuela_procedencia: null,
          email: null
        }
      }
      ,dataEncuestas:[]
      ,introEncuesta:null
      ,tituloencuesta:null
      ,respuestaAbierta:[]
    }
  },
  methods: {
    async saveResponsesMultiple(sesion_id,encuesta_id,respuesta_id,tipo){
      let formData = new FormData();
      formData.append("sesion_id", sesion_id);
      formData.append("encuesta_id", encuesta_id);
      formData.append("respuesta_id",  respuesta_id);
      formData.append("tipo", tipo);
      formData.append("respuestaAbierta", null);
      await EncuestaService.saveResponses(formData);
    },
    async saveResponsesAbierta(sesion_id,encuesta_id,tipo,respuesta){
      let formData = new FormData();
      formData.append("sesion_id", sesion_id);
      formData.append("encuesta_id", encuesta_id);
      formData.append("respuesta_id",  null);
      formData.append("tipo", tipo);
      formData.append("respuestaAbierta", respuesta);
      await EncuestaService.saveResponses(formData);
    },
    async abrirEncuesta(itemSelected){
      this.dataEncuestas=[]
      this.introEncuesta=null
      this.modalAbrirEncuesta=true
      this.encuesta.ponencia_id=itemSelected
      this.tituloencuesta = "Favor de esperar un momento, cargando información..."
      const response = await EncuestaService.getEncuestasRespuestas(itemSelected);
      this.tituloencuesta = null
      this.dataEncuestas = response.data.encuestas
      this.introEncuesta = response.data.intro
      this.tituloencuesta = response.data.titulo
      //console.log("encuestas")
      //console.log(this.dataEncuestas)
    },
    async inscribirse(idPonencia){

      let formData = new FormData();
      formData.append("idPonencia", idPonencia);
      //this.wait()
      const response = await EventoService.inscribirsePonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        await this.getPonenciasAll()
      }
      this.toast('b-toaster-bottom-center', true, message, color);

    },
    openModalUpdateSesion(sessionSelected) {
      this.modalEsperar = true
      this.resetFormSesion()
      this.sesion.id = sessionSelected.id
      this.sesion.titulo_ponencia = sessionSelected.titulo_ponencia
      this.sesion.nombre_salon = sessionSelected.nombre_salon
      this.sesion.horario_inicio_ponencia = sessionSelected.horario_inicio_ponencia
      this.sesion.horario_fin_ponencia = sessionSelected.horario_fin_ponencia
      this.sesion.numero_ponencia = sessionSelected.numero_ponencia
      this.sesion.duracion_minutos_ponencia = sessionSelected.duracion_minutos_ponencia
      this.sesion.nombre_presentador = sessionSelected.nombre_presentador
      this.sesion.nivel_ponencia = sessionSelected.nivel_ponencia
      this.sesion.descripcion_ponencia = sessionSelected.descripcion_ponencia
      this.sesion.maximo_permitido_asistentes = sessionSelected.maximo_permitido_asistentes
      this.sesion.seccion = sessionSelected.seccion
      this.sesion.dia_ponencia = sessionSelected.dia_ponencia
      this.modalEditarSesion = true
      this.modalEsperar = false

    },
    resetFormSesion() {
      this.sesion.id = 0
      this.sesion.titulo_ponencia = null
      this.sesion.evento_id = 0
      this.sesion.nombre_salon = null
      this.sesion.horario_inicio_ponencia = null
      this.sesion.horario_fin_ponencia = null
      this.sesion.numero_ponencia = null
      this.sesion.duracion_minutos_ponencia = null
      this.sesion.nombre_presentador = null
      this.sesion.nivel_ponencia = null
      this.sesion.descripcion_ponencia = null
      this.sesion.estatusActivo = null
      this.sesion.maximo_permitido_asistentes = null
      this.sesion.seccion = null
      this.sesion.dia_ponencia = null
      this.nombre_sesion_actual = null
      this.sesion_id_actual = null
      this.sesion.inscrito=null
      this.sesion.total_inscritos=null
    },
    openModalDeleteSesion(sesion) {
      this.nombre_sesion_actual = sesion.titulo_ponencia
      this.sesion_id_actual = sesion.id
      this.modalDeleteSesion = true
    },
    async updatePonencia() {
      if (this.sesion.titulo_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_presentador == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
        return false;
      } else if (this.sesion.dia_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.descripcion_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_salon == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
        return false;
      } else if (this.sesion.maximo_permitido_asistentes == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
        return false;
      } else if (this.sesion.horario_inicio_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.horario_fin_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.numero_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el número de ponencia!', 'danger');
        return false;
      } else if (this.sesion.duracion_minutos_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la duración en minutos de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nivel_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.seccion == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
        return false;
      }

      let formData = new FormData();
      formData.append("id", this.sesion.id);
      formData.append("titulo_ponencia", this.sesion.titulo_ponencia);
      formData.append("nombre_salon", this.sesion.nombre_salon);
      formData.append("horario_inicio_ponencia", this.sesion.horario_inicio_ponencia);
      formData.append("horario_fin_ponencia", this.sesion.horario_fin_ponencia);
      formData.append("numero_ponencia", this.sesion.numero_ponencia);
      formData.append("duracion_minutos_ponencia", this.sesion.duracion_minutos_ponencia);
      formData.append("nombre_presentador", this.sesion.nombre_presentador);
      formData.append("nivel_ponencia", this.sesion.nivel_ponencia);
      formData.append("descripcion_ponencia", this.sesion.descripcion_ponencia);
      formData.append("maximo_permitido_asistentes", this.sesion.maximo_permitido_asistentes);
      formData.append("seccion", this.sesion.seccion);
      formData.append("dia_ponencia", this.sesion.dia_ponencia);
      this.modalEsperar = true
      this.modalEditarSesion = false
      const response = await EventoService.updatePonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        await this.getPonenciasAll();
        this.toast('b-toaster-bottom-center', true, message, color);
      } else {
        this.toast('b-toaster-bottom-center', true, message, color);
      }
      this.resetFormSesion()
    },
    async guardarPonencia()
    {
      if (this.sesion.titulo_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_presentador == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
        return false;
      } else if (this.sesion.dia_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.descripcion_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_salon == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
        return false;
      } else if (this.sesion.maximo_permitido_asistentes == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
        return false;
      } else if (this.sesion.horario_inicio_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.horario_fin_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.numero_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el número de ponencia!', 'danger');
        return false;
      } else if (this.sesion.duracion_minutos_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la duración en minutos de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nivel_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.seccion == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
        return false;
      }

      let formData = new FormData();
      formData.append("titulo_ponencia", this.sesion.titulo_ponencia);
      formData.append("nombre_presentador", this.sesion.nombre_presentador);
      formData.append("descripcion_ponencia", this.sesion.descripcion_ponencia);
      formData.append("nombre_salon", this.sesion.nombre_salon);
      formData.append("maximo_permitido_asistentes", this.sesion.maximo_permitido_asistentes);
      formData.append("horario_inicio_ponencia", this.sesion.horario_inicio_ponencia);
      formData.append("horario_fin_ponencia", this.sesion.horario_fin_ponencia);
      formData.append("numero_ponencia", this.sesion.numero_ponencia);
      formData.append("duracion_minutos_ponencia", this.sesion.duracion_minutos_ponencia);
      formData.append("dia_ponencia", this.sesion.dia_ponencia);
      formData.append("nivel_ponencia", this.sesion.nivel_ponencia);
      formData.append("seccion", this.sesion.seccion);
      this.wait()
      this.modalAgregarInvitado = false
      const response = await EventoService.agregarPonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalAgregarInvitado = false
        await this.getPonenciasAll();
        this.toast('b-toaster-bottom-center', true, message, color);
      } else {
        //this.modalAgregarInvitado=false
        this.toast('b-toaster-bottom-center', true, message, color);
      }
      this.resetFormSesion()
    },
    async guardarValidacion() {
      let formData = new FormData();
      formData.append("id", this.infoPago.id);
      //console.log("id user", this.infoPago.id)
      this.modalValidarIngreso = false
      this.modalEsperar = true
      const response = await PagoService.guardarRegistroDeAsistencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        this.getPonenciasAll()

      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    hidemodalValidarPago() {
      this.modalValidarIngreso = false
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getPonenciasAll() {
      this.resultSet = []
      this.modalEsperar = true
      this.activo=false
      // const response = await EventoService.getAllPonencias();
      const response = await EventoService.getPonenciasByUserInscrito();
      this.modalEsperar = false
      this.activo=true
      this.resultSet = response.data.ponencias;
      /*      console.log("ponencias")
            console.log(this.resultSet)*/
      this.totalRows = response.data.cantidad;
    },
    showModalValidarPago(item) {
      this.modalValidarIngreso = true
      this.infoPago = item
    },
    hideModalCrearEvento() {
      this.modalCrearEvento = false
    },
    async deleteSesion() {
      let formData = new FormData();
      formData.append("id", this.sesion_id_actual);
      this.modalDeleteSesion = false
      this.modalEsperar = true
      const response = await EventoService.deleteSesion(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        this.resetFormSesion()
        this.getPonenciasAll();
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    async guardarEvento() {
      if (this.nombre_evento === '') {
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger');
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      this.wait()
      this.modalCrearEvento = false
      const response = await EventoService.saveEvento(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.getEventos();
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    showModalCrearEvento() {
      this.modalCrearEvento = true
    },
    async getData() {
      this.resultSet = [];
      const url = `/api/all-events`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.resultSet = (data == 1) ? resultSet : [];
      this.totalRows = this.resultSet.length;
      this.textoSnackbar = message;
      this.showAlert();
    },//getData
    async getMyTickets() {
      this.resultSet2 = [];
      const url = `/api/all-myTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.resultSet2 = (data == 1) ? resultSet : [];
      this.totalRows2 = this.resultSet2.length;
      this.textoSnackbar = message;
      this.showAlert();
    },//getMyTickets
    async getMyClosedTickets() {
      this.resultSet3 = [];
      const url = `/api/all-myClosedTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.textoSnackbar = message;
      this.resultSet3 = (data == 1) ? resultSet : [];
      this.totalRows3 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    this.getPonenciasAll();
  },
  computed: {}
}
</script>
<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}
</style>