import api from '@/services/api';

export default{
    validarComprobante(uuidEvento){
        let url          = `/api/masivos/validarComprobante/${uuidEvento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getInscripciones(uuidEvento){
        let url          = `/api/masivos/inscritos/${uuidEvento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    saveEmail(formData){
        let url          = `/api/masivos/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },






    saveValidacionPago(formData){
        let url          = `/api/pagos/storeValidacionPago`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

    saveComprobantePago(formData){
        let url          = `/api/eventos/storeComprobantePago`;
        let response     = api({
            url: url,
            method: 'POST',
            /*            headers: {
                            'content-type': 'multipart/form-data'
                        },*/
            headers: {
                //'Access-Control-Allow-Headers': 'X-Requested-With, , X-Token-Auth, Authorization',
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    saveInvitado(formData){
        let url          = `/api/eventos/storeInvitado`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getEvento(uuidEvento){
        let url        = `/api/eventos/getEvento/${uuidEvento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },


    saveResponses(formData){
        let url          = `/api/preguntas/responsesUser/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

}