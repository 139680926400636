/* eslint-disable vue/no-unused-vars */
<template>

  <b-container fluid>
    <!--ventana confirmacion -->
    <b-alert v-model="modalConfirmacion" variant="success" show>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
             viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
        {{ mensajeConfirmacion }}
      </div>
    </b-alert>
    <!--cerrar ventana confirmacion -->


    <!-- VENTANA ESPERAR-->
    <b-alert v-model="modalEsperar" show variant="warning">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history"
             viewBox="0 0 16 16">
          <path
              d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
          <path
              d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Favor de esperar un momento, procesando información...
      </div>
    </b-alert>
    <!-- CERRAR VENTANA ESPERAR-->

    <!-- modal agregar Ponente -->
    <b-modal v-model="modalAgregarPonente" persistent title="Agregar ponente" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    >
                  Capturar los datos que se solicitan, para agregar al ponente.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Nombre</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="nombre"
                          v-model="sesion.nombre"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Apellidos</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="apellidos"
                          v-model="sesion.apellidos"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Biografía</label>
                    </b-col>
                    <b-col sm="10">
                      <b-form-textarea
                          ref="biografia"
                          v-model="sesion.biografia"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Email personal</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailPersonal"
                          v-model="sesion.email"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Email interno</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailInterno"
                          v-model="sesion.email_interno"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Facebook</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="facebook"
                          v-model="sesion.facebook"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Instagram</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="instagram"
                          v-model="sesion.instagram"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Twitter</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="twitter"
                          v-model="sesion.twitter"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>LinkedIn</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="linkedin"
                          v-model="sesion.linkedin"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Certificaciones</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-textarea
                          ref="certificaciones"
                          v-model="sesion.certificaciones"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                    <b-col sm="2">
                      <label>Ponente nivelA</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-select
                          ref="ponenteNivelA"
                          v-model="sesion.ponenteNivelA"
                          :options="optionsPonenteNivelA"
                          size="sm"
                      >

                      </b-form-select>
                    </b-col>
                  </b-row>
                  <!--


                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Máximo asistentes</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="maximo_permitido_asistentes"
                                            v-model="sesion.maximo_permitido_asistentes"
                                            type="number"
                                        >
                                        </b-form-input>
                                      </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Hora inicio</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input v-model="sesion.horario_inicio_ponencia" type="time"></b-form-input>
                                      </b-col>

                                      <b-col sm="3">
                                        <label>Hora fin</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input v-model="sesion.horario_fin_ponencia" type="time"></b-form-input>
                                      </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Número ponencia</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="numero_ponencia"
                                            v-model="sesion.numero_ponencia"
                                            type="number"
                                        >
                                        </b-form-input>
                                      </b-col>
                                      <b-col sm="3">
                                        <label>Duración minutos</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="duracion_minutos_ponencia"
                                            v-model="sesion.duracion_minutos_ponencia"
                                            type="number"
                                        >
                                        </b-form-input>
                                      </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Nivel ponencia</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="nivel_ponencia"
                                            v-model="sesion.nivel_ponencia"
                                            type="text"
                                        >
                                        </b-form-input>
                                      </b-col>
                                      <b-col sm="2">
                                        <label>Sección</label>
                                      </b-col>
                                      <b-col sm="4">
                                        <b-form-textarea
                                            ref="seccion"
                                            v-model="sesion.seccion"
                                            type="text"
                                            rows="1"
                                            max-rows="1"
                                        >
                                        </b-form-textarea>
                                      </b-col>
                                    </b-row>-->


                  <div class="col-sm-12 my-2">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right mx-2 my-1"
                        @click="guardarPonente()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalAgregarPonente()">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE agregar Ponente --->

    <!-- modal ver Ponente -->
    <b-modal v-model="modalVerPonente" persistent size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                >
                  Consultar Ponente.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Nombre</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="nombre"
                          v-model="sesion.nombre"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Apellidos</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="apellidos"
                          v-model="sesion.apellidos"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Biografía</label>
                    </b-col>
                    <b-col sm="10">
                      <b-form-textarea
                          ref="biografia"
                          v-model="sesion.biografia"
                          type="text"
                          rows="2"
                          max-rows="2"
                          :readonly="true"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Email personal</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailPersonal"
                          v-model="sesion.email"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Email interno</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailInterno"
                          v-model="sesion.email_interno"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Facebook</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="facebook"
                          v-model="sesion.facebook"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Instagram</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="instagram"
                          v-model="sesion.instagram"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Twitter</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="twitter"
                          v-model="sesion.twitter"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>LinkedIn</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="linkedin"
                          v-model="sesion.linkedin"
                          type="text"
                          :readonly="true"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Certificaciones</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-textarea
                          ref="certificaciones"
                          v-model="sesion.certificaciones"
                          type="text"
                          rows="2"
                          max-rows="2"
                          :readonly="true"
                      >
                      </b-form-textarea>
                    </b-col>
                    <b-col sm="2">
                      <label>Ponente nivelA</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-select
                          ref="ponenteNivelA"
                          v-model="sesion.ponenteNivelA"
                          :options="optionsPonenteNivelA"
                          size="sm"
                          :disabled="true"
                      >

                      </b-form-select>
                    </b-col>
                  </b-row>

                  <div class="col-sm-12 my-2">

                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalVerPonente=!modalVerPonente">
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL ver Ponente --->

    <!-- modal editar ponente-->
    <b-modal v-model="modalEditarPonente" persistent title="Editar ponente" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                >
                  Capturar los datos que desea actualizar.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Nombre</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="nombre"
                          v-model="sesion.nombre"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Apellidos</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="apellidos"
                          v-model="sesion.apellidos"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Biografía</label>
                    </b-col>
                    <b-col sm="10">
                      <b-form-textarea
                          ref="biografia"
                          v-model="sesion.biografia"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Email personal</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailPersonal"
                          v-model="sesion.email"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Email interno</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailInterno"
                          v-model="sesion.email_interno"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Facebook</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="facebook"
                          v-model="sesion.facebook"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Instagram</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="instagram"
                          v-model="sesion.instagram"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Twitter</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="twitter"
                          v-model="sesion.twitter"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>LinkedIn</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="linkedin"
                          v-model="sesion.linkedin"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Certificaciones</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-textarea
                          ref="certificaciones"
                          v-model="sesion.certificaciones"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                    <b-col sm="2">
                      <label>Ponente nivelA</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-select
                          ref="ponenteNivelA"
                          v-model="sesion.ponenteNivelA"
                          :options="optionsPonenteNivelA"
                          size="sm"
                      >

                      </b-form-select>
                    </b-col>
                  </b-row>

                  <div class="col-sm-12 my-2">

                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right ml-2"
              @click="updatePonente()"
          >
            Actualizar
          </b-button>
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalEditarPonente=!modalEditarPonente">
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- fin midal editar ponente -->

    <!-- modal borrar ponente -->
    <b-modal
        v-model="modalDeleteSesion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar de eliminar el registro del Ponente: <b>{{this.sesion.nombre}} {{this.sesion.apellidos}}</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="danger"
              size="sm"
              class="float-right ml-2"
              @click="deletePonente()"
          >
            Eliminar
          </b-button>
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click=" modalDeleteSesion= false"
          >
            Cancelar
          </b-button>
        </div>
      </template>

    </b-modal>
    <!-- FIN borrar ponente -->


    <!--DASHBOARD PRINCIPAL-->
    <b-row>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <h5 class="font-weight-bold">Ponentes</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center p-2 my-2">
              <b-button :disabled=disabledButton variant="outline-secondary" size="md"
                        @click="modalAgregarPonente=!modalAgregarPonente">
                Agregar ponencia
              </b-button>
            </div>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Actualizar listado"
                          @click="getPonentes()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                       class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                    <path
                        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                    <path fill-rule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                  </svg>
                </b-button>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <b-table class="table data-table"
                     :items="resultSet"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText = 'Por el momento no hay registros que mostrar' }}
                </h5>
              </template>
              <template #cell(nombrecompleto)="row">
                {{row.item.nombre}} {{row.item.apellidos}}
              </template>
              <template #cell(acciones)="row">
                <div class="d-flex align-items-center">
                  <b-button :pressed="false" variant="primary" size="sm" class="ml-1" title="Ver"
                            @click="showModalVer(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                  </b-button>
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1"  title="Editar"
                            @click="showModalUpdate(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>

                  <b-button :pressed="false" variant="danger" size="sm" class="ml-1" title="Eliminar"
                            @click="showModalDelete(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-trash" viewBox="0 0 16 16">
                      <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </b-button>
                </div>
              </template>

            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import PonenteService from '@/services/PonenteService';
import moment from "moment"
import 'moment/locale/es';

export default {
  mixins: [toast],
  data() {
    return {
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      disabledButton: false,
      modalVerPonente: false,
      modalEditarPonente: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,
      sesion: {
        id: 0
        , nombre: null
        , user_id: 0
        , apellidos: null
        , biografia: null
        , email: null
        , facebook: null
        , instagram: null
        , twitter: null
        , linkedin: null
        , certificaciones: null
        , estatusActivo: null
        , ponenteNivelA: null
        , email_interno: null
      },
      dia_ponencia: [
        {value: 1, text: '1'},
        {value: 2, text: '2'}
      ],
      modalAgregarPonente: false,
      modalEsperar: false,
      modalValidarIngreso: false,
      fields: [
        {label: 'Nombre completo', key: 'nombrecompleto', align: 'left'}
        , {label: 'Biografía', key: 'biografia', align: 'left'}
        , {label: 'Email', key: 'email', align: 'left'}
        , {label: 'Certificaciones', key: 'certificaciones', align: 'left'}
        , {label: 'Acciones', key: 'acciones', align: 'center'}
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',
      optionsPonenteNivelA: [
        {value: 'Si', text: 'Si'},
        {value: 'No', text: 'No'}
      ],
      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter3: null,
      filterOn3: [],
      resultSet3: []
      , infoPago: {
        id: 0,
        url_comprobante_pago: null,
        comprobanteEstatusAprobado: 0,
        observacionesValidacion: null,
        cliente: {
          name: null,
          escuela_procedencia: null,
          email: null
        }
      }
    }
  },
  methods: {
    hideModalAgregarPonente(){
      this.modalAgregarPonente=false
      this.hiddeWait()
    },
    async guardarPonente()
    {
      if (this.sesion.nombre == null) {
        this.toast('b-toaster-top-center', true, '¡Debe capturar el nombre del ponente!', 'danger');
        return false;
      } else if (this.sesion.apellidos == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar los apellidos del ponente!', 'danger');
        return false;
      } else if (this.sesion.biografia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la biografía del ponente!', 'danger');
        return false;
      } else if (this.sesion.email == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el email del ponente!', 'danger');
        return false;
      } else if (this.sesion.ponenteNivelA == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe si el ponente es de nivelA!', 'danger');
        return false;
      }
      else{
        this.showWait()
          let formData = new FormData();
          formData.append("nombre", this.sesion.nombre);
          formData.append("apellidos", this.sesion.apellidos);
          formData.append("biografia", this.sesion.biografia);
          formData.append("email", this.sesion.email);
          formData.append("facebook", this.sesion.facebook);
          formData.append("instagram", this.sesion.instagram);
          formData.append("twitter", this.sesion.twitter);
          formData.append("linkedin", this.sesion.linkedin);
          formData.append("certificaciones", this.sesion.certificaciones);
          formData.append("email_interno", this.sesion.email_interno);
          formData.append("ponenteNivelA", this.sesion.ponenteNivelA);
          //this.wait()
          this.modalAgregarPonente = false
          const response = await PonenteService.guardarPonencia(formData);
          let {
            success,
            message
          } = response.data;
          if (success) {
            this.hiddeWait()
            this.showConfirmacion(message)
            await this.getPonentes()
          } else {
            this.hiddeWait()
            this.showConfirmacion(message)
          }
          this.resetForm()
      }
    },
    async deletePonente() {
      this.showWait()
      let formData = new FormData();
      formData.append("id", this.sesion.id);
      this.modalDeleteSesion = false
      this.modalEsperar = true
      const response = await PonenteService.deletePonente(formData);
      let {
        success,
        message,
      } = response.data;
      if (success) {
        this.hiddeWait()
        this.showConfirmacion(message)
        this.resetForm()
        await this.getPonentes()
      }
      else {
        this.hiddeWait()
        this.showConfirmacion(message)
      }
      this.resetForm()
    },
    async updatePonente() {

      if (this.sesion.nombre == null) {
        this.toast('b-toaster-top-center', true, '¡Debe capturar el nombre del ponente!', 'danger');
        return false;
      } else if (this.sesion.apellidos == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar los apellidos del ponente!', 'danger');
        return false;
      } else if (this.sesion.biografia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la biografía del ponente!', 'danger');
        return false;
      } else if (this.sesion.email == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el email del ponente!', 'danger');
        return false;
      } else if (this.sesion.ponenteNivelA == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe si el ponente es de nivelA!', 'danger');
        return false;
      }
      else{
        this.showWait()
        let formData = new FormData();
        formData.append("id", this.sesion.id);
        formData.append("nombre", this.sesion.nombre);
        formData.append("apellidos", this.sesion.apellidos);
        formData.append("biografia", this.sesion.biografia);
        formData.append("email", this.sesion.email);
        formData.append("facebook", this.sesion.facebook);
        formData.append("instagram", this.sesion.instagram);
        formData.append("twitter", this.sesion.twitter);
        formData.append("linkedin", this.sesion.linkedin);
        formData.append("certificaciones", this.sesion.certificaciones);
        formData.append("email_interno", this.sesion.email_interno);
        formData.append("ponenteNivelA", this.sesion.ponenteNivelA);
        //this.wait()
        this.modalEditarPonente = false
        const response = await PonenteService.actualizarPonente(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          this.showConfirmacion(message)
          await this.getPonentes()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
        this.resetForm()
      }
    },
    showWait() {
      this.modalEsperar = true
      this.disabledBtn()
    },
    hiddeWait() {
      this.modalEsperar = false
      this.enabledBtn()
    },
    disabledBtn() {
      this.disabledButton = true
    },
    enabledBtn() {
      this.disabledButton = false
    },
    resetForm() {
      this.sesion.id = 0
      this.sesion.user_id = 0
      this.sesion.nombre = null
      this.sesion.apellidos = null
      this.sesion.biografia = null
      this.sesion.email = null
      this.sesion.facebook = null
      this.sesion.instagram = null
      this.sesion.twitter = null
      this.sesion.linkedin = null
      this.sesion.certificaciones = null
      this.sesion.estatusActivo = null
      this.sesion.ponenteNivelA = null
      this.sesion.email_interno = null
    },

    hidemodalValidarPago() {
      this.modalValidarIngreso = false
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getPonentes() {
      this.showWait()
      this.resultSet = []
      const response = await PonenteService.getPonentes();
      this.hiddeWait()
      this.resultSet = response.data.ponentes;
      this.totalRows = response.data.cantidad;
    },
    showConfirmacion(message) {
      this.mensajeConfirmacion = message
      setTimeout(() => {
        this.modalConfirmacion = false
      }, 3000);
      this.modalConfirmacion = true

    },
    hiddeConfirmacion() {
      this.mensajeConfirmacion = null
      this.modalConfirmacion = false
    },
    showModalVer(sessionSelected){
      this.modalVerPonente=true
      this.sesion.id = sessionSelected.id
      this.sesion.nombre = sessionSelected.nombre
      this.sesion.apellidos = sessionSelected.apellidos
      this.sesion.biografia = sessionSelected.biografia
      this.sesion.email = sessionSelected.email
      this.sesion.facebook = sessionSelected.facebook
      this.sesion.instagram = sessionSelected.instagram
      this.sesion.twitter = sessionSelected.twitter
      this.sesion.linkedin = sessionSelected.linkedin
      this.sesion.certificaciones = sessionSelected.certificaciones
      this.sesion.estatusActivo = sessionSelected.estatusActivo
      this.sesion.ponenteNivelA = sessionSelected.ponenteNivelA
      this.sesion.email_interno = sessionSelected.email_interno
    },
    showModalUpdate(sessionSelected){
      this.modalEditarPonente=true
      this.sesion.id = sessionSelected.id
      this.sesion.nombre = sessionSelected.nombre
      this.sesion.apellidos = sessionSelected.apellidos
      this.sesion.biografia = sessionSelected.biografia
      this.sesion.email = sessionSelected.email
      this.sesion.facebook = sessionSelected.facebook
      this.sesion.instagram = sessionSelected.instagram
      this.sesion.twitter = sessionSelected.twitter
      this.sesion.linkedin = sessionSelected.linkedin
      this.sesion.certificaciones = sessionSelected.certificaciones
      this.sesion.estatusActivo = sessionSelected.estatusActivo
      this.sesion.ponenteNivelA = sessionSelected.ponenteNivelA
      this.sesion.email_interno = sessionSelected.email_interno
    },
    showModalDelete(item){
      this.sesion.id= item.id
      this.sesion.nombre= item.nombre
      this.sesion.apellidos= item.apellidos
      this.modalDeleteSesion = true
    },
  },
  mounted() {

    this.getPonentes();

  },
  computed: {}

}
</script>
<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}
</style>