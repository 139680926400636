import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../views/Home.vue";
import Parametrizacion from "@/views/Eventos/Parametrizacion";

import DashboardEventos from "@/views/Eventos/dashboardEventos";
import AllPagos from "@/views/Pagos/allPagos";

import InscripcionMasiva from "@/views/Pagos/InscripcionMasiva";

import GetTicket from "@/views/Eventos/Get-ticket";
import UserAyuda from "@/views/Eventos/get-userAyuda";
import AllUserAyuda from "@/views/Eventos/all-userAyuda";

//Adding layouts router.
const Layout1 = () => import("@/layouts/backend/Layout-1")

//Adding page content router.
const Dashbord1 = () => import('@/views/backend/Dashboards/Dashbord1')

//Creador de Agenda v1 19-02-2023
const AgendaCreator = () => import('@/views/AgendaCreator/agenda-creator')

import RegisterEvent from "@/views/Ponencias/index";
import CheckInGeneral from "@/views/CheckIn/index";
import VerificarDatos from "@/views/CheckIn/verificarDatos";
import Ponencias from "@/views/Ponencias/ponencias.vue";
import DashboardPonentes from "@/views/Ponentes/dashboardPonentes";
import AgendaSesiones from "@/views/Ponencias/agenda-sesiones";
import AgendaPonente from "@/views/Ponencias/agenda-ponente";
import AgendaSupervisor from "@/views/Ponencias/agenda-supervisor";
//import Agenda from "@/views/Ponencias/agenda";
import Encuesta from "@/views/Ponencias/encuesta";
import Sorteo from "@/views/Ponencias/sorteo";
import Escuelas from "@/views/Escuelas/index";
import NoAutorizado from "@/views/no-autorizado";
import datosActualizados from "@/views/datos-actualizados";

import Agenda2 from "@/views/agendav2";
import AgendaRecreo from "@/views/agendaRecreo";
import SinCheckin from "@/views/sin-checkin";
import Reinscribir from "@/views/reinscribir";

Vue.use(VueRouter)

const childRoute = () => [
  {
    path:"/agenda-recreo",
    name:"AgendaRecreo",
    component:AgendaRecreo
  },
  {
    path:"/reinscribir",
    name:"Reinscribir",
    component:Reinscribir
  },
  {
    path:"/sin-checkin",
    name:"SinCheckin",
    component:SinCheckin
  },
  {
    path:"/agenda",
    name:"Agenda2",
    component:Agenda2
  },
  {
    path:"/datos-actualizados",
    name:"datosActualizados",
    component:datosActualizados
  },
  {
    path:"/no-autorizado",
    name:"NoAutorizado",
    component:NoAutorizado
  },
  {
    path:"/escuelas",
    name:"Escuelas",
    component:Escuelas
  },
  {

    path:"/sorteo",
    name:"Sorteo",
    component:Sorteo
  },
  {
    path:"/encuesta/:idSesion",
    name:"Encuesta",
    component:Encuesta
  },
/*  {
    path:"/agendaaSIS",   //para todos los asistentes
    name:"Agenda",
    component:Agenda
  },*/
  {
    path:"/agenda-creator",
    name:"AgendaCreator",
    component:AgendaCreator
  },
  {
    path:"/ponentes",
    name:"DashboardPonentes",
    component:DashboardPonentes
  },

  {
    /*
    Listado de sesiones por ponente
     */
    path:"/agenda-supervisor",
    name:"AgendaSupervisor",
    component:AgendaSupervisor
  },
  {
    /*
    Listado de sesiones por ponente
     */
    path:"/agenda-ponente",
    name:"AgendaPonente",
    component:AgendaPonente
  },
  {
    /*
    Rev. de visitantes que asisten al evento
     */
    path:"/agenda-sesiones",
    name:"AgendaSesiones",
    component:AgendaSesiones
  },
  {
    /*
    Rev. de visitantes que asisten al evento
     */
    path:"/ponencias",
    name:"Ponencias",
    component:Ponencias
  },
  {
    /*
    cuando un asistente ya pasó a la mesa de registro puede ingresar con su cuenta
    para validar sus datos
     */
    path:"/verificar-datos/:uuid_boleto",
    name:"VerificarDatos",
    component:VerificarDatos
  },
  {
    /*
    Rev. de visitantes que asisten al evento
     */
    path:"/checkin-general",
    name:"CheckInGeneral",
    component:CheckInGeneral
  },
  {
    //
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path:"/register-event/:idEvento",
    name:"RegisterEvent",
    component:RegisterEvent
  },

  {
    path:"/all-userAyuda",
    name:"Ayuda.usuario",
    component:AllUserAyuda
  },
  {
    // https://ayuda.estudio.lab/show
    //http://localhost:8080/show-conv-login?ayuda_User_ticket=57&expires=1639520976&signature=43d937c4e8eb73046a82c39e0234f9f30d9ef6363f984ca522d1ef60b9b52f0c
    path:"/userAyuda/:id",
    name:"Ayuda.usuario.ticket",
    component:UserAyuda
  },
  { 
    path: "/event-get/:id",
    name: "Ver.solicitud",
    component: GetTicket,
  },
  {
    path: "/eventos",
    name: "DashboardEventos",
    component: DashboardEventos,
  },
  {
    path: "/pagos-all",
    name: "Dashboard.Pagos",
    component: AllPagos,
  },
  {
    path: "/register-inscripcion/:idEvento",
    name: "Dashboard.InscripcionMasiva",
    component: InscripcionMasiva,
  },

  {
    path: "/parametrizacion",
    name: "Parametrizacion",
    component: Parametrizacion,
  },

  {
    path: '',
    name: 'layout.dashboard1',
    meta: {  name: 'Dashboard1' },
    component: Dashbord1
  }
]


const routes = [
  {
    path: '/',
    name: '',
    component: Layout1,
    children: childRoute()
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
