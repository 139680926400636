import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import 'leaflet/dist/leaflet.css';
import DateFilter from "./filters/date";
import DateIsoFilter from "./filters/isoDate";
// @ is an alias to /src
import axios from "axios";
import Tawk from "vue-tawk";
const API_BASE_URL = process.env.VUE_APP_API_URL;

Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: API_BASE_URL,
            withCredentials: true,
/*            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization'
            }*/
        });

        let url = process.env.VUE_APP_API_URL;  //http://localhost:8000
        const routeParams  = window.location.search.substr(0);
        const currentRoute  = window.location.pathname;
        if (currentRoute === "/view") {
            //console.log("aqui entro")
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                window.location.href = url + "/api" +currentRoute+routeParams;
            });
        }
        else if (currentRoute === "/confirmar-boleto") {
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                // window.location.href =  url + "/api" + router.currentRoute.fullPath;
                window.location.href = url + "/api" +currentRoute+routeParams;
            });
        }
        else if (currentRoute === "/inscripcion") {
            //console.log("aqui entro")
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                window.location.href = url + "/api" +currentRoute+routeParams;
            });
        }

        Vue.prototype.$api.interceptors.response.use(
            function (response) {
                if (currentRoute === "/view") {
                    //console.warn('entra a response show-conv-login front');
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                    });
                }
                else if (currentRoute === "/inscripcion") {
                    //console.warn('entra a response show-conv-login front');
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                    });
                }
                return response;
            },
            function (error) {
                if (error.response.status === 401) {
                    const currentRoute  = window.location.pathname;
                    let url = process.env.VUE_APP_API_URL;
                    console.warn('entra a 401 show-conv-login front');
                    if (currentRoute === "/view") {
                        Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                            window.location.href = url + "/api" + window.location.href;
                        });
                    }
                    else if (currentRoute === "/inscripcion") {
                        Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                            window.location.href = url + "/api" + window.location.href;
                        });
                    }

                    else {
                        Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                            window.location.href =  url + "/redirect";
                        });
                    }
                }
                if (error.response.status === 419) {
                    //console.warn('entra a 419 show-conv-login front');
                    console.log("error detectado 419---->")
                    Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                    });
                }
                if (error.response.status === 403) {
                    console.warn('entra a 403 show-conv-login front');
                }
                return Promise.reject(error);
            }
        );
    },
});

Vue.prototype.$Tawk = Vue.use(Tawk, {
    tawkSrc: "https://embed.tawk.to/60216cb8a9a34e36b975092c/1eu191c4l",
});




Vue.config.productionTip = false;
Vue.filter("date", DateFilter);
Vue.filter("isoDate", DateIsoFilter);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
