/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex align-items-center p-2">
              <h5 class="font-weight-bold">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="green"
                     class="bi bi-trash" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                </svg>
                Tus datos han sido actualizados satisfactoriamente, y tu boleto ha sido reservado. Te invitamos a revisar tu correo electrónico,
                hemos enviado un mensaje de confirmación con tu enlace de acceso a la Agenda, para el día del Evento.</h5>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {


    }
  },
  computed:{

  },
  methods: {

  },
  mounted() {

  },

}
</script>

<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}

</style>