/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-alert v-model="modalEsperar" show variant="primary" >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Favor de esperar, procesando información.
      </div>
    </b-alert>


    <b-alert v-model="modalBoletoReservado" show variant="primary" >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Tus datos han sido actualizados satisfactoriamente, y tu boleto ha sido reservado. Te invitamos a revisar tu correo electrónico,
        hemos enviado un mensaje de confirmación con tu enlace de acceso a la Agenda, para el día del Evento.
      </div>
    </b-alert>

    <!--buscado princial-->
    <b-row>
      <b-alert
          :show="dismissCountDown"
          dismissible
          variant="success"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
          class="alert-fixed text-center text-bold">
        <p> <span>{{textoSnackbar}}</span></p>
      </b-alert>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <h4 class="font-weight-bold">Confirmar Datos.</h4>
            </div>
          </div>
          <b-row>
            <b-col cols="12">
              <div class="card card-block">
                <div class="card-body p-2">
                  <b-row style="display:flex; justify-content: center" class="mx-2 my-2">
                    <p style="font-weight: normal"><span style="color:red; font-weight: bold">¡Importante!</span>
                      valida detenidamente tus datos, para: <strong>reservar el presente boleto</strong> y con la información se ocupará para generar tu <strong>constancia de participación del evento</strong>.
                      Una vez actualizados, ya no estará disponible este procedimiento.</p>
                  </b-row>

                </div>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                  <b-col cols="12" class="align-content-center">
                    <b-form>
                      <b-form-group
                          id="input-group-1"
                          label="Nombre(s):"
                          label-for="input-nombre"
                      >
                        <b-form-input
                            id="input_nombre"
                            v-model="userInfo.nombre"
                            type="text"
                            ref="nombre"
                            :disabled="disabledUpdate"
                            placeholder="Ingresa tu nombre"
                            required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                          id="input-group-2"
                          label="Apellido(s):"
                          label-for="input-paterno"
                      >
                        <b-form-input
                            id="input-2"
                            v-model="userInfo.paterno"
                            type="text"
                            ref="apellidos"
                            :disabled="disabledUpdate"
                            placeholder="Ingresa tus apellidos"
                            required
                        ></b-form-input>
                      </b-form-group>

<!--                      <b-form-group
                          id="input-group-3"
                          label="Apellido materno:"
                          label-for="input-materno"
                      >
                        <b-form-input
                            id="input-3"
                            v-model="userInfo.materno"
                            :disabled="disabledUpdate"
                            type="text"
                            placeholder="Ingresa tu apellido"
                            required
                        ></b-form-input>
                      </b-form-group>-->

                      <b-form-group
                          id="input-group-4"
                          label="Escuela de procedencia:"
                          label-for="input-escuela_procedencia"
                      >
                        <b-form-input
                            id="input-4"
                            v-model="userInfo.escuela_procedencia"
                            type="text"
                            ref="escuela"
                            :disabled="disabledUpdate"
                            placeholder="Escuela de procedencia"
                            required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                          id="input-group-5"
                          label="Dominio:"
                          label-for="input-dominio"
                      >
                        <b-form-input
                            id="input-3"
                            v-model="userInfo.dominio"
                            type="text"
                            ref="dominio"
                            :disabled="disabledUpdate"
                            placeholder="Dominio (ejemplo: www.miescuela.net)"
                            required
                        ></b-form-input>
                      </b-form-group>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" class="text-center">
                    <b-button
                        class="myButton"
                        @click="guardarRegistro()"
                        :disabled="disabledUpdate"
                    >
                      Actualizar datos
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
import PagoService from "@/services/PagoService";
import moment from "moment"
import 'moment/locale/es';

export default {
  mixins:[toast],
  data(){
    return{
      uuid_boleto:(this.$route.params.uuid_boleto) ? (this.$route.params.uuid_boleto) : 0,
      modalVerComprobante:true,
      modalBoletoReservado:false,
      modalEsperar:true,
      previewObservacionesValidacion:'',
      modalValidarIngreso:false,
      textoSnackbar:'',
      disabledUpdate:false,
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        //{label: 'id',align: 'left',key: 'id',}
        { label: 'Usuario', key: 'nombre', align: 'left' }
        ,{ label: 'Escuela', key: 'escuela_procedencia', align: 'left' }
        ,{ label: 'Recepción', key: 'created_at', align: 'left' }
        ,{ label: 'Estatus', key: 'comprobanteEstatusAprobado', align: 'left' }
        // ,{ label: 'Acciones', key: 'actions', align: 'left' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      filterOn: [],
      resultSet:[],
      search: '',
      nombre_evento:null,
      numero_cuenta_pago:null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter2: null,
      filterOn2: [],
      resultSet2:[],
      search2: '',
      hiddenBtn:false,
      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter3: null,
      filterOn3: [],
      resultSet3:[]
      ,userInfo:[{
        nombre: ''
        ,paterno:''
        ,materno:''
        ,escuela_procedencia:''
        ,dominio:''
      }]
      ,infoPago:{
        id:0,
        url_comprobante_pago:null,
        comprobanteEstatusAprobado:0,
        observacionesValidacion:null,
        cliente:{
          name:null,
          escuela_procedencia:null,
          email:null
        }
      }
    }
  },
  methods: {
    verAgenda(){
      let url = process.env.VUE_APP_URL;  //http://localhost:8000
      window.location.href = url + "/agenda-sesiones";
    },
    async guardarRegistro()
    {
      this.disabledUpdate=true

      if(this.userInfo.nombre === null || this.userInfo.nombre ==''){
        this.toast('b-toaster-bottom-center', true, '¡Captura tu nombre!', 'danger' );
        this.$refs.nombre.focus()
        return false;
      }

      else if( this.userInfo.paterno === null || this.userInfo.paterno === ''){
        this.toast('b-toaster-bottom-center', true, '¡Captura tus apellidos!', 'danger' );
        this.$refs.apellidos.focus()
        return false;
      }

      else if(this.userInfo.escuela_procedencia === null || this.userInfo.escuela_procedencia === ''){
        this.toast('b-toaster-bottom-center', true, '¡Captura tu escuela de procedencia!', 'danger' );
        this.$refs.escuela.focus()
        return false;
      }
      else if(this.userInfo.dominio === null || this.userInfo.dominio === ''){
        this.toast('b-toaster-bottom-center', true, '¡Captura el dominio!', 'danger' );
        this.$refs.dominio.focus()
        return false;
      }
      else{
        let formData = new FormData();
        formData.append("nombre", this.userInfo.nombre);
        formData.append("paterno", this.userInfo.paterno);
        //formData.append("materno", this.userInfo.materno);
        formData.append("escuela_procedencia", this.userInfo.escuela_procedencia);
        formData.append("dominio", this.userInfo.dominio);
        formData.append("uuid_boleto", this.uuid_boleto);
        this.modalEsperar=true
        const response = await EventoService.updateInvitado(formData);
        let {   success
        } = response.data;
        if(success){
          this.modalEsperar=false
          this.disabledUpdate=true
          this.modalBoletoReservado = true
/*          this.disabledUpdate=true
          this.hiddenBtn=true*/
          this.toast('b-toaster-bottom-center', true, 'Datos actualizados exitosamente.', "success" );
          this.$router.push({name: 'datosActualizados'})
        }
        else{
          this.modalEsperar=false
          this.disabledUpdate=false
          this.modalBoletoReservado = false
          this.toast('b-toaster-bottom-center', true, 'Ha ocurrido un error en el envío de correo, informar a su administrador.', "danger" );
        }
      }
    },

    async guardarValidacion()
    {
      let formData = new FormData();
      formData.append("id", this.infoPago.id);
      this.modalValidarIngreso=false
      this.modalEsperar=true
      const response = await PagoService.guardarRegistroDeAsistencia(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.modalEsperar=false
        this.getUsersRegistrados()

      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    hidemodalValidarPago(){
      this.modalValidarIngreso=false
    },

    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getInfoUsuario(){
      this.resultSet=[]
      this.modalEsperar=true
      const response = await PagoService.getInfoUsuario();
      this.userInfo = response.data.userInfo;
      if(this.userInfo.estatusAsistencia==0){
        this.disabledUpdate=false
      }
      else
        this.disabledUpdate=true

      this.modalEsperar=false
    },
    showModalValidarPago(item){
      this.modalValidarIngreso=true
      //this.url_comprobante_pago=item.url_comprobante_pago
      this.infoPago = item
    },
    hideModalCrearEvento(){
      this.modalCrearEvento=false
    },
    async guardarEvento()
    {
      if( this.nombre_evento === ''){
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger' );
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      this.wait()
      this.modalCrearEvento=false
      const response = await EventoService.saveEvento(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getEventos();
      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    showModalCrearEvento(){
      this.modalCrearEvento=true
    },
    async getData(){
      this.resultSet = [];
      const url                       =   `/api/all-events`;
      const response                  =   await this.$api.get(url);
      let {message, data, resultSet}  =   response.data;
      this.resultSet                  =   (data == 1) ? resultSet : [];
      this.totalRows                  =   this.resultSet.length;
      this.textoSnackbar              =   message;
      this.showAlert();
    },//getData
    async getMyTickets(){
      this.resultSet2 = [];
      const url                       = `/api/all-myTickets`;
      const response                  = await this.$api.get(url);
      let {message, data, resultSet}  = response.data;
      this.resultSet2                = (data == 1) ? resultSet : [];
      this.totalRows2                = this.resultSet2.length;
      this.textoSnackbar             =   message;
      this.showAlert();
    },//getMyTickets

    async getMyClosedTickets(){
      this.resultSet3 = [];
      const url                       =   `/api/all-myClosedTickets`;
      const response                  =   await this.$api.get(url);
      let {message, data, resultSet}  =   response.data;
      this.textoSnackbar              =   message;
      this.resultSet3                 = (data == 1) ? resultSet : [];
      this.totalRows3                 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted () {
    //this.wait()
    this.disabledUpdate=false
    //this.getInfoUsuario();
    console.log("uuid_boleto")
    console.log(this.uuid_boleto)

  },
  computed:{

  }

}
</script>
<style>
.reload {
  fill: #B2007B !important;
}
.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}
.page-link {
  border-color: #B2007B !important;
  color:#B2007B !important;
}
.alert-fixed {
  position:fixed;
  bottom: 2%;
  left: 30%;
  z-index:9999;
  border-radius:5px;
}
</style>