import api from '@/services/api';

export default{
    updateEstatusEncuesta(formData){
        let url          = `/api/eventos/update-estatus-encuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    updateSesionUserInscrito(formData){
        let url          = `/api/sesion/update-sesion-inscrito`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getSesionesNuevasIdBloque(numero_bloque,dia,idInscripcion){
        let url        = `/api/sesion/get-sesiones-by-bloque/${numero_bloque}/${dia}/${idInscripcion}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getUserSesionesInscrito(userId){
        let url        = `/api/sesion/get-user-sesiones-inscrito/${userId}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getUsuariosByEvento(uuidEvento){
        let url        = `/api/sesion/get-usuarios-activos-by-evento/${uuidEvento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getTotalRespuestasUserEncuesta(evento_id){
        let url        = `/api/eventos/get-total-respuestas-encuesta/${evento_id}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getPonenciasEventoActivo(){
        let url        = `/api/eventos/get-ponencias-evento-activo`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPonenciasEventoActivoByUuidEvento(uuid_evento){
        let url        = `/api/eventos/get-ponencias-evento-activo-by-uuid_evento/${uuid_evento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPonenciasEventoUuidEventoVigente(uuid_evento_vigente){
        let url        = `/api/eventos/get-ponencias-evento-uuid-evento/${uuid_evento_vigente}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getPonenciasAllByUuidEvento(uuidEvento){
        let url        = `/api/eventos/get-ponencias-by-uuidevento/${uuidEvento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPonenciasByUuidEvento(){
        let url        = `/api/eventos/get-ponencias-evento-uuid`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getEventosActivos()
    {
        let url          = `/api/eventos/get-eventos-activos`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getEventosActivosByUser()
    {
        let url          = `/api/sesion/get-eventos-activos-by-user`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getGanador()
    {
        let url          = `/api/eventos/getGanador`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    saveUserExtemporaneo(formData){
        let url          = `/api/eventos/inscribirUserExtemporaneo`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getPonenciasByUserInscrito()
    {
        let url          = `/api/eventos/all-ponencias-byUserInscrito`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getPonenciasByAllPonente()
    {
        let url          = `/api/eventos/allPonenciasAllPonente`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getPonenciasByAllPonenteByUuidEvento(uuid_evento)
    {
        let url          = `/api/eventos/get-ponencias-by-uuidEvento/${uuid_evento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },


    getPonenciasByPonente()
    {
        let url          = `/api/eventos/all-ponencias-byPonente`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    inscribirsePonencia(formData){
        let url          = `/api/eventos/inscribirsePonencia`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    deleteSesion(formData){
        let url          = `/api/eventos/delete-sesion`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    deleteRespuestasUserEncuesta(formData){
        let url          = `/api/eventos/eliminar-respuestas-user-encuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getAllPonencias()
    {
        let url          = `/api/eventos/all-ponencias`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    updatePonencia(formData){
        let url          = `/api/eventos/updatePonencia`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    agregarPonencia(formData){
        let url          = `/api/eventos/storePonencia`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    agregarInvitado(formData){
        let url          = `/api/eventos/storeInvitado`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    saveComprobantePago(formData){
        let url          = `/api/eventos/storeComprobantePago`;
        let response     = api({
            url: url,
            method: 'POST',
/*            headers: {
                'content-type': 'multipart/form-data'
            },*/
            headers: {
                //'Access-Control-Allow-Headers': 'X-Requested-With, , X-Token-Auth, Authorization',
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    updateInvitado(formData){
        let url          = `/api/eventos/updateAsistente`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    saveInvitado(formData){
        let url          = `/api/eventos/storeInvitado`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getEvento(uuidEvento){
        let url        = `/api/eventos/getEvento/${uuidEvento}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getEventos(){
        let url          = `/api/eventos/all-eventos`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    generarBoletosMasivamente(formData){
        let url          = `/api/eventos/generar-boletos-masivos`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    saveEvento(formData){
        let url          = `/api/eventos/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    saveResponses(formData){
        let url          = `/api/preguntas/responsesUser/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

}