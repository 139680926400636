/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-alert v-model="modalEsperar" variant="primary" >
      Favor de esperar un momento, procesando información...
    </b-alert>


    <b-modal v-model="modalEsperarValidacion"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex;" class="mx-2 my-2">
                  <div id="texto" style="text-align: center">
                    <h5>
                      <span style="text-align: center;">
                      ¡Gracias por adjuntar tu comprobante de pago!
                      </span>
                    </h5>
                    <div id="subparrafo" style="text-align: center;margin-top: 10px">
                      Éste será validado en un lapso de 48 a 72 horas hábiles y posteriormente
                      recibirás un correo electrónico de confirmación.
                    </div>
                  </div>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal v-model="modalFichaPagoGenerada"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex;" class="mx-2 my-2">
                  <h5 style="text-align: justify">Por favor realiza tu pago o transferencia a</h5>
                  <div id="pago" style="text-align: left">
                    <br>
                    Nombre de:&nbsp;<span style="color:#007bff">NIVEL A EDUCACION SA DE CV</span><br>
                    Cuenta:&nbsp;<span style="color:#007bff">BBVA {{numero_cuenta_pago}}</span><br>
                    CLABE:&nbsp;<span style="color:#007bff">{{clabe_banco}}</span><br>
                    Monto:&nbsp;<span style="color:#007bff">{{monto_pago}} (MXN)</span><br>
                    Referencia:&nbsp;<span style="color:#007bff">(tu nombre y apellidos)</span>
                  </div><br>
                    Esto incluye dos días del evento con alimentos incluídos.

                </b-row>
                <b-row style="display:flex;" class="mx-2 my-2">
                  <b-form>
                    <b-form-file
                        v-model="comprobante_pago"
                        :state="Boolean(comprobante_pago)"
                        placeholder="Adjuntar comprobante de pago..."
                        drop-placeholder="Coloca aquí tu archivo..."
                        size="sm"
                    ></b-form-file>
                  </b-form>
                </b-row>
                <b-row>
                  <b-col lg="12" class="text-center">
                    <b-button
                        class="myButton"
                        @click="guardarComprobante()"
                    >
                      Guardar
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal v-model="modalCuentaBanco"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex;" class="mx-2 my-2">
                  <h5 style="text-align: justify">Por favor realiza tu pago o transferencia a:<br> la <span style="color:#007bff">cuenta {{numero_cuenta_pago}} del banco: {{nombre_banco_pago}}
                    por el monto de: {{monto_pago}} (pesos mexicanos)</span> por los dos días de eventos, con alimentos incluidos.</h5>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>


    <b-modal v-model="modalNoPagaEscuela"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex; justify-content: center" class="mx-2 my-2">
                  <h5>A continuación captura tus datos:</h5>
                </b-row>

              </div>
              <b-row>
                <b-col cols="12" class="align-content-center">
                <b-form>
                  <b-form-group
                      id="input-group-1"
                      label="Nombre:"
                      label-for="input-nombre"
                  >
                    <b-form-input
                        id="input_nombre"
                        v-model="nombre"
                        type="text"
                        placeholder="Ingresa tu nombre"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                      id="input-group-2"
                      label="Apellido paterno:"
                      label-for="input-paterno"
                  >
                    <b-form-input
                        id="input-2"
                        v-model="paterno"
                        type="text"
                        placeholder="Ingresa tu apellido"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                      id="input-group-3"
                      label="Apellido materno:"
                      label-for="input-materno"
                  >
                    <b-form-input
                        id="input-3"
                        v-model="materno"
                        type="text"
                        placeholder="Ingresa tu apellido"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                      id="input-group-4"
                      label="Escuela de procedencia:"
                      label-for="input-escuela_procedencia"
                  >
                    <b-form-input
                        id="input-4"
                        v-model="escuela_procedencia"
                        type="text"
                        placeholder="Escuela de procedencia"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                      id="input-group-5"
                      label="Dominio:"
                      label-for="input-dominio"
                  >
                    <b-form-input
                        id="input-3"
                        v-model="dominio"
                        type="text"
                        placeholder="Dominio (ejemplo: www.miescuela.net)"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" class="text-center">
                  <b-button
                      class="myButton"
                      @click="guardarRegistro()"
                  >
                    Guardar
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>






    <b-modal v-model="modalSiPagaEscuela"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex;" class="mx-2 my-2">
                  <h5 style="text-align: justify">Es necesario que el coordinador de tu colegio se comunique con Patty Zamora
                    <span style="color:#007bff"> <a href="mailto:pattyz@nive.la">(pattyz@nive.la)</a></span> para
                  recibir indicaciones sobre tu alta y con ello completar tu registro.</h5>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>


    <b-modal v-model="modalPagoEscuela"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex; justify-content: center" class="mx-2 my-2">
                  <h5>¿Tu pago lo realizará la escuela?</h5>
                </b-row>
              </div>
              <b-row>
                <b-col lg="6" class="text-center" >
                  <b-button
                      class="myButton"
                      @click="siPagaEscuela()"
                  >
                    Sí
                  </b-button>
                </b-col>
                <b-col lg="6" class="text-center">
                  <b-button
                      class="myButton"
                      @click="noPagaEscuela()"
                  >
                    No
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>


    <b-modal v-model="modalPreguntaPreregistroNo"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">

              <div class="card-body p-2">

                <b-row style="display:flex; justify-content: center" class="mx-2 my-2">
                  <h5>¡Gracias por visitarnos!</h5>
                </b-row>

              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal v-model="modalPreguntaPreregistro"  centered size="lg" no-close-on-backdrop no-close-on-esc hide-footer hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <b-row style="display:flex; justify-content: center" class="mx-2 my-2">
                  <h5>Hola, ¿Deseas registrarte al {{this.nombre_evento}}?</h5>
                </b-row>
              </div>
              <b-row>
                <b-col lg="6" class="text-center" >
                  <b-button
                      class="myButton"
                      @click="siPreRegistrar()"
                  >
                    Si
                  </b-button>
                </b-col>
                <b-col lg="6" class="text-center">
                  <b-button
                      class="myButton"
                      @click="noPreRegistrar()"
                  >
                    No
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
export default {
  mixins:[toast],
  data(){
    return{
      //alert confirm dismissble
      uuid:(this.$route.params.idEvento) ? (this.$route.params.idEvento) : 0,
      modalEsperarValidacion:false,
      modalFichaPagoGenerada:false,
      modalCuentaBanco:false,
      modalNoPagaEscuela:false,
      modalSiPagaEscuela:false,
      modalPagoEscuela:false,
      modalEsperar:true,
      modalCrearEvento:false,
      modalPreguntaPreregistro:false,
      modalPreguntaPreregistroNo:false,
      textoSnackbar:'',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        {label: 'idEvento',class: 'text-start',key: 'id',}
        ,{ label: 'Nombre Ponencias', key: 'nombre_evento', class: 'text-center' }
        ,{ label: 'URL', key: 'url', class: 'text-start' }
        ,{ label: 'Inscritos', key: 'asistentes_inscritos', class: 'text-center' }
        ,{ label: 'Pregistrados', key: 'asistentes_preregistrados', class: 'text-center' }
        ,{ label: 'Pendientes de inscribirse', key: 'asistentes_pendientes', class: 'text-center' }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      filterOn: [],
      resultSet:[],
      search: '',
      nombre_evento:null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter2: null,
      filterOn2: [],
      resultSet2:[],
      search2: '',

      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter3: null,
      filterOn3: [],
      resultSet3:[],
      search3: '',
      editarActividadUnidad: false,
      infoEvento:[],
      nombre:""
      ,paterno:""
      ,materno:""
      ,escuela_procedencia:""
      ,dominio:""
      ,nombre_banco_pago:""
      ,numero_cuenta_pago:""
      ,referencia_banco:""
      ,clabe_banco:""
      ,monto_pago:""
      ,comprobante_pago:[]
    }
  },
  methods: {
    async guardarComprobante(){
      let formData = new FormData();
      formData.append("idEvento", this.uuid);
      formData.append("imagen", this.comprobante_pago);
      const url = `/api/eventos/storeComprobantePago`;
      this.modalEsperar=true
      this.modalFichaPagoGenerada=false
      const response  = await this.$api.post(url, formData,
          {
            headers: {'content-type': 'multipart/form-data'}
          }
      );
      let {   success
      } = response.data;
      if(success){
        this.modalEsperar=false
        this.modalEsperarValidacion=true
      }
    },

    async guardarRegistro()
    {
      if(this.nombre.trim() === ""){
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar su nombre!', 'danger' );
        return false;
      }
      else if( this.paterno.trim() === ""){
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar su apellido!', 'danger' );
        return false;
      }
      else if(this.materno.trim() === ""){
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar su apellido!', 'danger' );
        return false;
      }
      else if(this.escuela_procedencia.trim() === ""){
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar su escuela de procedencia!', 'danger' );
        return false;
      }
      else if(this.dominio.trim() === ""){
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar su dominio!', 'danger' );
        return false;
      }
      else{
        let formData = new FormData();
        formData.append("nombre", this.nombre);
        formData.append("paterno", this.paterno);
        formData.append("materno", this.materno);
        formData.append("escuela_procedencia", this.escuela_procedencia);
        formData.append("dominio", this.dominio);
        this.modalNoPagaEscuela=false
        this.modalEsperar=true
        const response = await EventoService.saveInvitado(formData);
        let {   success
        } = response.data;
        if(success){
          this.modalEsperar=false
          this.modalFichaPagoGenerada=true
        }
      //this.toast('b-toaster-bottom-center', true, message, color );
      }
    },
    noPagaEscuela(){
      this.modalPagoEscuela=false
      this.modalNoPagaEscuela=true
    },
    siPagaEscuela(){
      this.modalPagoEscuela           =false
      this.modalSiPagaEscuela         =true
    },
    siPreRegistrar(){
      this.modalPreguntaPreregistro   =false
      this.modalPagoEscuela           =true
    },
    noPreRegistrar(){
      this.modalPreguntaPreregistro   =false
      this.modalPreguntaPreregistroNo =true
    },
    async getEvento(){
      const response = await EventoService.getEvento(this.uuid);
      this.modalEsperar=false
      this.infoEvento = response.data.evento;


      this.nombre_evento  = this.infoEvento.nombre_evento
      this.nombre_banco_pago  = this.infoEvento.nombre_banco_pago
      this.numero_cuenta_pago = this.infoEvento.numero_cuenta_pago
      this.referencia_banco   = this.infoEvento.referencia_banco
      this.clabe_banco        = this.infoEvento.clabe_banco
      this.monto_pago         = this.infoEvento.monto_pago
      //console.log(response.data.ficha_pago_generada)
      if(response.data.ficha_pago_generada === 1)
        this.modalFichaPagoGenerada=true
      else if(response.data.ficha_pago_generada === 2)
        this.modalEsperarValidacion=true
      else{
        this.modalPreguntaPreregistro=true
      }

    },
    async getEventos(){
      this.resultSet=[]
      const response = await EventoService.getEventos();
      this.resultSet = response.data.eventos;
      this.totalRows = response.data.cantidad;

    },
    hideModalCrearEvento(){
      this.modalCrearEvento=false
    },
    async guardarEvento()
    {
      if( this.nombre_evento === ''){
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger' );
        //this.toast('b-toaster-bottom-center', true, message, color );
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      this.wait()
      const response = await EventoService.saveEvento(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getEventos();
      }
      this.toast('b-toaster-bottom-center', true, message, color );
      this.reactivo = ' ';
    },
    showModalCrearEvento(){
      this.modalCrearEvento=true
    },
    async getData(){
      this.resultSet = [];
      const url                       =   `/api/all-events`;
      const response                  =   await this.$api.get(url);
      let {message, data, resultSet}  =   response.data;
      this.resultSet                  =   (data == 1) ? resultSet : [];
      this.totalRows                  =   this.resultSet.length;
      this.textoSnackbar              =   message;
      this.showAlert();
    },//getData

    async getMyTickets(){
      this.resultSet2 = [];
      const url                       = `/api/all-myTickets`;
      const response                  = await this.$api.get(url);
      let {message, data, resultSet}  = response.data;
      this.resultSet2                = (data == 1) ? resultSet : [];
      this.totalRows2                = this.resultSet2.length;
      this.textoSnackbar             =   message;
      this.showAlert();
    },//getMyTickets

    async getMyClosedTickets(){
      this.resultSet3 = [];
      const url                       =   `/api/all-myClosedTickets`;
      const response                  =   await this.$api.get(url);
      let {message, data, resultSet}  =   response.data;
      this.textoSnackbar              =   message;
      this.resultSet3                 = (data == 1) ? resultSet : [];
      this.totalRows3                 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets

    async verManual(){
      await this.$router.push(`/manual`);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted () {
    this.getEvento();
  },

}
</script>

<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color:#B2007B !important;
}

.alert-fixed {
  position:fixed;
  bottom: 2%;
  left: 30%;
  z-index:9999;
  border-radius:5px;
}

.myButton {
  box-shadow:inset 0px 1px 0px 0px #9acc85;
  background:linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
  background-color:#74ad5a;
  border:1px solid #3b6e22;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  padding:8px 50px;
  text-decoration:none;
}
.myButton:hover {
  background:linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
  background-color:#68a54b;
}
.myButton:active {
  position:relative;
  top:1px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Buscar';
}
</style>