import api from '@/services/api';

export default{
    saveResponsesAbierta(formData){
        let url          = `/api/encuestas/saveRespuestasUserAbierta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    saveResponses(formData){
        let url          = `/api/encuestas/saveRespuestasUser`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    deleteEncuesta(formData){
        let url          = `/api/encuestas/deleteEncuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    deleteRespuesta(formData){
        let url          = `/api/encuestas/deleteRespuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    guardarEncuesta(formData){
        let url          = `/api/encuestas/guardarEncuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    guardarRespuesta(formData){
        let url          = `/api/encuestas/guardarRespuesta`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getEncuestas(sesion_id)
    {
        let url          = `/api/encuestas/all/${sesion_id}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getEncuestasRespuestas(sesion_id)
    {
        let url          = `/api/encuestas/allpreguntasEncuestaUser/${sesion_id}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getRespuestas(encuesta_id)
    {
        let url          = `/api/encuestas/allRespuestas/${encuesta_id}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    guardarFechador(formData){
        let url          = `/api/componentes/guardarFechador`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

    actualizarPonente(formData){
        let url          = `/api/ponentes/updatePonente`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    deletePonente(formData){
        let url          = `/api/ponentes/deletePonente`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
}