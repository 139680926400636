<template>
   <b-container>
    
     <b-jumbotron>
    <template #header>EstudioAPP</template>

    <template #lead>
      Proximamente, en esta sección podrá ver todos las solicitudes de soporte que usted haya creado, así como el seguimiento que estas hayan tenido. 
    </template>

    <hr class="my-4">

    <p>
      Por el momento, no hay nada más aquí, le invitamos a salir y estar al pendiente de nuevas actualizaciones.
    </p>

    <b-button variant="primary" @click="logout">
        <svg class="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
           <path class="text-white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
      Salir</b-button>
   
  </b-jumbotron>

  </b-container>
</template>

<script>
export default {
  data() {
      return {
            loading: false,
          
            timeout:2000,
            snackbar:false,
            textoSnackbar:"",
            colorSnackbar:"",

        resultSet:[],
        search: '',
            headers: [
            {
              text: 'Mensaje',
              align: 'start',
              value: 'mensaje',
            },
            { text: 'Fecha', value: 'fecha' },
            { text: '',   value: 'id',  align:'center'},
          ]
      }
  },//data
  methods:{

		logout() {
			this.$api.post("/logout").then(() => {
				window.location =
					"https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
					process.env.VUE_APP_API_URL +
					"/redirect";
			});
			}//logout

    ,verSolicitud(id) {
        this.$router.push(`/userAyuda/${id}`);
    },//verSolicitud

    getData(){
            this.$api.get("/api/user-solicitudes")
            .then((response) => {
                    let { 
                        message
                        ,color
                        ,data
                        ,resultSet
                        } = response.data;
                        console.log(response.data);
                this.normalSnackbar(color,message);
                this.resultSet = (data == 1) ? resultSet : [];
            })
            .catch( (e) => {
              this.resultSet = [];
              // this.errorSnackbar(e);
              console.log(e);
              console.log("ocurrió un error :" + e.getDate())
            });
           
        },//getData
    
  }//methods

  ,mounted(){
    // this.getData();
  }
}//export default
</script>

<style>

</style>