/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-modal
        id="modalSeleccionarEvento"
        v-model="FormPonencias.modalSeleccionarEvento"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        centered
        size="lg"
        hide-footer
        persistent
        title="Seleccionar Evento"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                >
                  Selecciona el evento al cual deseas procesar ponencias.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="4">
                      <label>Evento:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-select
                          v-model="FormPonencias.uuid_evento"
                          :options="FormPonencias.eventos"
                          ref="uuid_evento"
                          size="sm"
                          text-field="nombre_evento"
                          value-field="uuid"
                          return-object
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <div class="col-sm-12 mt-5">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right ml-2"
                        @click="seleccionarEvento()"
                    >
                      Seleccionar
                    </b-button>

                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right ml-2"
                        @click="cancelarSeleccionarEvento()"
                    >
                      Cancelar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!--ventana confirmacion -->
    <b-alert v-model="modalConfirmacion" variant="success" show>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
             viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
        {{ mensajeConfirmacion }}
      </div>
    </b-alert>
    <!--cerrar ventana confirmacion -->
    <b-alert v-model="modalEsperar" show variant="primary">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history"
             viewBox="0 0 16 16">
          <path
              d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
          <path
              d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Favor de esperar un momento, procesando información...
      </div>
    </b-alert>

    <!-- modal agregar REspuesta -->
    <b-modal v-model="modalAgregarRespuesta" persistent title="Agregar opciones de respuesta" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  En esta sección se enlistan las opciones de respuesta creadas.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>
              <b-form>
                <b-form-textarea
                    ref="respuesta"
                    class="my-2"
                    v-model="respuestas.contenido"
                    type="text"
                    rows="2"
                    max-rows="3"
                    placeholder="Ingresa la opción de respuesta"
                >
                </b-form-textarea>
                <div class="col-sm-12 my-2">

                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="guardarRespuesta()"
                  >
                    Guardar
                  </b-button>
                </div>

                <b-table class="table data-table"
                         :items="dataRespuestas"
                         :fields="respuestas_fields"
                         :current-page="currentPage_respuestas"
                         :per-page="perPage_respuestas"
                         :filter="filter_respuestas"
                         :filter-included-fields="filterOn_respuestas"
                         stacked="md"
                         :striped="true"
                         hover
                         show-empty
                         outlined
                         :small="true"
                >

                  <template #cell(pregunta)="data">
                    <span v-html="data.item.contenido"></span>
                  </template>
                  <template #empty="scope">
                    <b-alert show variant="info">{{
                        scope.emptyText = 'Por el momento no hay registros que mostrar'
                      }}
                    </b-alert>
                  </template>
                  <template #emptyfiltered="scope">
                    <b-alert show variant="warning">
                      {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </b-alert>
                  </template>
                  <template #cell(actions)="row">
                    <div class="text-center">
                      <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                                @click="deleteRespuesta(row.item.id,row.item.encuesta_id)" class="mr-1"
                                title="Borrar pregunta">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash3" viewBox="0 0 16 16">
                          <path
                              d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                        </svg>
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>
                      <b-pagination
                          v-model="currentPage_respuestas"
                          :total-rows="totalRows_respuestas"
                          :per-page="perPage_respuestas"
                          align="fill"
                          size="sm"
                          class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalAgregarRespuesta=false">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE agregar REspuesta --->

    <!-- modal agregar Encuesta -->
    <b-modal v-model="modalAgregarEncuesta" persistent title="Agregar encuesta" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  En esta sección se enlistan las preguntas creadas.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>
              <b-form>
                <label class="ml-2">Pregunta:</label>
                <b-form-textarea
                    ref="pregunta"
                    class="my-2"
                    v-model="encuesta.pregunta"
                    type="text"
                    rows="2"
                    max-rows="3"
                    placeholder="Ingresa la pregunta"
                >
                </b-form-textarea>
                <div class="col-sm-12 my-2">
                  <b-row>
                    <b-col sm="8">
                      <label>¿Es pregunta de respuesta abierta?</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-select
                          ref="pregunta_respuesta_abierta"
                          v-model="encuesta.respuesta_abierta"
                          :options="opcionesRespuestaAbierta"
                          size="sm"
                      >

                      </b-form-select>
                    </b-col>
                  </b-row>
                </div>
                <div class="col-sm-12 my-2">

                  <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 my-1"
                      @click="guardarEncuesta()"
                  >
                    Guardar
                  </b-button>
                </div>


                <b-table class="table data-table"
                         :items="dataEncuestas"
                         :fields="reactivos_fields"
                         :current-page="currentPage_encuestas"
                         :per-page="perPage_encuestas"
                         :filter="filter_encuestas"
                         :filter-included-fields="filterOn_encuestas"
                         stacked="md"
                         :striped="true"
                         hover
                         show-empty
                         outlined
                         :small="true"
                >

                  <template #cell(pregunta)="data">
                    <span v-html="data.item.pregunta"></span>
                  </template>
                  <template #empty="scope">
                    <b-alert show variant="info">{{
                        scope.emptyText = 'Por el momento no hay registros que mostrar'
                      }}
                    </b-alert>
                  </template>
                  <template #emptyfiltered="scope">
                    <b-alert show variant="warning">
                      {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </b-alert>
                  </template>
                  <template #cell(actions)="row">
                    <div class="text-center">
                      <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                @click="openModalRespuestas(row.item)" class="mr-1" title="Opciones de Respuesta">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-card-list" viewBox="0 0 16 16">
                          <path
                              d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                          <path
                              d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                        </svg>
                      </b-button>
                      <b-button variant="danger" v-b-modal.modal-prevent-closing size="sm"
                                @click="deleteEncuesta(row.item.id,row.item.sesion_id)" class="mr-1"
                                title="Borrar pregunta">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-trash3" viewBox="0 0 16 16">
                          <path
                              d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                        </svg>
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>
                      <b-pagination
                          v-model="currentPage_encuestas"
                          :total-rows="totalRows_encuestas"
                          :per-page="perPage_encuestas"
                          align="fill"
                          size="sm"
                          class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalAgregarEncuesta=false">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE agregar Encuesta --->

    <!-- modal editar Sesion -->
    <b-modal v-model="modalEditarSesion"
             title="Editar ponencia"
             size="lg"
             no-close-on-backdrop
             no-close-on-esc
             hide-header-close
             centered
             hide-footer
             persistent
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  Capturar los datos a modificar, después de modificado pulsar el botón actualizar para
                  guardar los cambios.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>
              </div>
              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Titulo ponencia</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                          label="titulo"
                          ref="Titulo ponencia"
                          v-model="sesion.titulo_ponencia"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Número de Bloque</label>
                    </b-col>
                    <b-col sm="2">
                      <b-form-select
                          ref="numero_bloque"
                          v-model="sesion.numero_bloque"
                          :options="opcionesNumeroBloque"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                    <b-col sm="2">
                      <label>Tipo</label>
                    </b-col>
                    <b-col sm="5">
                      <b-form-select
                          ref="tipo"
                          v-model="sesion.tipo"
                          :options="opcionesTipo"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Nombre del Presentador</label>
                    </b-col>
                    <b-col sm="5">
                      <b-form-input
                          ref="Nombre presentador"
                          v-model="sesion.nombre_presentador"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Día ponencia</label>
                    </b-col>
                    <b-col sm="2">
                      <b-form-select
                          ref="dia ponencia"
                          v-model="sesion.dia_ponencia"
                          :options="dia_ponencia"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Email coach</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                          ref="email_coach"
                          v-model="sesion.email_coach"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>




                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Descripción ponencia</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-textarea
                          ref="descripcion ponencia"
                          v-model="sesion.descripcion_ponencia"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Salón</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                          ref="nombre_salon"
                          v-model="sesion.nombre_salon"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <label>Máximo asistentes</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                          ref="maximo_permitido_asistentes"
                          v-model="sesion.maximo_permitido_asistentes"
                          type="number"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Hora inicio</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input v-model="sesion.horario_inicio_ponencia" type="time"></b-form-input>
                    </b-col>

                    <b-col sm="3">
                      <label>Hora fin</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input v-model="sesion.horario_fin_ponencia" type="time"></b-form-input>
                    </b-col>
                  </b-row>


                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Nivel ponencia</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                          ref="nivel_ponencia"
                          v-model="sesion.nivel_ponencia"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Sección</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-textarea
                          ref="seccion"
                          v-model="sesion.seccion"
                          type="text"
                          rows="1"
                          max-rows="1"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="3">
                      <label>Requisitos</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-textarea
                          ref="requisitos"
                          v-model="sesion.requisitos"
                          type="text"
                          rows="1"
                          max-rows="1"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>




                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Inscripción:</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-select
                          ref="inscripcion"
                          v-model="sesion.inscripcionactiva"
                          :options="opcionesInscripcion"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>

                    <b-col sm="3">
                      <label>Encuesta activa</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-select
                          ref="encuestaActiva"
                          v-model="sesion.encuesta_activa"
                          :options="opcionesEncuesta"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <div class="col-sm-12 my-2">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right ml-2"
                        @click="updatePonencia()"
                    >
                      Actualizar
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cancelarEditarPonencia()">
                      Cancelar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- FIN MODAL editar Sesion --->

    <!-- modal borrar sesion -->
    <b-modal
        v-model="modalDeleteSesion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar la sesión: <b>"{{ nombre_sesion_actual }}"</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="deleteSesion()"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal agregar ponencia -->
    <b-modal
        v-model="modalAgregarPonencia"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        centered
        size="lg"
        hide-footer
        persistent
        title="Agregar ponencia"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  Capturar los datos que se solicitan, para agregar la sesión.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Titulo ponencia</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                          label="titulo"
                          ref="Titulo ponencia"
                          v-model="sesion.titulo_ponencia"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Imagen</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-file
                          size="sm"
                          v-model="sesion.imagen"
                          ref="imagen"
                          placeholder="Archivo adjunto..."
                          accept="image/*"
                          drop-placeholder="Arrastrar un archivo para adjuntar."
                      ></b-form-file>
                    </b-col>
                  </b-row>


                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Número de Bloque</label>
                    </b-col>
                    <b-col sm="2">
                      <b-form-select
                          ref="numero_bloque"
                          v-model="sesion.numero_bloque"
                          :options="opcionesNumeroBloque"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                    <b-col sm="2">
                      <label>Tipo</label>
                    </b-col>
                    <b-col sm="5">
                      <b-form-select
                          ref="tipo"
                          v-model="sesion.tipo"
                          :options="opcionesTipo"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>


                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Nombre del Presentador</label>
                    </b-col>
                    <b-col sm="5">
                      <b-form-input
                          ref="Nombre presentador"
                          v-model="sesion.nombre_presentador"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Día ponencia</label>
                    </b-col>
                    <b-col sm="2">
                      <b-form-select
                          ref="dia ponencia"
                          v-model="sesion.dia_ponencia"
                          :options="dia_ponencia"
                          size="sm"
                      >

                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Email coach</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                          ref="email_coach"
                          v-model="sesion.email_coach"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Descripción ponencia</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-textarea
                          ref="descripcion ponencia"
                          v-model="sesion.descripcion_ponencia"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Salón</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                          ref="nombre_salon"
                          v-model="sesion.nombre_salon"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="3">
                      <label>Máximo asistentes</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                          ref="maximo_permitido_asistentes"
                          v-model="sesion.maximo_permitido_asistentes"
                          type="number"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Hora inicio</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input v-model="sesion.horario_inicio_ponencia" type="time"></b-form-input>
                    </b-col>

                    <b-col sm="3">
                      <label>Hora fin</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input v-model="sesion.horario_fin_ponencia" type="time"></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Nivel ponencia</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-input
                          ref="nivel_ponencia"
                          v-model="sesion.nivel_ponencia"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <!--                    <b-col sm="3">
                                          <label>Número ponencia</label>
                                        </b-col>
                                        <b-col sm="3">
                                          <b-form-input
                                              ref="numero_ponencia"
                                              v-model="sesion.numero_ponencia"
                                              type="number"
                                          >
                                          </b-form-input>
                                        </b-col>-->
                    <!--                    <b-col sm="3">
                                          <label>Duración minutos</label>
                                        </b-col>
                                        <b-col sm="3">
                                          <b-form-input
                                              ref="duracion_minutos_ponencia"
                                              v-model="sesion.duracion_minutos_ponencia"
                                              type="number"
                                          >
                                          </b-form-input>
                                        </b-col>-->
                  </b-row>

                  <b-row>
                    <b-col sm="3">
                      <label>Sección</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-textarea
                          ref="seccion"
                          v-model="sesion.seccion"
                          type="text"
                          rows="1"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <label>Requisitos</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-textarea
                          ref="requisitos"
                          v-model="sesion.requisitos"
                          type="text"
                          rows="1"
                          max-rows="1"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Inscripción:</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-select
                          ref="inscripcion"
                          v-model="sesion.inscripcionactiva"
                          :options="opcionesInscripcion"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>

                    <b-col sm="3">
                      <label>Encuesta activa</label>
                    </b-col>
                    <b-col sm="3">
                      <b-form-select
                          ref="encuestaActiva"
                          v-model="sesion.encuesta_activa"
                          :options="opcionesEncuesta"
                          size="sm"
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>


                  <div class="col-sm-12">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right ml-2"
                        @click="guardarPonencia()"
                    >
                      Guardar
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cancelarAgregarPonencia()"
                    >
                      Cancelar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <!--      <template #modal-footer>
              <div class="w-100">
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="modalAgregarInvitado=!modalAgregarInvitado">
                  Cancelar
                </b-button>
              </div>
            </template>-->
    </b-modal>
    <!-- FIN MODAL DE agregar Sesion --->

    <!-- FIN MODAL agregar asistente --->


    <!-- banner-->
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">

            <b-img
                center
                rounded
                src="https://storage.googleapis.com/asiste/ponencias-recreoCDMX/ReCreoBanner.png"
                height="auto"
                width="400"
                fluid
            >
            </b-img>

          </div>
        </div>
      </b-col>
    </b-row>
    <!-- fin banner-->


    <!--buscado princial-->
    <b-row>
      <b-alert
          :show="dismissCountDown"
          dismissible
          variant="success"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
          class="alert-fixed text-center text-bold">
        <p><span>{{ textoSnackbar }}</span></p>
      </b-alert>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <h5 class="font-weight-bold">Evento:{{ FormPonencias.nombre_evento }} (Configurar Ponencias)</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center p-2 my-2">
              <b-button
                  variant="outline-secondary"
                  size="md"
                  @click="modalAgregarPonencia=!modalAgregarPonencia"
                  :disabled="FormPonencias.disabledBtnAgregarPonencia"
              >
                Agregar ponencia
              </b-button>

              <b-button variant="outline-secondary" class="ml-2" size="md"
                        @click="FormPonencias.modalSeleccionarEvento=!FormPonencias.modalSeleccionarEvento" v-if="this.rol==='SuperAdmin'">
                Seleccionar Evento
              </b-button>
            </div>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <svg @click="getPonenciasAllByUuidEvento()" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor"
                     class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                  <path
                      d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                  <path fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                </svg>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar"
                  ></b-form-input>
                </div>
              </div>
            </div>

            <b-table class="table data-table"
                     :items="resultSet"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText = 'Por el momento no hay registros que mostrar' }}
                </h5>
              </template>
              <template #cell(horario)="row">
                {{ row.item.horario_inicio_ponencia }} - {{ row.item.horario_fin_ponencia }}
              </template>

              <template #cell(acciones)="row">
                <div class="d-flex justify-content-around align-items-center">
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Editar"
                            @click="openModalUpdateSesion(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>

                  <b-button :pressed="false" variant="danger" size="sm" class="ml-1" title="Eliminar"
                            @click="openModalDeleteSesion(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-trash" viewBox="0 0 16 16">
                      <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </b-button>

                  <b-button :pressed="false" variant="primary" size="sm" class="ml-1" title="Encuesta"
                            @click="openModalEncuestas(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-blockquote-right" viewBox="0 0 16 16">
                      <path
                          d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
                    </svg>
                  </b-button>
                </div>
              </template>

            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
import PagoService from "@/services/PagoService";
import EncuestaService from "@/services/EncuestaService";
import moment from "moment"
import 'moment/locale/es';

export default {
  mixins: [toast],
  data() {
    return {
      rol:null,
      opcionesNumeroBloque: [
        {value: 0, text: '0'},
        {value: 1, text: '1'},
        {value: 2, text: '2'},
        {value: 3, text: '3'},
        {value: 4, text: '4'},
        {value: 5, text: '5'},
        {value: 6, text: '6'},
        {value: 7, text: '7'},
        {value: 8, text: '8'},
        {value: 9, text: '9'},
        {value: 10, text: '10'},
        {value: 11, text: '11'},
        {value: 12, text: '12'},
        {value: 13, text: '13'},
        {value: 14, text: '15'},
        {value: 14, text: '16'},
      ],
      opcionesTipo: [
        {value: 1, text: 'Ponencial Magistral'},
        {value: 2, text: 'Cintillo Bloque'},
        {value: 3, text: 'Cintillo Comida'},
        {value: 4, text: 'Ponencia'},
        {value: 5, text: 'Inicio de día'},
        {value: 6, text: 'Encuesta Global'},
      ],


      FormPonencias: {
        eventos: [],
        eventoSelected: null,
        uuid_evento: '',
        modalSeleccionarEvento: false,
        nombre_evento: '',
        disabledBtnAgregarPonencia: true
      },
      modalAgregarPonencia: false,
      mensajeConfirmacion: null,
      modalConfirmacion: false,
      modalAgregarRespuesta: false,
      modalAgregarEncuesta: false,
      modalEditarSesion: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,
      encuesta: {
        id: null,
        pregunta: null,
        sesion_id: null,
        respuesta_abierta: 0,
        modalValidarEncuesta: false
      },
      respuestas: {
        id: null
        , contenido: null
        , encuesta_id: null
      },

      dataEncuestas: [],
      currentPage_encuestas: 1,
      perPage_encuestas: 8,
      filter_encuestas: null,
      filterOn_encuestas: [],
      totalRows_encuestas: 1,

      dataRespuestas: [],
      currentPage_respuestas: 1,
      perPage_respuestas: 8,
      filter_respuestas: null,
      filterOn_respuestas: [],
      totalRows_respuestas: 1,


      sesion: {
        email_coach:null,
        requisitos:null,
        id: 0,
        titulo_ponencia: '',
        evento_id: 0,
        nombre_salon: '',
        horario_inicio_ponencia: '',
        horario_fin_ponencia: '',
        numero_ponencia: '',
        duracion_minutos_ponencia: '',
        nombre_presentador: '',
        nivel_ponencia: '',
        descripcion_ponencia: '',
        estatusActivo: '',
        maximo_permitido_asistentes: 0,
        seccion: '',
        dia_ponencia: '',
        imagen: null,
        encuesta_activa: 0,
        inscripcionactiva: 0,
        ponenciaimpartida: 0,
        tipo: 4,
        numero_bloque: 1

      },
      dia_ponencia: [
        {value: 1, text: '1'},
        {value: 2, text: '2'}
      ],

      opcionesInscripcion: [
        {value: 0, text: 'No'},
        {value: 1, text: 'Sí'}
      ],

      opcionesEncuesta: [
        {value: 0, text: 'No'},
        {value: 1, text: 'Sí'}
      ],

      opcionesPreguntaAbierta: [
        {value: 0, text: 'No'},
        {value: 1, text: 'Sí'}
      ],


      modalAgregarInvitado: false,
      modalVerComprobante: true,
      modalEsperar: true,
      previewObservacionesValidacion: '',
      modalValidarIngreso: false,
      textoSnackbar: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        {label: 'Id', key: 'id', align: 'left'}
        , {label: 'Día', key: 'dia_ponencia', align: 'left'}
        , {label: 'Bloque', key: 'numero_bloque', align: 'left'}
        // , {label: 'No. Ponencia', key: 'numero_ponencia', align: 'left'}
        , {label: 'Titulo ponencia', key: 'titulo_ponencia', align: 'left'}
        , {label: 'Presentador', key: 'nombre_presentador', align: 'left'}
        //, {label: 'Día', key: 'dia_ponencia', align: 'left'}
        , {label: 'Salon', key: 'nombre_salon', align: 'left'}
        , {label: 'Horario', key: 'horario', align: 'left'}
        , {label: 'Acciones', key: 'acciones', align: 'left'}

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 250,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter3: null,
      filterOn3: [],
      resultSet3: []
      , infoPago: {
        id: 0,
        url_comprobante_pago: null,
        comprobanteEstatusAprobado: 0,
        observacionesValidacion: null,
        cliente: {
          name: null,
          escuela_procedencia: null,
          email: null
        }
      }
      , reactivos_fields: [
        {label: 'Pregunta', key: 'pregunta', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      respuestas_fields: [
        {label: 'Respuesta', key: 'contenido', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      opcionesRespuestaAbierta: [
        {value: 0, text: 'No'},
        {value: 1, text: 'Sí'}
      ],
    }
  },
  methods: {
    cancelarAgregarPonencia(){
      this.modalAgregarPonencia= false
      this.resetSesion()
    },
    cancelarEditarPonencia(){
      this.modalEditarSesion= false
      this.resetSesion()
    },
    checkInscripcion() {
      console.log("selected inscripcion")
      console.log(this.sesion.inscripcionactiva)
    },
    resetSesion(){
        this.sesion.id = 0
        this.sesion.titulo_ponencia= ''
        this.sesion.evento_id= 0
        this.sesion.nombre_salon= ''
        this.sesion.horario_inicio_ponencia= ''
        this.sesion.horario_fin_ponencia= ''
        this.sesion.numero_ponencia= ''
        this.sesion.duracion_minutos_ponencia= ''
        this.sesion.nombre_presentador= ''
        this.sesion.nivel_ponencia= ''
        this.sesion.descripcion_ponencia= ''
        this.sesion.estatusActivo= ''
        this.sesion.maximo_permitido_asistentes= 0
        this.sesion.seccion= ''
        this.sesion.dia_ponencia= ''
        this.sesion.imagen= null
        this.sesion.encuesta_activa= 0
        this.sesion.inscripcionactiva= 0
        this.sesion.ponenciaimpartida= 0
        this.sesion.tipo= 4
        this.sesion.numero_bloque= 1

    },
    cancelarSeleccionarEvento() {
      this.FormPonencias.disabledBtnAgregarPonencia = true
      this.FormPonencias.modalSeleccionarEvento = false
    },
    async seleccionarEvento() {
      //this.FormPonencias.uuid_evento = eventoSelected
      if (this.FormPonencias.uuid_evento === '') {
        this.$refs.uuid_evento.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Selecciona un evento de la lista!', 'danger');
        return false;

      } else {
        this.FormPonencias.modalSeleccionarEvento = false
        this.modalEsperar = true
        const response = await EventoService.getPonenciasAllByUuidEvento(this.FormPonencias.uuid_evento)
        this.FormPonencias.disabledBtnAgregarPonencia = false
        this.modalEsperar = false
        this.resultSet = response.data.agenda
        this.totalRows = this.resultSet.cantidad
        this.FormPonencias.nombre_evento = response.data.nombre_evento
        console.log("ponencias despues de seleccionar evento")
        console.log(this.resultSet)
      }
    },

    async seleccionarEventoRolBasico() {
        const response = await EventoService.getPonenciasAllByUuidEvento(this.FormPonencias.uuid_evento)
        this.FormPonencias.disabledBtnAgregarPonencia = false
        this.modalEsperar = false
        this.resultSet = response.data.agenda
        this.totalRows = this.resultSet.cantidad
        this.FormPonencias.nombre_evento = response.data.nombre_evento
    },






    async getPonenciasAllByUuidEvento() {
      this.modalEsperar = true
      const response = await EventoService.getPonenciasAllByUuidEvento(this.FormPonencias.uuid_evento)
      this.modalEsperar = false
      this.resultSet = response.data.agenda
      this.totalRows = this.resultSet.cantidad
      this.FormPonencias.nombre_evento = response.data.nombre_evento

    },


    async deleteRespuesta(id, encuesta_id) {
      this.showWait()
      let formData = new FormData();
      formData.append("id", id);
      /*
            this.modalDeleteSesion = false
            this.modalEsperar = true
      */
      const response = await EncuestaService.deleteRespuesta(formData);
      let {
        success,
        message,
      } = response.data;
      if (success) {
        this.hiddeWait()
        //this.showConfirmacion(message)
        this.toast('b-toaster-bottom-center', true, message, 'success');

        await this.getRespuestas(encuesta_id)
      } else {
        this.hiddeWait()
        //this.showConfirmacion(message)
        this.toast('b-toaster-bottom-center', true, message, 'success');
      }
    },
    async deleteEncuesta(id, sesion_id) {
      this.showWait()
      let formData = new FormData();
      formData.append("id", id);
      /*
            this.modalDeleteSesion = false
            this.modalEsperar = true
      */
      const response = await EncuestaService.deleteEncuesta(formData);
      let {
        success,
        message,
      } = response.data;
      if (success) {
        this.hiddeWait()
        //this.showConfirmacion(message)
        this.toast('b-toaster-bottom-center', true, message, 'success');

        await this.getEncuestas(sesion_id)
      } else {
        this.hiddeWait()
        //this.showConfirmacion(message)
        this.toast('b-toaster-bottom-center', true, message, 'success');
      }
    },
    async getEncuestas(sesion_id) {
      this.dataEncuestas = []
      this.showWait()
      const response = await EncuestaService.getEncuestas(sesion_id);
      this.hiddeWait()
      this.dataEncuestas = response.data.encuestas;
      this.totalRows_encuestas = response.data.cantidad;
    },

    async getRespuestas(encuesta_id) {
      this.dataRespuestas = []
      this.showWait()
      //console.log("encuesta_id",encuesta_id)
      const response = await EncuestaService.getRespuestas(encuesta_id);
      this.hiddeWait()
      this.dataRespuestas = response.data.encuestas;
      this.totalRows_respuestas = response.data.cantidad;
    },

    openModalEncuestas(itemSelected) {
      this.resetFormEncuestas()
      this.encuesta.sesion_id = itemSelected.id
      this.modalAgregarEncuesta = true
      this.getEncuestas(this.encuesta.sesion_id)
    },
    openModalRespuestas(itemSelected) {
      this.resetFormRespuestas()
      this.respuestas.encuesta_id = itemSelected.id
      this.modalAgregarRespuesta = true
      this.getRespuestas(this.respuestas.encuesta_id)
    },
    resetFormEncuestas() {
      this.encuesta.pregunta = null
      this.encuesta.respuesta_abierta = 0
    },
    resetFormRespuestas() {
      this.respuestas.contenido = null
    },
    showConfirmacion(message) {
      this.mensajeConfirmacion = message
      setTimeout(() => {
        this.modalConfirmacion = false
      }, 3000);
      this.modalConfirmacion = true

    },
    hiddeConfirmacion() {
      this.mensajeConfirmacion = null
      this.modalConfirmacion = false
    },
    showWait() {
      this.modalEsperar = true
      //this.disabledBtn()
    },
    hiddeWait() {
      this.modalEsperar = false
      //this.enabledBtn()
    },
    async guardarEncuesta() {
      if (this.encuesta.pregunta === '' || this.encuesta.pregunta === null) {
        this.$refs.pregunta.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta de la encuesta!', 'danger');
        return false;
      } else {
        this.showWait()

        let formData = new FormData();
        formData.append("pregunta", this.encuesta.pregunta);
        formData.append("respuesta_abierta", this.encuesta.respuesta_abierta);
        formData.append("sesion_id", this.encuesta.sesion_id);
        const response = await EncuestaService.guardarEncuesta(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          //this.showConfirmacion(message)
          this.toast('b-toaster-bottom-center', true, message, 'success');
          await this.getEncuestas(this.encuesta.sesion_id);
          this.resetFormEncuestas()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
      }
    },
    async guardarRespuesta() {
      if (this.respuestas.contenido === '' || this.respuestas.contenido === null) {
        this.$refs.respuesta.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la opción de respuesta!', 'danger');
        return false;
      } else {
        this.showWait()

        let formData = new FormData();
        formData.append("contenido", this.respuestas.contenido);
        formData.append("encuesta_id", this.respuestas.encuesta_id);
        const response = await EncuestaService.guardarRespuesta(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          //this.showConfirmacion(message)
          this.toast('b-toaster-bottom-center', true, message, 'success');
          await this.getRespuestas(this.respuestas.encuesta_id);
          this.resetFormRespuestas()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
        //this.toast('b-toaster-bottom-center', true, message, color);

      }
    },
    openModalUpdateSesion(sessionSelected) {
      this.modalEsperar = true
      this.resetFormSesion()
      this.sesion.id = sessionSelected.id
      this.sesion.titulo_ponencia = sessionSelected.titulo_ponencia
      this.sesion.nombre_salon = sessionSelected.nombre_salon
      this.sesion.horario_inicio_ponencia = sessionSelected.horario_inicio_ponencia
      this.sesion.horario_fin_ponencia = sessionSelected.horario_fin_ponencia
      this.sesion.numero_ponencia = sessionSelected.numero_ponencia
      this.sesion.duracion_minutos_ponencia = sessionSelected.duracion_minutos_ponencia
      this.sesion.nombre_presentador = sessionSelected.nombre_presentador
      this.sesion.nivel_ponencia = sessionSelected.nivel_ponencia
      this.sesion.descripcion_ponencia = sessionSelected.descripcion_ponencia
      this.sesion.maximo_permitido_asistentes = sessionSelected.maximo_permitido_asistentes
      this.sesion.seccion = sessionSelected.seccion
      this.sesion.dia_ponencia = sessionSelected.dia_ponencia
      this.sesion.encuesta_activa = sessionSelected.encuesta_activa
      this.sesion.inscripcionactiva = sessionSelected.inscripcionactiva
      this.sesion.tipo = sessionSelected.tipo
      this.sesion.email_coach = sessionSelected.email_coach
      this.sesion.requisitos = sessionSelected.requisitos
      this.sesion.numero_bloque = sessionSelected.numero_bloque
      this.modalEditarSesion = true
      this.modalEsperar = false

    },
    resetFormSesion() {
      this.sesion.id = 0
      this.sesion.titulo_ponencia = null
      this.sesion.evento_id = 0
      this.sesion.nombre_salon = null
      this.sesion.horario_inicio_ponencia = null
      this.sesion.horario_fin_ponencia = null
      this.sesion.numero_ponencia = null
      this.sesion.duracion_minutos_ponencia = null
      this.sesion.nombre_presentador = null
      this.sesion.nivel_ponencia = null
      this.sesion.descripcion_ponencia = null
      this.sesion.estatusActivo = null
      this.sesion.maximo_permitido_asistentes = null
      this.sesion.seccion = null
      this.sesion.dia_ponencia = null
      this.nombre_sesion_actual = null
      this.sesion_id_actual = null
      this.sesion.tipo = 4
      this.sesion.numero_bloque = 1
      this.sesion.email_coach = null
      this.sesion.requisitos = null
    },
    openModalDeleteSesion(sesion) {
      this.nombre_sesion_actual = sesion.titulo_ponencia
      this.sesion_id_actual = sesion.id
      this.modalDeleteSesion = true
    },
    async updatePonencia() {
      if (this.sesion.titulo_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_presentador == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
        return false;
      } else if (this.sesion.dia_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.descripcion_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.nombre_salon == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
        return false;
      } else if (this.sesion.maximo_permitido_asistentes == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
        return false;
      } else if (this.sesion.horario_inicio_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.horario_fin_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
        return false;
      }
      /*
      else if (this.sesion.numero_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el número de ponencia!', 'danger');
        return false;
      }
      else if (this.sesion.duracion_minutos_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la duración en minutos de la ponencia!', 'danger');
        return false;
      } */
      else if (this.sesion.nivel_ponencia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
        return false;
      } else if (this.sesion.seccion == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
        return false;
      }

      let formData = new FormData();
      formData.append("id", this.sesion.id);
      formData.append("titulo_ponencia", this.sesion.titulo_ponencia);
      formData.append("nombre_salon", this.sesion.nombre_salon);
      formData.append("horario_inicio_ponencia", this.sesion.horario_inicio_ponencia);
      formData.append("horario_fin_ponencia", this.sesion.horario_fin_ponencia);
      formData.append("numero_ponencia", this.sesion.numero_ponencia);
      formData.append("duracion_minutos_ponencia", this.sesion.duracion_minutos_ponencia);
      formData.append("nombre_presentador", this.sesion.nombre_presentador);
      formData.append("nivel_ponencia", this.sesion.nivel_ponencia);
      formData.append("descripcion_ponencia", this.sesion.descripcion_ponencia);
      formData.append("maximo_permitido_asistentes", this.sesion.maximo_permitido_asistentes);
      formData.append("seccion", this.sesion.seccion);
      formData.append("dia_ponencia", this.sesion.dia_ponencia);
      formData.append("encuesta_activa", this.sesion.encuesta_activa);
      formData.append("inscripcionactiva", this.sesion.inscripcionactiva);
      formData.append("tipo", this.sesion.tipo);
      formData.append("numero_bloque", this.sesion.numero_bloque);
      formData.append("email_coach", this.sesion.email_coach);
      formData.append("requisitos", this.sesion.requisitos);
      this.modalEsperar = true
      this.modalEditarSesion = false
      const response = await EventoService.updatePonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        //await this.getPonenciasAll();
        this.getPonenciasAllByUuidEvento()
        this.toast('b-toaster-bottom-center', true, message, color);
      } else {
        this.toast('b-toaster-bottom-center', true, message, color);
      }
      this.resetFormSesion()
    },
    async guardarPonencia() {

      if (this.sesion.tipo === 1) { //ponencia magistral
        this.sesion.numero_bloque = 0
      }
      else if (this.sesion.tipo === 2) { //cintillo bloque
        if (this.sesion.dia_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día que aplica el cintillo de bloque!', 'danger');
          return false;
        }
        else if (this.sesion.horario_inicio_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.horario_fin_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
          return false;
        }
        this.sesion.nombre_presentador="-"
        this.sesion.descripcion_ponencia="-"
        this.sesion.nombre_salon="-"
        this.sesion.maximo_permitido_asistentes=0
        this.sesion.nivel_ponencia="-"
        this.sesion.seccion="-"
        this.sesion.email_coach="-"
        this.sesion.requisitos=null
      }
      else if (this.sesion.tipo === 3) { //cintillo comida
        if (this.sesion.dia_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día que aplica el cintillo de bloque!', 'danger');
          return false;
        }
        else if (this.sesion.horario_inicio_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.horario_fin_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
          return false;
        }
        this.sesion.nombre_presentador="-"
        this.sesion.descripcion_ponencia="-"
        this.sesion.nombre_salon="-"
        this.sesion.maximo_permitido_asistentes=0
        this.sesion.nivel_ponencia="-"
        this.sesion.seccion="-"
        this.sesion.email_coach="-"
        this.sesion.requisitos=null
      }


      else if (this.sesion.tipo === 4) { //ponencia

        if (this.sesion.titulo_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nombre_presentador === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
          return false;
        } else if (this.sesion.dia_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.descripcion_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nombre_salon === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
          return false;
        } else if (this.sesion.maximo_permitido_asistentes === '' || this.sesion.maximo_permitido_asistentes === 0) {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
          return false;
        } else if (this.sesion.horario_inicio_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.horario_fin_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nivel_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.seccion === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
          return false;
        }
        else if (this.sesion.email_coach === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el email del coach!', 'danger');
          return false;
        }
      }
      else if (this.sesion.tipo === 5) { //cintillo inicio de dia
        if (this.sesion.dia_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día que aplica el cintillo de bloque!', 'danger');
          return false;
        }
        else if (this.sesion.horario_inicio_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.horario_fin_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
          return false;
        }
        this.sesion.nombre_presentador="-"
        this.sesion.descripcion_ponencia="-"
        this.sesion.nombre_salon="-"
        this.sesion.maximo_permitido_asistentes=0
        this.sesion.nivel_ponencia="-"
        this.sesion.seccion="-"
        this.sesion.email_coach="-"
        this.sesion.requisitos=null
      }

      else if (this.sesion.tipo === 6) { //encuesta global

        if (this.sesion.titulo_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nombre_presentador === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
          return false;
        } else if (this.sesion.dia_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.descripcion_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nombre_salon === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
          return false;
        } else if (this.sesion.maximo_permitido_asistentes === '' || this.sesion.maximo_permitido_asistentes === 0) {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
          return false;
        } else if (this.sesion.horario_inicio_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.horario_fin_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nivel_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.seccion === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
          return false;
        }
      }

/*      else if (this.sesion.tipo === 6) { //ponencia

        if (this.sesion.titulo_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el título de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nombre_presentador === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del presentador!', 'danger');
          return false;
        } else if (this.sesion.dia_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el día de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.descripcion_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la descripción de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nombre_salon === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nombre del salón!', 'danger');
          return false;
        } else if (this.sesion.maximo_permitido_asistentes === '' || this.sesion.maximo_permitido_asistentes === 0) {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el máximo de asistentes!', 'danger');
          return false;
        } else if (this.sesion.horario_inicio_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de inicio de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.horario_fin_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la hora de fin de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.nivel_ponencia === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar el nivel de la ponencia!', 'danger');
          return false;
        } else if (this.sesion.seccion === '') {
          this.toast('b-toaster-bottom-center', true, '¡Debe capturar la sección de la ponencia!', 'danger');
          return false;
        }
      }*/

      let formData = new FormData();
      formData.append("titulo_ponencia", this.sesion.titulo_ponencia);
      formData.append("uuid_evento", this.FormPonencias.uuid_evento);
      formData.append("nombre_presentador", this.sesion.nombre_presentador);
      formData.append("descripcion_ponencia", this.sesion.descripcion_ponencia);
      formData.append("nombre_salon", this.sesion.nombre_salon);
      formData.append("maximo_permitido_asistentes", this.sesion.maximo_permitido_asistentes);
      formData.append("horario_inicio_ponencia", this.sesion.horario_inicio_ponencia);
      formData.append("horario_fin_ponencia", this.sesion.horario_fin_ponencia);
      /*      formData.append("numero_ponencia", this.sesion.numero_ponencia);
            formData.append("duracion_minutos_ponencia", this.sesion.duracion_minutos_ponencia);*/
      formData.append("dia_ponencia", this.sesion.dia_ponencia);
      formData.append("nivel_ponencia", this.sesion.nivel_ponencia);
      formData.append("seccion", this.sesion.seccion);
      formData.append("archivo_imagen", this.sesion.imagen);
      formData.append("nombre_archivo_imagen", this.sesion.imagen);
      formData.append("encuesta_activa", this.sesion.encuesta_activa);
      formData.append("inscripcionactiva", this.sesion.inscripcionactiva);
      formData.append("tipo", this.sesion.tipo);
      formData.append("numero_bloque", this.sesion.numero_bloque);
      formData.append("email_coach", this.sesion.email_coach);
      formData.append("requisitos", this.sesion.requisitos);
      this.wait()
      this.modalAgregarInvitado = false
      const response = await EventoService.agregarPonencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalAgregarPonencia = false
        this.modalAgregarInvitado = false
        this.resetFormSesion()
        //await this.getPonenciasAll();
        this.getPonenciasAllByUuidEvento()
        this.toast('b-toaster-bottom-center', true, message, color);
      } else {
        //this.modalAgregarInvitado=false
        this.toast('b-toaster-bottom-center', true, message, color);
      }
      this.resetFormSesion()
    },
    async guardarValidacion() {
      let formData = new FormData();
      formData.append("id", this.infoPago.id);
      //console.log("id user", this.infoPago.id)
      this.modalValidarIngreso = false
      this.modalEsperar = true
      const response = await PagoService.guardarRegistroDeAsistencia(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        this.getPonenciasAll()

      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    hidemodalValidarPago() {
      this.modalValidarIngreso = false
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getPonenciasAll() {
      this.resultSet = []
      this.modalEsperar = true
      const response = await EventoService.getAllPonencias();
      this.modalEsperar = false
      //console.log("Eventos")
      //console.log(response)
      this.resultSet = response.data.ponencias;
      this.totalRows = response.data.cantidad;
    },

    async getEventos() {
      this.FormPonencias.eventos = []
      this.modalEsperar = true
      const response = await EventoService.getEventosActivos();

      this.rol = response.data.rol
      if(this.rol === "SuperAdmin"){
        this.FormPonencias.modalSeleccionarEvento = true
        this.FormPonencias.eventos = response.data.eventos;
        this.modalEsperar = false
      }
      else{
        this.FormPonencias.uuid_evento = response.data.uuid_evento
        await this.seleccionarEventoRolBasico()
      }
    },


    showModalValidarPago(item) {
      this.modalValidarIngreso = true
      this.infoPago = item
    },
    hideModalCrearEvento() {
      this.modalCrearEvento = false
    },
    async deleteSesion() {
      let formData = new FormData();
      formData.append("id", this.sesion_id_actual);
      this.modalDeleteSesion = false
      this.modalEsperar = true
      const response = await EventoService.deleteSesion(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.modalEsperar = false
        this.resetFormSesion()
        this.getPonenciasAll();
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    async guardarEvento() {
      if (this.nombre_evento === '') {
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger');
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      this.wait()
      this.modalCrearEvento = false
      const response = await EventoService.saveEvento(formData);
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.getEventos();
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    showModalCrearEvento() {
      this.modalCrearEvento = true
    },
    async getData() {
      this.resultSet = [];
      const url = `/api/all-events`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.resultSet = (data == 1) ? resultSet : [];
      this.totalRows = this.resultSet.length;
      this.textoSnackbar = message;
      this.showAlert();
    },//getData
    async getMyTickets() {
      this.resultSet2 = [];
      const url = `/api/all-myTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.resultSet2 = (data == 1) ? resultSet : [];
      this.totalRows2 = this.resultSet2.length;
      this.textoSnackbar = message;
      this.showAlert();
    },//getMyTickets
    async getMyClosedTickets() {
      this.resultSet3 = [];
      const url = `/api/all-myClosedTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.textoSnackbar = message;
      this.resultSet3 = (data == 1) ? resultSet : [];
      this.totalRows3 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    //this.getPonenciasAll();
    this.modalEsperar = true

    this.getEventos()
   // this.modalEsperar = false

  },
  computed: {}

}
</script>
<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}
</style>