/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/valid-v-for */
/* eslint-disable vue/no-unused-vars */
<template>
   <b-container >

  <b-alert
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
            class="alert-fixed text-center text-bold"> 
           <p> <span>{{textoSnackbar}}</span></p>
        </b-alert>
     <h5>Seguimiento de mi solicitud</h5>
      <b-row align="center" justify="center">
         <b-col cols="12" sm="12" md="12" lg="10" xl="10">
           <b-card style="style=padding:50px">
            <b-card-text>

              <b-row>
                      <b-col cols="3" md="3" sm="12">
                      <b-button  v-if="estatus_ticket == 999"
                          color="deep-purple lighten-2"
                          text
                          @click="irAllTickets()"
                      >
                        Mis tickets
                      </b-button >
                      </b-col>
                      <b-col cols="6" md="6" sm="12"></b-col>
                      <b-col cols="3" md="3" sm="12">
                        <b-button v-if="estatus_ticket!=1 && this.contestar"
                            size="sm" 
                            variant="outline-secondary"
                            @click="enviarMensajeSeguimiento()"
                        >
                            Enviar un mensaje
                        </b-button >
                      </b-col>
                      <p v-if="!this.contestar">Para poder enviar otro mensaje, debe esperar a que el soporte técnico se ponga en contacto primeramente, disculpas</p>
              </b-row>

              <hr class="my-4">

              <b-row>
                  <b-col cols="12" md="12" sm="12">
                      <h5>
                        Historial de mensajes
                      </h5>
                      <b-card-text class="estilos_seguimiento">
                          
                          <!--main span - show conversation-->
                          <span v-for="(result, key ) in resultSet"
                                :key="key" >

                          <div v-if="result['RespuestaSeguimientoTicket']" class="p-2 respuesta_user mt-3 text-left">
                            <!--  <p class="font-weight-light text-left"> -->
                                <span show variant="light" v-if="result['mensajes']">

                                    <span v-for="(mensaje, index ) in result['mensajes']" :key="index">
                                        <span class="caption"> <i style="color:#95a5a6">{{result['fechasRes'][index]}} - </i></span><span v-html="mensaje"></span>
                                     
                                          <div v-if=" result['adjuntosRes'][index]!='#'">
                                              <a target="_blank"
                                                  :href= "result['adjuntosRes'][index]">
                                                    <hr class="my-4">
                                                       <b-img
                                                            fluid 
                                                            v-bind  ="imageMainProps"
                                                            :src    ="result['adjuntosRes'][index]"
                                                        ></b-img>
                                              </a>
                                          </div>
                                          <hr class="my-4">
                                    </span>
                                  </span>
                            <!--  </p> -->
                          </div>

                          
                          <div class="text-left p-2 respuesta_soporte mt-3">
                              <p class="text-right" show variant="light">
                                <span v-html="result['SeguimientoTicket']"></span> - <span class="caption">  <i style="color:#95a5a6"> {{result['created_at']}} </i></span>
                              </p>  
                            <hr class="my-4">
                            <div v-if=" result['adjunto_seguimiento']!='#'">
                                <a target="_blank"
                                    :href= "result['adjunto_seguimiento']">
                                          <b-img
                                              fluid
                                              v-bind  ="imageMainProps"
                                              :src        ="result['adjunto_seguimiento']"
                                          ></b-img>
                                </a>
                            </div>
                          </div>

                        </span> <!--main span - show conversation-->
                        <div class="text-left p-2 respuesta_user mt-3 fondo_msjOriginal">
                              <p class="text-left" show variant="light">
                                <span class="caption"> <i style="color:#95a5a6"> {{created_at}} - </i></span>      <span v-html="original_Ticket_text"></span> 
                              </p>
                               <hr class="my-4">
                                <div v-if="this.original_ticket_adjunto!='#'">
                                    <a target="_blank"
                                        :href=this.original_ticket_adjunto>
                                          <b-img
                                              fluid
                                              v-bind="imageMainProps"
                                              :src        ="original_ticket_adjunto"
                                          ></b-img>
                                    </a>
                                </div>
                              
                          </div>
                      </b-card-text>
                
                  </b-col>
                </b-row>

            </b-card-text>

          </b-card>
        </b-col>
      </b-row>

   <b-modal
      v-model="dialogMensajeSeguimiento"
      persistent
      title=" Mensaje al soporte técnico a manera de seguimiento"
      size="lg"
    >
   <b-container fluid>
      <b-row class="m-1 text-left">
        <b-col cols="12">  
          <vue-editor v-model="mensajeSeguimiento" 
                        :editorToolbar="customToolbar" 
                        :placeholder="placeholder"
                        >
            </vue-editor>
          </b-col>   
        </b-row>
        </b-container>

          <b-form-file class="my-3"
              size="sm"
              v-model="imagen"
              :state="Boolean(imagen)"
              placeholder="Archivo adjunto..."
              drop-placeholder="Arrastrar un archivo para adjuntar."
          ></b-form-file>
          <template #modal-footer>
            <div class="w-100">
                <b-button
                      variant="primary"
                      size="sm"
                      class="float-right"
                      @click="dialogMensajeSeguimiento = false"
                    >
                      Volver
                    </b-button>
                    <b-button
                      variant="primary" 
                      size="sm" 
                      class="float-right mx-2"
                      @click="enviarMensaje()"
                    >
                    Enviar mensaje
                  </b-button>
            </div>
          </template>
    </b-modal>

   </b-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
        components: {
            VueEditor
        },
data() {
  return {
       imageMainProps: {  width: 250, height: 150, class: 'text-right' },

        //alert confirm dismissble
          textoSnackbar:'',
          dismissSecs: 3,
          dismissCountDown: 0,
          showDismissibleAlert: false,
        //alert confirm dismissble
  
      resultSet:[],
      original_Ticket_text:'',
       original_ticket_adjunto:'',
       created_at:'',
      mensaje_ticket:"",
      estatus_ticket: "",

      contestar:false,

      dialogMensajeSeguimiento:false,
      imagen : [],
      mensajeSeguimiento:"",
      mensajeSeguimientoRules:[
                                      v => !!v || 'Campo requerido',
                              ],
      //vue2editor
      content: "",
      placeholder:"Escriba aquí el mensaje al usuario",
      customToolbar: 
      [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" }
          ],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          ["link", "video"], // ["link", "image", "video"],
          ["clean"]
      ],                              
  }
},//data
mounted () {
      this.showConversation();
      
},//mounted
methods: {
    enviarMensajeSeguimiento(){
        this.dialogMensajeSeguimiento = true;
    },
    async showConversation(){
      console.warn('entra a showConversation');
                const id                        = (this.$route.params.id) ? (this.$route.params.id) : 0;
                const url                       = `/api/show-Conversation/${id}`;
                const response                  =  await this.$api.get(url);
                let { message, 
                      resultSet, 
                      contestar, 
                      original_Ticket_text, 
                      original_ticket_adjunto, 
                      created_at
                      } = response.data;
                  this.textoSnackbar            =   message;
                  this.resultSet                = resultSet;
                  this.contestar                = contestar
                  this.original_Ticket_text     = original_Ticket_text;
                  this.original_ticket_adjunto  = original_ticket_adjunto;
                  this.created_at               = created_at;
                  this.showAlert();

    },//showConversation
      countDownChanged(dismissCountDown) {
          this.dismissCountDown = dismissCountDown
      },
      showAlert() {
          this.dismissCountDown = this.dismissSecs
      },

    async enviarMensaje(){
          
        if(this.mensajeSeguimiento == '' || this.mensajeSeguimiento == ' ' || this.mensajeSeguimiento == null )
        {         
            this.textoSnackbar            =   "El mensaje a enviar NO puede estar vacío";
            this.showAlert();
        return null;
        }
        let formData = new FormData();
          formData.append("id", (this.$route.params.id) ? (this.$route.params.id) : 0  );
          formData.append("imagen", this.imagen);
          formData.append("mensaje", this.mensajeSeguimiento);

        const url = `/api/user-sendMessage-ticket`;
     
        const response  = await this.$api.post(url, formData, 
                      { 
                        headers: {'content-type': 'multipart/form-data'} 
                      }
                    );

        let {message}            = response.data;
        this.textoSnackbar              =   message;
        this.showAlert();
        this.mensajeSeguimiento         ='';
        this.dialogMensajeSeguimiento   = false;   
       // this.showConversation(); 
       console.log(response.data);
    }//enviarMensaje

      ,irAllTickets(){
          this.$router.push(`/all-userAyuda`);
      },//irAllTickets
      
},//methods
}
</script>

<style scoped>
    .estilos_seguimiento
    {   
        border-right: 1px solid #dedede;
        border-left: 1px solid #dedede; 
        padding:25px !important;
    }

    
    .respuesta_user
    {   
        border-right: 3px solid #00bcd4 !important;
        border-left: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      
    }

    .respuesta_soporte
    {   
        border-left: 3px solid rgb(149, 47, 129) !important;
        border-right: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .fondo_msjOriginal
    {
        background-color:  rgb(241, 242, 246) !important;
    }

    .alert-fixed 
    {
        position:fixed;
        bottom: 2%; 
        left: 30%;
        z-index:9999; 
        border-radius:5px;
    }

</style>