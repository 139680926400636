/* eslint-disable vue/no-unused-vars */
<template>
   <v-container>
       <v-subheader>Tickets</v-subheader>
        <v-row>
            <v-card elevation="5" style="padding:50px">
                <v-card-title class="headline">
                Seleccionar la configuración de EstudioAPP
                </v-card-title>
                
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <br>
                                <v-alert
                                    dense
                                    type="info"
                                    prominent
                                    >
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae sapiente aut doloremque delectus natus omnis, est praesentium, quis asperiores, minima iste! Alias eaque iste doloribus porro qui. Earum, aspernatur in.
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique debitis natus quis adipisci sequi quo, illum veniam, quod ducimus sapiente voluptatum quidem dignissimos repellat delectus commodi hic laboriosam error suscipit.
                                    <br>
                                </v-alert>

                            </v-col>
                        </v-row>



<v-row>
    
    <v-col cols="3"></v-col>
<v-col cols="6">

                        <v-card>
                            <v-card-title class="headline">
                          Elegir región de la que quiere mostrar los textos
                            </v-card-title>
                            <v-card-text>
                                    <v-snackbar
                                    v-model="snackbar3"
                                    color="red"
                                    :timeout="timeout"
                                    >
                                {{textoSnackbar3}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar3 = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>

                                    <v-snackbar
                                    v-model="snackbar"
                                     color="success"
                                     :timeout="timeout"
                                    >
                                   {{textoSnackbar}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>
                                <v-radio-group v-model="pais">
                                    <img height="100px" width="100px" src="https://upload.wikimedia.org/wikipedia/commons/c/c0/Mexico_flag_icon.svg" alt="">
                                <v-radio
                                    color="red"
                                    value="1"
                                ></v-radio>
                                  <img height="100px" width="100px" src="https://upload.wikimedia.org/wikipedia/commons/d/d3/Chile_01.png" alt="">
                                <v-radio
                                    color="red"
                                    value="2"
                                ></v-radio>

                                <img height="100px" width="100px" src="https://cdn.countryflags.com/thumbs/colombia/flag-round-250.png" alt="">
                                <v-radio
                                    color="red"
                                    value="3"
                                ></v-radio>
                                </v-radio-group>

                            </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="green darken-1"
                                    text
                                   @click="guardarSeleccion()"
                                >
                                    Guardar selección
                                </v-btn>
                                </v-card-actions>
                            </v-card>

</v-col>
<v-col cols="3"></v-col>
    
</v-row>

                    </v-container>
                </v-card-text>


            </v-card>

        </v-row>
  </v-container>
</template>

<script>
export default {
data() {
    return {
        snackbar3:false,
        textoSnackbar3:"¡Se debe elegir una opción!",
         timeout: 2000,
        textoSnackbar:" Ajustes Guardados",
         snackbar: false,
        resultSet:[],
        pais:null,
    }
},
    mounted(){

    },
methods: {
    guardarSeleccion(){

if(!this.pais){ 
    this.snackbar3 = true;
    return false;}

        console.log(this.pais);
        const url = `/api/update-parametrizacion`;
          this.$api.post(url,  {
                    id: this.pais
                    })
          .then( () => {
              this.snackbar=true;
           
          })
          .catch( () => {
            console.log("entra a error");
              
          })

    }
},
}
</script>

<style scoped>

</style>