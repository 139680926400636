<template>
  <b-container fluid>
    <b-modal v-model="modalAbrirEncuesta" persistent size="lg" centered>
      <b-container fluid>
        <b-alert id="modalValidarEncuesta" v-model="modalValidarEncuesta" variant="light" show>
          <b-button variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>
            {{ mensajeModalValidarEncuesta }}
          </b-button>
        </b-alert>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">

              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <!--                  <h5 style="color:#f8105e">{{ tituloencuesta }}</h5>-->
                  <h5 style="color:#f8105e">Encuesta</h5>
                </div>
                <!--                <p style="text-align: justify">
                                  {{ introEncuesta }}
                                </p>-->
                <p style="text-align: justify">
                  Participa, para nosotros tu opinión es muy importante.
                </p>
              </div>

              <b-form>
                <div v-for="input in dataEncuestas" :key="input.id">
                  <div class="mx-3 mt-3 p-2 criterio">
                    <b-jumbotron :lead=input.pregunta></b-jumbotron>
                    <!--                    <div id="reactivoRespuestaAbierta" v-if="input.respuesta_abierta===0">-->
                    <div id="reactivoRespuestaAbierta" v-if="input.respuesta_abierta===0">
                      <b-form-radio-group>
                        <b-row>
                          <b-col sm="12" v-for="descripcion in input.respuestas" :key="descripcion.id">
                            <!--                            <b-card style="border:1px #dedede solid;  border-radius: 15px;">-->
                            <b-card-text>
                              <b-form-radio
                                  :id="'respuesta-' + descripcion.id"
                                  :value="descripcion.id"
                                  @change="saveResponsesMultiple(input.sesion_id,input.id, descripcion.id)"
                              >{{ descripcion.contenido }}
                              </b-form-radio>
                            </b-card-text>
                            <!--                            </b-card>-->
                          </b-col>
                        </b-row>
                      </b-form-radio-group>
                    </div>
                    <div id="divReeactivoRespuestaAbierta" v-if="input.respuesta_abierta===1">
                      <b-form-textarea
                          :id="'abierta-' + input.id"
                          class="my-2"
                          v-model="respuestaAbierta[input.id]"
                          type="text"
                          rows="2"
                          max-rows="3"
                          placeholder="Ingresa la opción de respuesta"
                          @change="saveResponsesAbierta(input.sesion_id,input.id,$event)"
                          v-if="input.respuesta_abierta===1"
                      >
                      </b-form-textarea>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right ml-2"
              :disabled="btnFinalizarEncuesta"
              @click="finalizarEncuesta()">

            Finalizar encuesta
          </b-button>

          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              :disabled="btnCancelar"
              @click="cancelarModalAbrirEncuesta()">
            Cancelar
          </b-button>

        </div>

      </template>
    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2" style="background-color: transparent" v-if="imagen_evento===null">
            <b-img
                center
                rounded
                src="https://storage.googleapis.com/asiste/ponencias-recreoCDMX/ReCreoBanner.png"
                height="auto"
                width="400"
                fluid
            >
            </b-img>

          </div>
          <div class="card-body p-2" style="background-color: transparent" v-if="imagen_evento!==null">
            <b-img
                center
                rounded
                :src="imagen_evento"
                height="auto"
                width="600"
                fluid
            >
            </b-img>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row id="dashBoardGeneral">
      <b-col cols="12">
        <!-- <b-alert
             show
             variant="primary"
         >
           Agenda
         </b-alert>-->

        <b-alert id="alertModalConfirmacionGeneral" v-model="modalConfirmacion" variant="light" show>
          <b-button variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>
            {{ mensajeConfirmacion }}
          </b-button>
        </b-alert>

        <div id="agenda" v-for="(ponencia, index) in Formagenda.ponencias" :key="index">
          <!-- <b-card v-if="question.preguntas.tiporeactivo_id===1">-->
          <b-card-group class="mb-3">
            <b-card no-body v-if="ponencia.tipo===1">  <!--PONENCIA MAGISTRAL-->
              <b-row no-gutters>
                <b-col md="4" class="align-content-center">
                  <b-img v-bind:src="ponencia.imagen" width="400" height="auto" fluid
                         alt="Fluid image"></b-img>
                </b-col>
                <b-col md="8">
                  <b-card-body v-bind:title="ponencia.titulo_ponencia">
                    <b-card-text>
                      <div id="presentador" class="d-inline-block mb-2">
                        <span style="color: green; font-weight: normal; font-size: small">Presenta</span>
                        {{ ponencia.nombre_presentador }}
                      </div>
                    </b-card-text>

                    <b-card-text>

                      <div id="dia_evento" class="d-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                             class="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                          <path
                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                        </svg>
                        <span style="color: green; font-weight: normal; font-size: small"> Día</span>
                        {{ ponencia.dia_ponencia }}
                      </div>

                      <div id="horario" class="d-inline-block ml-2">
                        <small class="text-muted">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                         class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg>
                  </span>
                          {{ ponencia.horario_inicio_ponencia }} - {{ ponencia.horario_fin_ponencia }}</small>
                      </div>

                      <div id="salon" class="d-inline-block ml-2">
                        <span style="color: green; font-weight: normal; font-size: small">Salón</span>
                        {{ ponencia.nombre_salon }}
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>

            <b-card no-body v-if="ponencia.tipo===6">  <!--encuesta global-->
              <b-row no-gutters>
                <b-col md="4" class="align-content-center">
                  <b-img v-bind:src="ponencia.imagen" width="400" height="auto" fluid
                         alt="Fluid image"></b-img>
                </b-col>
                <b-col md="8">
                  <b-card-body v-bind:title="ponencia.titulo_ponencia">
                    <!--                    <b-card-text>
                                          <div id="presentador" class="d-inline-block mb-2">
                                            <span style="color: green; font-weight: normal; font-size: small">Presenta</span>
                                            {{ ponencia.nombre_presentador }}
                                          </div>
                                        </b-card-text>-->
                    <!--                    <b-card-text>
                                          <div id="presentador" class="d-inline-block mb-2 text-justify">
                                            {{ ponencia.descripcion_ponencia }}
                                          </div>
                                          <div id="salon" class="d-inline-block">
                                            <span style="color: green; font-weight: normal; font-size: small">Sección</span>
                                            {{ ponencia.seccion }}
                                          </div>
                                        </b-card-text>-->
                    <b-card-text>

                      <div id="dia_evento" class="d-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                             class="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                          <path
                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                        </svg>
                        <span style="color: green; font-weight: normal; font-size: small"> Día</span>
                        {{ ponencia.dia_ponencia }}
                      </div>

                      <div id="horario" class="d-inline-block ml-2">
                        <small class="text-muted">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                         class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg>
                  </span>
                          {{ ponencia.horario_inicio_ponencia }} - {{ ponencia.horario_fin_ponencia }}</small>
                      </div>


                      <!--                      <div id="nivel_ponencia" class="d-inline-block ml-2">
                                              <span style="color: green; font-weight: normal; font-size: small">Nivel</span>
                                              {{ ponencia.nivel_ponencia }}
                                            </div>-->

                      <div id="salon" class="d-inline-block ml-2">
                        <span style="color: green; font-weight: normal; font-size: small">Salón</span>
                        {{ ponencia.nombre_salon }}
                      </div>






                      <div id="btn-encuesta" class="d-inline-block ml-2">
                        <span style="color: green; font-weight: normal; font-size: small"
                              v-if="ponencia.encuesta_activa && ponencia.respuestas<ponencia.total_preguntas_encuesta">
                          <b-button variant="outline-info" size="sm"
                                    @click="abrirEncuesta(ponencia.id)"
                                    v-if="(ponencia.encuesta_activa===1 && ponencia.respuestas<ponencia.total_preguntas_encuesta)"
                          >
                            Encuesta
                          </b-button>
                        </span>
                        <span style="color: green; font-weight: normal; font-size: small"
                        >
                           <b-badge
                               variant="primary"
                               v-if="ponencia.respuestas==ponencia.total_preguntas_encuesta && ponencia.total_preguntas_encuesta>0">
                              Encuesta respondida
                            </b-badge>
                        </span>

                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>


            <b-card no-body v-if="ponencia.tipo===2" style="background-color: transparent">
              <b-row>
                <b-col md="12">

                  <b-alert id="cintilloBloque" variant="primary" show> <!--verde -->
                    {{ ponencia.titulo_ponencia }}
                  </b-alert>
                </b-col>
              </b-row>
            </b-card>


            <b-card no-body v-if="ponencia.tipo===3" style="background-color: transparent">
              <b-row>
                <b-col md="12">

                  <b-alert id="cintilloComida" variant="success" show> <!--verde -->
                    {{ ponencia.titulo_ponencia }} ({{ ponencia.horario_inicio_ponencia }} - {{ ponencia.horario_fin_ponencia }})
                  </b-alert>
                </b-col>
              </b-row>
            </b-card>

            <b-card no-body v-if="ponencia.tipo===5" style="background-color: transparent">
              <b-row>
                <b-col md="12">

                  <b-alert id="cintilloInicioDeDia" variant="warning" show> <!--verde -->
                    {{ ponencia.titulo_ponencia }}
                  </b-alert>
                </b-col>
              </b-row>
            </b-card>





            <b-card no-body v-if="ponencia.tipo===4">
              <b-row no-gutters>
                <b-col md="4" class="align-content-center">
                  <!--                  <b-card-img src="https://picsum.photos/400/400/?image=20" alt="Image" class="rounded-0"></b-card-img>-->
                  <b-img v-bind:src="ponencia.imagen" width="400" height="auto" fluid
                         alt="Fluid image"></b-img>
                </b-col>
                <b-col md="8">
                  <b-card-body v-bind:title="ponencia.titulo_ponencia">
                    <b-card-text>
                      <div id="presentador" class="d-inline-block mb-2">
                        <span style="color: green; font-weight: normal; font-size: small">Presenta</span>
                        {{ ponencia.nombre_presentador }}
                      </div>
                    </b-card-text>
                    <b-card-text>
                      <div id="presentador" class="d-inline-block mb-2 text-justify">
                        {{ ponencia.descripcion_ponencia }}
                      </div>
                      <div id="salon" class="d-inline-block">
                        <span style="color: green; font-weight: normal; font-size: small;margin-left:5px">Sección</span>
                        {{ ponencia.seccion }}
                      </div>
                      <div id="requisitos" class="d-inline-block" v-if="ponencia.requisitos !== null ">
                        <span style="color: green; font-weight: normal; font-size: small;margin-left:5px" >Requisitos:</span>
                        {{ ponencia.requisitos }}
                      </div>
                    </b-card-text>
                    <b-card-text>

                      <div id="dia_evento" class="d-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                             class="bi bi-calendar2-check-fill" viewBox="0 0 16 16">
                          <path
                              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                        </svg>
                        <span style="color: green; font-weight: normal; font-size: small"> Día</span>
                        {{ ponencia.dia_ponencia }}
                      </div>

                      <div id="horario" class="d-inline-block ml-2">
                        <small class="text-muted">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                         class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg>
                  </span>
                          {{ ponencia.horario_inicio_ponencia }} - {{ ponencia.horario_fin_ponencia }}</small>
                      </div>


                      <div id="nivel_ponencia" class="d-inline-block ml-2">
                        <span style="color: green; font-weight: normal; font-size: small">Nivel</span>
                        {{ ponencia.nivel_ponencia }}
                      </div>

                      <div id="salon" class="d-inline-block ml-2">
                        <span style="color: green; font-weight: normal; font-size: small">Salón</span>
                        {{ ponencia.nombre_salon }}
                      </div>

                      <div id="btn-inscribirse" class="d-inline-block ml-2" v-if="ponencia.inscripcionactiva">

                        <span style="color: green; font-weight: normal; font-size: small">
                          <b-button
                              variant="outline-success"
                              :disabled="disabledBtnInscribirse"
                              size="sm"
                              @click="Inscribirse(ponencia.id,ponencia.numero_bloque)"
                              v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes && ponencia.inscripcionactiva===1 && ponencia.numero_bloque===bloque_user"
                          >
                            Inscribirse
                          </b-button>
                        </span>
                      </div>

                      <div id="btn-inscrito" class="d-inline-block ml-2" v-if="ponencia.inscrito">
                        <span style="color: green; font-weight: normal; font-size: small">
                            <b-badge
                                variant="success"
                                v-if="ponencia.inscrito===1">
                              Inscrito
                            </b-badge>
                        </span>
                      </div>

                      <div
                          id="btn-cupo-lleno"
                          class="d-inline-block ml-2"
                          v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes"
                      >
                        <span style="color: green; font-weight: normal; font-size: small">
                            <b-badge
                                variant="warning"
                                v-if="ponencia.inscrito===0 && ponencia.total_inscritos>=ponencia.maximo_permitido_asistentes">
                              Cupo lleno
                            </b-badge>
                        </span>
                      </div>

                      <div id="btn-encuesta" class="d-inline-block ml-2">
                        <span style="color: green; font-weight: normal; font-size: small"
                              v-if="ponencia.encuesta_activa && ponencia.respuestas<ponencia.total_preguntas_encuesta">
                          <b-button variant="outline-info" size="sm"
                                    @click="abrirEncuesta(ponencia.id)"
                                    v-if="(ponencia.inscrito===1  && ponencia.encuesta_activa===1 && ponencia.respuestas<ponencia.total_preguntas_encuesta)"
                          >
                            Encuesta
                          </b-button>
                        </span>
                        <span style="color: green; font-weight: normal; font-size: small"
                        >
                           <b-badge
                               variant="primary"
                               v-if="ponencia.inscrito===1 && ponencia.respuestas==ponencia.total_preguntas_encuesta && ponencia.total_preguntas_encuesta>0">
                              Encuesta respondida
                            </b-badge>
                        </span>

                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-card-group>
        </div>


      </b-col>
    </b-row>

  </b-container>
</template>
/* eslint-disable vue/no-unused-vars */
<script>
import toast from "@/mixins/ToastMixin.js";
/*import moment from "moment"
import 'moment/locale/es';*/
import EventoService from '@/services/EventoService';
import EncuestaService from "@/services/EncuestaService";

export default {

  mixins: [toast],
  data() {
    return {
      imagen_evento:null,
      uuid_evento_vigente:null,
      bloque_user:0,
      evento_id: 0,
      datos_validados: 0,
      estatusAsistencia:0,
      role:'',
      modalValidarEncuesta: false,
      totalRespuestasEncuesta: 0,
      totalPreguntasEncuesta: 0,
      btnFinalizarEncuesta: false,
      mensajeModalValidarEncuesta: '',
      Formagenda: {
        ponencias: [],
      },
      categoriasArray: [],
      opcionesArray: [], // Aquí almacenaremos los elementos de opciones
      categorias: [],    // Aquí almacenaremos las categorías y sus opciones
      respuestas: [],
      nombres_categorias: [],
      FormDetallesExamen: {
        data: [],
        totalRows: 0,
        respuestaTipo1: [],
        respuestaTipo2: [],
        respuestaTipo3: [],
        fields: [
          {label: 'Descripcion', key: 'descripcion', class: 'text-left'},
          {label: 'Concepto', key: 'concepto', class: 'text-left'},
        ],
        currentPage: 1,
        perPage: 20,
        filter: null,
        filterOn: [],
        dataOpcionesCategoria: []
      },
      FormExamen: {
        titulo: null,
        instrucciones: '',
        valorExamen: 0,
        tiempo_minutos_responder: 0,
        uuid_examen: 0,
        id: 0,
      },
      FormPreguntas: {
        data: [], //resultSetPreguntas: [],
        totalRows: 0,      //totalRowsPreguntas: 1,
        fields: [
          {label: 'Pregunta', key: 'descripcion', class: 'text-left', sortable: true},
          {label: 'Tipo', key: 'tiporeactivo_id', class: 'text-center', sortable: true},
          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
      },


      textobtnGuardarExamen: "Guardar Examen",
      disabledBtnGral: true,
      disabledTabPreguntas: true,
      disabledTabVistaPrevia: true,


      primer_guardado_pregunta: 0,
      contenidoPreguntaOriginal: null,

      //fin variables pregunta
      /*variables Generales*/
      disabledBtnCancelar: false,
      /*VARIABLES DE BARRA DE PROGRESO*/
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,

      /*VARIABLES VIDEO*/
      textobtnGuardarVideo: "Guardar video",
      modalConfirmacionVideo: false,
      disabledBtnGuardarVideo: true,
      modalYoutube: false,
      video_save: 0,
      mensajeConfirmacion: null,
      modalConfirmacion: false,

      disabledBarraMultimedia: true,


      /* activar desactivar botones, textbox,textarea */
      visibleBtnDelVideo: true,


      tituloVideoYoutube: null,
      userSelectedVideo: 0,
      idVideoSelected: null,
      youtubeItems: [],
      youtubeItems_count: 0,


      //VARIABLES PARA IMAGEN
      disabledBtnsEliminarImagen: false,
      modalEliminarImagen: false,
      textobtnGuardarImagen: "Guardar archivo",
      disabledBtnGuardarImagen: false,
      modalConfirmacionImagen: false,
      primer_guardado_nameFileImagen: 0,
      nombre_archivo_original_imagen: null,
      url_imagen: null,
      imagenNueva: null,
      imagen: null,
      editarImagen: false,
      enabledToolbarImagen: false,
      modalZoomImagen: false,
      modalImagen: false,
      objImagen: null,
      //FIN VARIABLES PARA IMAGEN

      //VARIABLES PARA AUDIO
      disabledBtnsEliminarAudio: false,
      modalAudio: false,
      modalEliminarAudio: false,
      textobtnGuardarAudio: "Guardar archivo",
      disabledBtnGuardarAudio: false,
      primer_guardado_nameFileAudio: 0,
      nombre_archivo_original_audio: null,
      enabledToolbarAudio: false,
      modalConfirmacionAudio: false,
      audio_play: null,
      objAudio: null,
      audioNuevo: null,
      audio: null,
      editarAudio: false,
      url_audio: null,
      //fin vars para audio


      //variables RESPUESTA
      modalConfirmacionEliminarImagenRespuesta: false,
      modalEliminarImagenRespuesta: false,
      objImagenRespuesta: null,
      modalZoomImagenRespuesta: false,
      primer_guardado_nameFileImagenRespuesta: 0,
      nombre_archivo_original_imagenRespuesta: null,
      editarImagenRespuesta: false,
      disabledBarraMultimediaRespuesta: true,
      textobtnGuardarImagenRespuesta: "Guardar archivo",
      disabledBtnGuardarImagenRespuesta: false,
      url_imagenRespuesta: null,
      imagenNuevaRespuesta: null,
      modalImagenRespuesta: false,
      modalConfirmacionImagenRespuesta: false,

      maximoRespuestasOkPermitidas: 1,
      totalRespuestasOk: 0,
      respuesta: null,
      resultSetRespuestas: [],
      disabledBtnGuardarRespuesta: true,
      disabledBtnNuevaRespuesta: true,
      textobtnGuardarRespuesta: "Guardar Respuesta",
      disabledRespuesta: true,
      uuid_respuesta: 0,
      modalEliminarRespuesta: false,
      modalConfirmacionRespuesta: false,
      disabledBtnsEliminarRespuesta: false,
      //fin variables RESPUESTA

      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

      customToolbar:
          [
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            ["bold", "italic", "underline", "strike"],
            [{color: []}],
            [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
            [
              {align: ""},
              {align: "center"},
              {align: "right"},
              {align: "justify"}
            ],
            [{indent: "-1"}, {indent: "+1"}],

            ["clean"]
          ],
      dataEncuestas: [],
      introEncuesta: null,
      modalAbrirEncuesta: false,
      encuesta: {
        ponencia_id: null
      },
      btnCancelar: false,
      tituloencuesta: null,
      disabledBtnInscribirse: false,
      respuestaAbierta: []
    }
  },
  created() {
    this.objAudio = new Audio();
  },

  methods: {
    async cancelarModalAbrirEncuesta() {
      //mandar a eliminar todas las respuestas q haya adelantado el usuario
      this.btnCancelar = true
      let formData = new FormData();
      formData.append("evento_id", this.evento_id);
      const response = await EventoService.deleteRespuestasUserEncuesta(formData);
      let {
        success,

      } = response.data;
      if (success) {
        this.getPonenciasEventoActivo()
        this.evento_id = 0
        this.modalAbrirEncuesta = false
        this.modalValidarEncuesta = false
        this.btnFinalizarEncuesta = false
        this.respuestaAbierta = []
        this.btnCancelar = false
      }


    },
    async saveResponsesAbierta(sesion_id, encuesta_id, event) {
      let formData = new FormData();
      let contenido = event;
      formData.append("sesion_id", sesion_id);
      formData.append("encuesta_id", encuesta_id);
      formData.append("respuestaAbierta", contenido);
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
      await EncuestaService.saveResponsesAbierta(formData);
    },
    async saveResponsesMultiple(sesion_id, encuesta_id, respuesta_id) {
      let formData = new FormData();
      formData.append("sesion_id", sesion_id);  //idponencia
      formData.append("encuesta_id", encuesta_id);  ///id pregunta
      formData.append("respuesta_id", respuesta_id);  //id respuesta

      await EncuestaService.saveResponses(formData);
    },
    async Inscribirse(idPonencia,ponencia_numero_bloque) {
      this.disabledBtnInscribirse = true
      let formData = new FormData();
      formData.append("idPonencia", idPonencia);
      formData.append("numero_bloque", ponencia_numero_bloque);
      const response = await EventoService.inscribirsePonencia(formData);
      this.disabledBtnInscribirse = false
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.getPonenciasEventoActivo()
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    async finalizarEncuesta() {
      this.btnFinalizarEncuesta = true
      this.Formagenda.ponencias = []
      this.mensajeModalValidarEncuesta = 'Favor de esperar, contabilizando respuestas...'
      this.modalValidarEncuesta = true
      const response = await EventoService.getTotalRespuestasUserEncuesta(this.evento_id);
      let {
        success,
        totalRespuestasEncuesta,
        totalPreguntasEncuesta
      } = response.data;
      if (success) {
        this.modalValidarEncuesta = false
        this.totalRespuestasEncuesta = totalRespuestasEncuesta
        this.totalPreguntasEncuesta = totalPreguntasEncuesta

        console.log("totalRespuestas")
        console.log(this.totalRespuestasEncuesta)
        console.log("totalPreguntas")
        console.log(this.totalPreguntasEncuesta)


        if (this.totalRespuestasEncuesta == this.totalPreguntasEncuesta) {
          this.modalAbrirEncuesta = false
          this.evento_id = 0
          this.modalAbrirEncuesta = false
          this.modalValidarEncuesta = false
          this.btnFinalizarEncuesta = false
          this.respuestaAbierta = []
          this.getPonenciasEventoActivo()

        } else {
          this.btnFinalizarEncuesta = false
          this.mensajeModalValidarEncuesta = 'Faltan preguntas de la encuesta por responder, favor de revisar...'
          this.modalValidarEncuesta = true
        }
      }

    },
    async abrirEncuesta(itemSelected) {
      this.evento_id = itemSelected
      this.dataEncuestas = []
      this.introEncuesta = null
      this.modalAbrirEncuesta = true
      this.encuesta.ponencia_id = itemSelected
      this.btnCancelar = true
      this.tituloencuesta = "Favor de esperar un momento, preparando Encuesta..."
      const response = await EncuestaService.getEncuestasRespuestas(itemSelected);
      this.tituloencuesta = null
      this.dataEncuestas = response.data.encuestas
      console.log("this.dataEncuestas")
      console.log(this.dataEncuestas)
      console.log("this.tituloencuesta")
      console.log(this.tituloencuesta)
      console.log("this.introEncuesta")
      console.log(this.introEncuesta)
      this.introEncuesta = response.data.intro
      this.tituloencuesta = response.data.titulo
      this.btnCancelar = false
    },
    async getPonenciasEventoActivo() {
      this.Formagenda.ponencias = []
      const response = await EventoService.getPonenciasByUuidEvento();
      let {
        success,
        agenda,
        bloque,
          imagen_evento
      } = response.data;
      if (success) {
        this.imagen_evento = imagen_evento
        this.Formagenda.ponencias = agenda
        this.bloque_user = bloque
        console.log("agenda")
        console.log(this.Formagenda.ponencias)
      }
    },

  },
  mounted() {
    this.uuid_evento_vigente = this.$store.getters.usuario.uuid_evento_vigente
    this.datos_validados = this.$store.getters.usuario.datos_validados
    this.estatusAsistencia = this.$store.getters.usuario.estatusAsistencia
    this.role = this.$store.getters.usuario.role
    if (this.datos_validados === 0 && this.role === 'Profesores' && this.estatusAsistencia ===0) {
      this.$router.push(`/sin-validar-datos`);
    }
    else if (this.datos_validados === 1 && this.role === 'Profesores' && this.estatusAsistencia ===0) {
      this.$router.push(`/sin-checkin`);
    }

    else {
      this.getPonenciasEventoActivo()
    }
  },

  computed: {}
}
</script>
<style lang="scss" scoped>
$studioAPPColor: #952f81;
.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: #dadada !important;
  color: white !important;
}

.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}


.player {
  background: #eef3f7;
  width: 310px;
  min-height: 30px;
  // box-shadow: 0px 55px 75px -10px rgba(76, 70, 124, 0.5);
  // box-shadow: 0px 55px 105px 10px rgba(76, 70, 124, 0.35);
  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  border-radius: 15px;
  padding: 30px;
  @media screen and (max-width: 576px), (max-height: 500px) {
    width: 95%;
    padding: 10px;
    margin-top: 75px;
    min-height: initial;
    padding-bottom: 30px;
    max-width: 400px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 4;
    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-wrap: wrap;
    }
  }

  &-cover {
    width: 300px;
    height: 300px;
    margin-left: -70px;
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    border-radius: 15px;
    // transform: perspective(512px) translate3d(0, 0, 0);
    // transition: all .4s cubic-bezier(.125, .625, .125, .875);
    z-index: 1;

    @media screen and (max-width: 576px), (max-height: 500px) {
      margin-top: -70px;
      margin-bottom: 25px;
      width: 290px;
      height: 230px;
      margin-left: auto;
      margin-right: auto;
    }

    &__item {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 1;
        position: absolute;
        top: 30px;
        transform: scale(0.9);
        filter: blur(10px);
        opacity: 0.9;
        border-radius: 15px;
      }

      &:after {
        content: "";
        background: inherit;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
        display: block;
        z-index: 2;
        position: absolute;
        border-radius: 15px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
      box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
      user-select: none;
      pointer-events: none;
    }
  }

  &-controls {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 576px), (max-height: 500px) {
      flex-direction: row;
      padding-left: 0;
      width: 100%;
      flex: unset;
    }

    &__item {
      display: inline-flex;
      font-size: 30px;
      padding: 5px;
      margin-bottom: 10px;
      color: #acb8cc;
      cursor: pointer;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 576px), (max-height: 500px) {
        font-size: 26px;
        padding: 5px;
        margin-right: 10px;
        color: #acb8cc;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        transform: scale(0.5);
        opacity: 0;
        box-shadow: 0px 5px 10px 0px rgba(76, 70, 124, 0.2);
        transition: all 0.3s ease-in-out;
        transition: all 0.4s cubic-bezier(0.35, 0.57, 0.13, 0.88);
      }

      @media screen and (min-width: 500px) {
        &:hover {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      @media screen and (max-width: 576px), (max-height: 500px) {
        &:active {
          color: #532ab9;

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }
      }

      .icon {
        position: relative;
        z-index: 2;
      }

      &.-xl {
        margin-bottom: 0;
        font-size: 95px;
        // filter: drop-shadow(0 2px 8px rgba(172, 184, 204, 0.3));
        // filter: drop-shadow(0 9px 6px rgba(172, 184, 204, 0.35));
        filter: drop-shadow(0 11px 6px rgba(172, 184, 204, 0.45));
        color: #fff;
        width: auto;
        height: auto;
        display: inline-flex;
        @media screen and (max-width: 576px), (max-height: 500px) {
          margin-left: auto;
          font-size: 75px;
          margin-right: 0;
        }

        &:before {
          display: none;
        }
      }

      &.-favorite {
        &.active {
          color: red;
        }
      }
    }
  }
}

[v-cloak] {
  display: none;
}

[v-cloak] > * {
  display: none;
}

.progress {
  width: 100%;
  margin-top: -15px;
  user-select: none;

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__duration {
    margin-bottom: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.5;
  }

  &__time {
    margin-top: 6px;
    color: #71829e;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.7;
  }
}

.progress__bar {
  height: 6px;
  width: 100%;
  cursor: pointer;
  background-color: #d0d8e6;
  display: inline-block;
  border-radius: 10px;
}

.progress__current {
  height: inherit;
  width: 0%;
  background-color: #a3b3ce;
  border-radius: 10px;
}

.album-info {
  color: #71829e;
  flex: 1;
  padding-right: 60px;
  user-select: none;

  @media screen and (max-width: 576px), (max-height: 500px) {
    padding-right: 30px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
    line-height: 1.3em;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      margin-bottom: 9px;
    }
  }

  &__track {
    font-weight: 400;
    font-size: 20px;
    opacity: 0.7;
    line-height: 1.3em;
    min-height: 52px;
    @media screen and (max-width: 576px), (max-height: 500px) {
      font-size: 18px;
      min-height: 50px;
    }
  }
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

//scale out

.scale-out-enter-active {
  transition: all .35s ease-in-out;
}

.scale-out-leave-active {
  transition: all .35s ease-in-out;
}

.scale-out-enter {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.scale-out-leave-to {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}


//scale in

.scale-in-enter-active {
  transition: all .35s ease-in-out;
}

.scale-in-leave-active {
  transition: all .35s ease-in-out;
}

.scale-in-enter {
  transform: scale(1.2);
  pointer-events: none;
  opacity: 0;
}

.scale-in-leave-to {
  transform: scale(.55);
  pointer-events: none;
  opacity: 0;
}

.btn-primary.active, .btn-primary:active {
  background-color: #FFA500; /* Color de fondo cuando es pulsado */
  border-color: #FFA500; /* Color de borde cuando es pulsado */
}

.vue-editor__wrapper {
  height: 100px;
}

#editor1,
#editor2 {
  height: 550px;

  .custom-header th {
    font-size: 10px; /* Ajusta el tamaño de fuente según tus necesidades */
  }
}

/* Estilos para el reactivo de categorizacion*/
.columns {
  display: flex;
}

.column {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.column-items {
  min-height: 50px;
  background-color: #f5f5f5;
  margin-top: 5px;
}

.item {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  margin: 5px;
  cursor: grab;
}

.unassigned {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

/* FIN Estilos para el reactivo de categorizacion*/
</style>