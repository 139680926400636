/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex align-items-center p-2">
              <h5 class="font-weight-bold">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="red"
                     class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"/>
                  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                </svg>
                Acceso no autorizado</h5>
            </div>
            </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div>
          <b-button v-b-modal.modal-1>Abrir chat de ayuda</b-button>

          <b-modal id="modal-1" title="EstudioApp" no-close-on-backdrop no-close-on-esc   hide-footer>
            <iframe :src="url" width="100%" height="500px"></iframe>
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://tawk.to/chat/60216cb8a9a34e36b975092c/1eu191c4l'

    }
  },
  computed:{

  },
  methods: {

  },
  mounted() {

  },

}
</script>

<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}

</style>