/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-alert v-model="modalConfirmacion" variant="light" show>
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        {{ mensajeConfirmacion }}
      </b-button>
    </b-alert>
    <!-- inicio modal CREAR EVENTO-->
    <b-modal
        id="modalBoletosMasivos"
        v-model="FormBoletos.modalBoletosMasivos"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        centered
        size="lg"
        hide-footer
        persistent
        title="Generación Masiva de Boletos "
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  Capturar los datos que se solicitan, para generar los boletos del evento:
                  <strong>{{ FormBoletos.nombre_evento }}</strong>
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="4">
                      <label>Seleccionar Escuela:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-select
                          v-model="FormBoletos.escuela_id"
                          :options="FormBoletos.escuelas"
                          ref="escuela_id"
                          size="sm"
                          text-field="nombre_escuela"
                          value-field="id"
                          return-object
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label>Motivo:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-select
                          v-model="FormBoletos.motivo_id"
                          ref="motivo_id"
                          :options="FormBoletos.motivos"
                          size="sm"
                          text-field="motivo"
                          value-field="id"
                          return-object
                      >
                      </b-form-select>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="4">
                      <label>Cantidad de boletos:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input
                          v-model="FormBoletos.cantidad_boletos"
                          ref="cantidad_boletos"
                          type="number"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="12">

                      <b-table class="table data-table"
                               :items="dataBoletosFirmados"
                               :fields="fieldsBoletosFirmados"
                               :current-page="currentPageBoletos"
                               :per-page="perPageBoletos"
                               :filter="filterBoletos"
                               :filter-included-fields="filterOnBoletos"
                               show-empty
                               small
                               striped hover
                      >
                        <template #empty="scope">
                          <h5 class="text-center">{{ scope.emptyText = 'Por el momento no hay registros que mostrar' }}</h5>
                        </template>

<!--                        <template #cell(boleto)="row">
                          <div v-if="row.item.boleto.length > 500">
                            {{ row.boleto.slice(0, 500) }}... &lt;!&ndash; Muestra los primeros 500 caracteres y añade puntos suspensivos &ndash;&gt;
                            <b-popover :target="'popover-' + row.index">
                              {{ row.value }} &lt;!&ndash; Muestra el contenido completo en el popover &ndash;&gt;
                            </b-popover>
                            <b-button v-b-popover="'popover-' + row.index" size="sm">Ver más</b-button>
                          </div>
                          <div v-else>
                            {{ row.value }}
                          </div>
                        </template>-->
                        <template #cell(boleto)="row">
                          <div v-html="row.item.boleto" style="font-size: small; overflow: auto;word-wrap: break-word;max-width: 600px;"></div>
                        </template>
                      </b-table>
                      <div class="d-flex justify-content-between row p-2">
                        <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                          <div>
                            <b-pagination
                                v-model="currentPageBoletos"
                                :total-rows="totalRowsBoletos"
                                :per-page="perPageBoletos"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                          </div>
                        </div>
                      </div>


                    </b-col>
                  </b-row>


                  <div class="col-sm-12 mt-5">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right ml-2"
                        @click="generarBoletos()"
                    >
                      Generar Boletos
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="cancelarBoletosMasivos()"
                    >
                      Cancelar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>


    <b-modal
        v-model="modalCrearEvento"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        hide-footer
        persistent
        title="Crear Evento"
        centered
        size="lg"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                    dismissible>
                  Capturar los siguientes datos, para crear el evento.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>
              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Nombre del evento:</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-input
                          label="Nombre del evento"
                          ref="nombre_evento"
                          v-model="nombre_evento"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Fecha inicio del evento:</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-datepicker
                          id="fecha_inicio_evento"
                          ref="fecha_inicio_evento"
                          size="sm"
                          v-model="FormBoletos.fecha_inicio_evento"
                      >
                      </b-form-datepicker>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="3">
                      <label>Fecha fin del evento:</label>
                    </b-col>
                    <b-col sm="9">
                      <b-form-datepicker
                          id="fecha_fin_evento"
                          ref="fecha_fin_evento"
                          size="sm"
                          v-model="FormBoletos.fecha_fin_evento"
                      >
                      </b-form-datepicker>
                    </b-col>
                  </b-row>
                  <!--                <b-form-textarea
                                      ref="reactivo"
                                      class="my-2"
                                      v-model="nombre_evento"
                                      type="text"
                                      rows="1"
                                      max-rows="2"
                                      placeholder="Ingresa el nombre del evento"
                                  >
                                  </b-form-textarea>-->
                  <!--                <b-form-input
                                      ref="reactivo"
                                      class="my-2"
                                      v-model="numero_cuenta_pago"
                                      type="text"
                                      rows="1"
                                      max-rows="2"
                                      placeholder="Número de cuenta (pago)"
                                  >
                                  </b-form-input>-->
                  <div class="col-sm-12 my-2">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right ml-2"
                        @click="guardarEvento()"
                    >
                      Guardar Evento
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click=" modalCrearEvento= false"
                    >
                      Cancelar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalCrearEvento()">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE CREAR EVENTO --->

    <b-row>
      <b-alert
          :show="dismissCountDown"
          dismissible
          variant="success"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
          class="alert-fixed text-center text-bold">
        <p><span>{{ textoSnackbar }}</span></p>
      </b-alert>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <h5 class="font-weight-bold">Eventos</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center p-2 my-2">
              <b-button variant="outline-secondary" size="md" @click="showModalCrearEvento()">Agregar evento</b-button>
            </div>
            <!--              <b-tabs content-class="mt-3" justified>
                            <b-tab title="Tickets abiertos" active>-->
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <svg @click="getData()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                     class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                  <path
                      d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                  <path fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                </svg>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar"
                  ></b-form-input>
                </div>
              </div>
            </div>

            <b-table class="table data-table"
                     :items="resultSet"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">{{ scope.emptyText = 'Por el momento no hay registros que mostrar' }}</h5>
              </template>
              <!--              <template #cell(id)="row">
                              <div class="text-center">
                                {{ row.item.id }}
                                <router-link :to="`/tickets-get/${row.item.id}`" v-b-tooltip.top title="Ver">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                                       class="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                  </svg>
                                </router-link>
                              </div>
                            </template>-->
              <template #cell(fecha)="row">
                {{ row.item.fecha_inicio_evento }} - {{ row.item.fecha_fin_evento }}
              </template>
              <template #cell(acciones)="row">
                <div class="d-flex align-items-center">
<!--                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Copiar URL Registro"
                            @click="copiarUrlRegistro(row.item)">
                    Copiar URL Registro
                  </b-button>
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Abrir/Cerrar registro"
                            @click="abrirCerrarRegistroEvento(row.item)">
                    Abrir/Cerrar registro
                  </b-button>
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Ver agenda"
                            @click="openModalGeneracionMasivaBoletos(row.item)">
                    Ver agenda
                  </b-button>
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Ver cupones"
                            @click="openModalGeneracionMasivaBoletos(row.item)">
                    Ver cupones
                  </b-button>
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Editar evento"
                            @click="openModalGeneracionMasivaBoletos(row.item)">
                    Editar evento
                  </b-button>-->
                  <b-button :pressed="false" variant="success" size="sm" class="ml-1" title="Generación Masiva Boletos"
                            @click="openModalGeneracionMasivaBoletos(row.item)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5ZM5 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z"/>
                      <path
                          d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z"/>
                    </svg>
                  </b-button>


                </div>
              </template>
            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
            <!--                 </b-tab>
                          </b-tabs>-->
          </div>
        </div>
      </b-col><!-- main center-->
    </b-row>
  </b-container>
</template>

<script>
//import moment from 'moment';
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
import EscuelasServices from "@/services/EscuelasServices";

export default {
  mixins: [toast],
  data() {
    return {
      dataBoletosFirmados: [],
      fieldsBoletosFirmados: [
        {label: 'Boleto', class: 'text-left', key: 'boleto'},
      ],
      totalRowsBoletos: 1,
      currentPageBoletos: 1,
      perPageBoletos: 50,
      pageOptionsBoletos: [50, 100, 150, {value: 50, text: "Show a lot"}],
      filterBoletos: null,
      filterOnBoletos: [],


      FormBoletos: {
        modalBoletosMasivos: false,
        fecha_inicio_evento: '',
        fecha_fin_evento: '',
        uuid_evento: 0,
        escuela_id: 0,
        motivo_id: 0,
        nombre_evento: '',
        cantidad_boletos: 0,
        escuelas: [],
        motivos: [
          {id: 1, motivo: 'Compra'},
          {id: 2, motivo: 'Cortesía'},
        ]

      },


      cantidad_dias_evento: 0,
      mensajeConfirmacion: null,
      modalConfirmacion: false,
      modalCrearEvento: false,
      textoSnackbar: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
        {label: 'id', class: 'text-left', key: 'id',},
        {label: 'Nombre Evento', key: 'nombre_evento', class: 'text-left'},
        {label: 'Fecha  del evento', key: 'fecha', class: 'text-left'},

        {label: 'Acciones', key: 'acciones', align: 'center', class: 'text-left'},
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: '',
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',

      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter3: null,
      filterOn3: [],
      resultSet3: [],
      search3: '',
      editarActividadUnidad: false,

    }
  },
  computed: {},
  methods: {
    copiarUrlRegistro(eventoSelected){
      let idEvento = eventoSelected.id
      console.log('ideventoSelected')
      console.log(idEvento)
    },
    abrirCerrarRegistroEvento(eventoSelected){
      let idEvento = eventoSelected.id
      console.log('ideventoSelected')
      console.log(idEvento)
    },
    cancelarBoletosMasivos(){
      this.FormBoletos.modalBoletosMasivos= false
      this.reiniciarFormBoletos()
    },

    reiniciarFormBoletos() {
      this.FormBoletos.uuid_evento = 0
      this.FormBoletos.escuela_id = 0
      this.FormBoletos.motivo_id = 0
      this.FormBoletos.cantidad_boletos = 0
      this.FormBoletos.nombre_evento = ''
      this.dataBoletosFirmados = []
      this.totalRowsBoletos = 1
      this.currentPageBoletos = 1
    },

    /*    fechaInicioFormateada(fechaInicio) {
          return moment(fechaInicio).format('D/M/Y');
        },*/
    async getEscuelas() {
      this.FormBoletos.escuelas = []
      this.modalEsperar = true
      const response = await EscuelasServices.getEscuelas();
      this.modalEsperar = false
      this.FormBoletos.escuelas = response.data.escuelas;
    },
    async generarBoletos() {
      console.log(this.FormBoletos.escuela_id)
      if (this.FormBoletos.escuela_id === 0) {
        this.$refs.motivo_id.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Seleccionar la escuela a la cual se generan los boletos.!', 'danger');

        return false;
      } else if (this.FormBoletos.motivo_id === 0) {
        this.$refs.motivo_id.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Seleccionar el motivo por el cual se generan los boletos.!', 'danger');

        return false;
      } else if (this.FormBoletos.cantidad_boletos <= 0) {
        this.$refs.cantidad_boletos.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Capturar una cantidad válida de boletos!', 'danger');
        return false;
      }

      let formData = new FormData();
      formData.append("uuid_evento", this.FormBoletos.uuid_evento);
      formData.append("escuela_id", this.FormBoletos.escuela_id);
      formData.append("motivo_id", this.FormBoletos.motivo_id);
      formData.append("cantidad_boletos", this.FormBoletos.cantidad_boletos);

      this.mensajeConfirmacion = "Favor de esperar, procesando la información..."
      this.modalConfirmacion = true
      //this.FormBoletos.modalBoletosMasivos = false
      const response = await EventoService.generarBoletosMasivamente(formData);
      //REINICIAR VARIABLES
      this.reiniciarFormBoletos()

      this.modalConfirmacion = false
      let {
        success,
        message,
        color,
        boletosFirmados,
        total
      } = response.data;
      if (success) {
        this.nombre_evento = ''
        this.dataBoletosFirmados = boletosFirmados
        this.totalRowsBoletos = total
        this.getEventos();
        this.modalCrearEvento = false
        this.nombre_evento = ''
        this.cantidad_dias_evento = ''
      }
      this.toast('b-toaster-bottom-center', true, message, color);


    },
    openModalGeneracionMasivaBoletos(boleto) {
      this.getEscuelas()
      this.FormBoletos.modalBoletosMasivos = true
      this.FormBoletos.uuid_evento = boleto.uuid
      this.FormBoletos.nombre_evento = boleto.nombre_evento
/*      console.log("boleto selected")
      console.log(boleto)*/
    },
    formatCantidad(e) {
      return String(e).substring(0, 1);
    },
    async getEventos() {
      this.resultSet = []
      const response = await EventoService.getEventos();
      console.log("eventos")
      console.log(response.data.resultSet)
      this.resultSet = response.data.resultSet;
      this.totalRows = response.data.cantidad;

    },
    hideModalCrearEvento() {
      this.modalCrearEvento = false
    },
    async guardarEvento() {
      if (this.nombre_evento === '') {
        this.$refs.nombre_evento.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Capturar nombre del evento!', 'danger');
        //this.toast('b-toaster-bottom-center', true, message, color );
        return false;
      } else if (this.FormBoletos.fecha_inicio_evento === '') {
        this.$refs.fecha_inicio_evento.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Capturar fecha de inicio del evento.!', 'danger');
        return false;
      } else if (this.FormBoletos.fecha_fin_evento === '') {
        this.$refs.fecha_fin_evento.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Capturar fecha de finalización del evento.!', 'danger');
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      formData.append("fecha_inicio_evento", this.FormBoletos.fecha_inicio_evento);
      formData.append("fecha_fin_evento", this.FormBoletos.fecha_fin_evento);
      this.mensajeConfirmacion = "Favor de esperar, guardando el evento..."
      this.modalConfirmacion = true
      this.modalCrearEvento = false
      const response = await EventoService.saveEvento(formData);
      this.modalConfirmacion = false
      let {
        success,
        message,
        color
      } = response.data;
      if (success) {
        this.nombre_evento = ''
        this.getEventos();
        this.modalCrearEvento = false
        this.nombre_evento = ''
        this.cantidad_dias_evento = ''
      }
      this.toast('b-toaster-bottom-center', true, message, color);
      //this.reactivo = ' ';
    },
    showModalCrearEvento() {
      this.modalCrearEvento = true
    },
    async getData() {
      this.resultSet = [];
      this.modalConfirmacion = true
      this.mensajeConfirmacion = "Favor de esperar, procesando información..."
      const url = `/api/eventos/all-eventos`;
      const response = await this.$api.get(url);
      this.modalConfirmacion = false
      let {message, data, resultSet} = response.data;
      this.resultSet = (data == 1) ? resultSet : [];
      console.log("Eventos")
      console.log(this.resultSet)
      this.totalRows = this.resultSet.length;
      this.textoSnackbar = message;
      // this.showAlert();
    },//getData


    async getMyClosedTickets() {
      this.resultSet3 = [];
      const url = `/api/all-myClosedTickets`;
      const response = await this.$api.get(url);
      let {message, data, resultSet} = response.data;
      this.textoSnackbar = message;
      this.resultSet3 = (data == 1) ? resultSet : [];
      this.totalRows3 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets

    async verManual() {
      await this.$router.push(`/manual`);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {

    this.getData();
  },

}
</script>

<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}

</style>