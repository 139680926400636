/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
    <b-alert v-model="modalEsperar" show variant="primary" >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Favor de esperar un momento, procesando información...
      </div>


    </b-alert>
    <!-- inicio modal CREAR EVENTO-->
    <b-modal v-model="modalValidarPago" title="Validar Pago"   centered size="lg" no-close-on-backdrop no-close-on-esc  hide-header-close>
      <b-container fluid>
          <div>
              <p>A continuación se muestran los datos del comprobante con <b>ID</b> {{infoPago.id}}.<br></p>
              <b>Usuario:</b>{{infoPago.cliente.name}}<br>
              <b>Escuela:</b>{{infoPago.cliente.escuela_procedencia}}
          </div>
         <b-row>
            <b-col cols="6">
              <div class="card card-block">
                <div class="card-body p-2">
                  <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                    <b-img
                        rounded
                        :src="infoPago.url_comprobante_pago"
                        height="auto"
                        width="400"
                        fluid
                    >
                    </b-img>
                  </b-row>
                </div>
              </div>
            </b-col>
              <b-col cols="6">
              <div class="card card-block">
                <div class="card-body p-2">
                  <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">

                    <b-col cols="12" class="align-content-center">
                      <b-form>
                        <b-form-group label="Resultado de verificación">
                          <b-form-radio v-model="infoPago.comprobanteEstatusAprobado"  name="form-radio-comprobante" value="1">Aprobar pago</b-form-radio>
                          <b-form-radio v-model="infoPago.comprobanteEstatusAprobado"  name="form-radio-comprobante" value="0">Pago rechazado</b-form-radio>
                        </b-form-group>

                        <b-form-group
                            id="label-observaciones"
                            label="Observaciones:"
                            label-for="Observaciones"
                        >
                          <b-form-textarea
                              id="input-observaciones"
                              v-model="infoPago.observacionesValidacion"
                              placeholder="Capturar observaciones (opcional)"
                              type="text"
                              rows="3"
                              max-rows="6"
                          ></b-form-textarea>

<!--                          <pre class="mt-3 mb-0">{{ infoPago.observacionesValidacion }}</pre>-->
                        </b-form-group>

                      </b-form>
                    </b-col>


                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right mx-2"
              @click="guardarValidacion()"
          >
            Guardar
          </b-button>
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalValidarPago=false"
          >
            Cancelar
          </b-button>

        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE CREAR EVENTO --->

    <b-row>
      <b-alert
          :show="dismissCountDown"
          dismissible
          variant="success"
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
          class="alert-fixed text-center text-bold">
        <p> <span>{{textoSnackbar}}</span></p>
      </b-alert>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <h5 class="font-weight-bold">Administración de Pagos</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center p-2 my-2">
              <b-button  variant="outline-secondary" size="md"  @click="showModalCrearEvento()">Agregar evento</b-button>
            </div>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <svg  @click="getData()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                  <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                </svg>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar"
                  ></b-form-input>
                </div>
              </div>
            </div>

            <b-table class="table data-table"
                     :items="resultSet"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</h5>
              </template>
              <template #cell(created_at)="row">
                <div>{{convertir_fecha(row.item.created_at)}}</div>
              </template>


              <template #cell(comprobanteEstatusAprobado)="row" >
                <div class="d-flex align-items-center">
                    <b-button variant="danger" size="sm" class="ml-1" v-if="row.item.comprobanteEstatusAprobado==0">
                      <div>Pendiente</div>
                    </b-button>
                  <b-button variant="success" size="sm" class="ml-1" v-if="row.item.comprobanteEstatusAprobado==1">
                    <div>Aprobado</div>
                  </b-button>
                </div>
              </template>

              <template #cell(actions)="row">
                <b-button variant="success" size="sm" @click="showModalValidarPago(row.item)" class="ml-1" title="Verificar pago">
<!--                  <div>Validar</div>-->
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                  </svg>
                </b-button>
              </template>



            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col><!-- main center-->
    </b-row>
  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import EventoService from '@/services/EventoService';
import PagoService from "@/services/PagoService";
import moment from "moment"
import 'moment/locale/es';

export default {
  mixins:[toast],
  data(){
    return{
      modalEsperar:true,
      previewObservacionesValidacion:'',
      modalValidarPago:false,
      textoSnackbar:'',
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      fields: [
      {label: 'id',align: 'left',key: 'id',}
      ,{ label: 'Usuario', key: 'cliente.name', align: 'left' }
      ,{ label: 'Escuela', key: 'cliente.escuela_procedencia', align: 'left' }
      ,{ label: 'Recepción', key: 'created_at', align: 'left' }
      ,{ label: 'Estatus', key: 'comprobanteEstatusAprobado', align: 'left' }
      ,{ label: 'Acciones', key: 'actions', align: 'left' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      filterOn: [],
      resultSet:[],
      search: '',
      nombre_evento:null,
      numero_cuenta_pago:null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter2: null,
      filterOn2: [],
      resultSet2:[],
      search2: '',

      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter3: null,
      filterOn3: [],
      resultSet3:[]
      //url_comprobante_pago:null
      ,infoPago:{
        id:0,
        url_comprobante_pago:null,
        comprobanteEstatusAprobado:0,
        observacionesValidacion:null,
        cliente:{
            name:null,
            escuela_procedencia:null,
            email:null
        }
      }
    }
  },
  methods: {
    async guardarValidacion()
    {
      let formData = new FormData();
      formData.append("id", this.infoPago.id);
      formData.append("email", this.infoPago.cliente.email);
      formData.append("name", this.infoPago.cliente.name);
      formData.append("comprobanteEstatusAprobado", this.infoPago.comprobanteEstatusAprobado);
      formData.append("observacionesValidacion", this.infoPago.observacionesValidacion);
      this.modalValidarPago=false
      this.modalEsperar=true
      const response = await PagoService.saveValidacionPago(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.modalEsperar=false
        this.getPagos()

      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    hidemodalValidarPago(){
      this.modalValidarPago=false
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getPagos(){
      this.resultSet=[]
      this.modalEsperar=true
      const response = await PagoService.getPagos();
      this.modalEsperar=false
      this.resultSet = response.data.pagos;
      this.totalRows = response.data.cantidad;
    },
    showModalValidarPago(item){
      //console.log("informacion de usuario")
     // console.log(item)
      this.modalValidarPago=true
      //this.url_comprobante_pago=item.url_comprobante_pago
      this.infoPago = item
    },
    hideModalCrearEvento(){
      this.modalCrearEvento=false
    },
    async guardarEvento()
    {
      if( this.nombre_evento === ''){
        this.$refs.reactivo.$el.focus();
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la pregunta!', 'danger' );
        return false;
      }
      let formData = new FormData();
      formData.append("nombre_evento", this.nombre_evento);
      this.wait()
      this.modalCrearEvento=false
      const response = await EventoService.saveEvento(formData);
      let {   success,
        message,
        color
      } = response.data;
      if(success){
        this.getEventos();
      }
      this.toast('b-toaster-bottom-center', true, message, color );
    },
    showModalCrearEvento(){
      this.modalCrearEvento=true
    },
    async getData(){
      this.resultSet = [];
      const url                       =   `/api/all-events`;
      const response                  =   await this.$api.get(url);
      let {message, data, resultSet}  =   response.data;
      this.resultSet                  =   (data == 1) ? resultSet : [];
      this.totalRows                  =   this.resultSet.length;
      this.textoSnackbar              =   message;
      this.showAlert();
    },//getData
    async getMyTickets(){
      this.resultSet2 = [];
      const url                       = `/api/all-myTickets`;
      const response                  = await this.$api.get(url);
      let {message, data, resultSet}  = response.data;
      this.resultSet2                = (data == 1) ? resultSet : [];
      this.totalRows2                = this.resultSet2.length;
      this.textoSnackbar             =   message;
      this.showAlert();
    },//getMyTickets

    async getMyClosedTickets(){
      this.resultSet3 = [];
      const url                       =   `/api/all-myClosedTickets`;
      const response                  =   await this.$api.get(url);
      let {message, data, resultSet}  =   response.data;
      this.textoSnackbar              =   message;
      this.resultSet3                 = (data == 1) ? resultSet : [];
      this.totalRows3                 = this.resultSet3.length;
      this.showAlert();
    },//getMyClosedTickets
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted () {
    this.wait()
    this.getPagos();

  },

}
</script>
<style>
.reload {
  fill: #B2007B !important;
}
.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}
.page-link {
  border-color: #B2007B !important;
  color:#B2007B !important;
}
.alert-fixed {
  position:fixed;
  bottom: 2%;
  left: 30%;
  z-index:9999;
  border-radius:5px;
}
</style>